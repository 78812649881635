import {LibraryIssue} from "../data/api/generated/EmpowerSwaggerApi"
import React, {useContext, useEffect, useState} from "react"
import {CXSelectionContext} from "../context/CXSelectionContext"
import {
    CheckboxText,
    CheckImageContainer, EditImageContainer,
    HorizontalFlexContainer,
    HorizontalLine,
    PanelContainer
} from "../feature/cx/library/CXStyleSheet"
import redCheck from "../img/check-red.png"
import emptyCheck from "../img/checkEmpty.png"
import editIcon from "../img/edit.png"
import {CxModalEditFocusAreaItems} from "../feature/cx/library/CXModalEditFocusAreaData"

interface RenderIssueProps {
    issues: LibraryIssue
    requiresImage: boolean,
    requiresVideo: boolean,
    requiresComment: boolean
    hasCurrentTemplate: boolean
    elementId: string
}

export const  RenderIssue: React.FC<RenderIssueProps> = (props) => {
    const selectionContext = useContext(CXSelectionContext)
    const [isIssueChecked, setIsIssueChecked] = useState(selectionContext.isSelectedIssue(props.issues))
    const [showModal, setShowModal] = useState<'editIssue' | null>(null)

    useEffect(() => {
        setIsIssueChecked(selectionContext.isSelectedIssue(props.issues))
    }, [selectionContext, props.issues])

    return (
        <>
            <PanelContainer style={{marginLeft: 28, height: 36, position: 'relative'}}>
                <HorizontalFlexContainer onClick={() => {
                    const value = !isIssueChecked
                    selectionContext.toggleIssue(
                        props.issues.id!!,
                        value,
                        props.requiresImage,
                        props.requiresVideo,
                        props.requiresComment
                    )
                    setIsIssueChecked(value)
                }}>
                    {!props.hasCurrentTemplate &&
                      <CheckImageContainer src={isIssueChecked ? (redCheck) : (emptyCheck)}/>}
                    <CheckboxText style={{cursor: "pointer"}}>{props.issues.name}</CheckboxText>
                </HorizontalFlexContainer>
                {!props.hasCurrentTemplate &&
                  <EditImageContainer style={{right: 15}} onClick={() => setShowModal('editIssue')}>
                    <img src={editIcon} alt={"edit-icon"} width={10} height={10}/>
                  </EditImageContainer>}
            </PanelContainer>
            <HorizontalLine style={{marginLeft: 30}}/>
            <CxModalEditFocusAreaItems id={props.issues.id!} name={props.issues.name!} type={'Issue'}
                                       show={showModal === 'editIssue'}
                                       additionalData={{elementId: props.elementId!}}
                                       onClose={() => setShowModal(null)}/>
        </>
    )
}
