import React from "react"
import {Button, Layout, Modal, Popconfirm, Spin} from "antd"
import queryString from "query-string"
import {EmpowerTicketsAPI} from "../../data/api/EmpowerTicketsAPI"
import {loginAPIErrorRedirector} from "../../data/api/APIErrorRedirector"
import {TicketDetailResponse} from "../../data/api/APITypes"
import {TicketMessage} from "./TicketMessage"
import {TicketReplyForm, TicketReplyFormData} from "./TicketReplyForm"
import {DateUtil} from "../../util/DateUtil"

const {
    Header,
} = Layout
type TicketDetailProps = {
    location: any
    ticketId?: string | null | undefined
    ticketSubject?: string | null | undefined
    history: any
}
type TicketDetailState = {
    busy: boolean
    authFailed: boolean
    currentTicket?: TicketDetailResponse | null | undefined
    replyFormShown: boolean
}
const styles = {
    headerButton: {
        marginRight: 6
    },
    deleteButton: {
        marginRight: 6,
        backgroundColor: "#f5222d",
        color: "#fff"
    }
}

export class TicketDetail extends React.Component<TicketDetailProps, TicketDetailState> {
    ticketId: string
    ticketSubject: string

    constructor(props: TicketDetailProps) {
        super(props)

        if (this.props.ticketId != null) {
            this.ticketId = this.props.ticketId
        } else {
            // @ts-ignore
            this.ticketId = queryString.parse(this.props.location.search).ticketId
        }

        if (this.props.ticketSubject != null) {
            this.ticketSubject = this.props.ticketSubject
        } else {
            // @ts-ignore
            this.ticketSubject = queryString.parse(this.props.location.search).ticketSubject
        }

        this.state = {
            busy: true,
            currentTicket: null,
            replyFormShown: false,
            authFailed: false
        }
        this.fetchTicketDetails(this.ticketId)
    }

    fetchTicketDetails(id: string) {
        EmpowerTicketsAPI.fetchTicketDetails(id).catch(error => {
            this.setState({
                authFailed: true
            })
            return loginAPIErrorRedirector(() => {
                return this.props
            }).onError(error)
        }).then(response => {
            this.setState({
                busy: false,
                currentTicket: response.data
            })
        })
    }

    submitForm = (formData: TicketReplyFormData) => {
        this.setState({
            replyFormShown: false,
            busy: true
        })
        var attachments = undefined

        if (formData.attachments != null) {
            attachments = formData.attachments.map(attachment => {
                return attachment.id
            })
        }

        var body = {
            body: formData.body,
            attachmentIds: attachments,

            // @ts-ignore
            subject: this.state.currentTicket.subject
        }
        EmpowerTicketsAPI.replyToTicket(this.ticketId, body).catch(error => {
            this.setState({
                authFailed: true
            })
            return loginAPIErrorRedirector(() => {
                return this.props
            }).onError(error)
        }).then(() => {
            this.fetchTicketDetails(this.ticketId)
        }).catch(() => {
            this.setState({
                busy: false
            })
        })
    }
    showReplyForm = () => {
        this.setState({
            replyFormShown: true
        })
    }

    updateTicketStatus(newStatus: number) {
        this.setState({
            busy: true
        })
        EmpowerTicketsAPI.updateTicketStatus(this.ticketId, newStatus).catch(error => {
            this.setState({
                authFailed: true
            })
            return loginAPIErrorRedirector(() => {
                return this.props
            }).onError(error)
        }).then(() => {
            this.fetchTicketDetails(this.ticketId)
        }).catch(() => {
            this.setState({
                busy: false
            })
        })
    }

    closeIssue = () => {
        this.updateTicketStatus(6)
    }
    reopenIssue = () => {
        this.updateTicketStatus(5)
    }
    deleteIssue = () => {
        this.setState({
            busy: true
        })
        EmpowerTicketsAPI.deleteTicket(this.ticketId).catch(error => {
            this.setState({
                authFailed: true
            })
            return loginAPIErrorRedirector(() => {
                return this.props
            }).onError(error)
        }).then(() => {
            this.props.history.goBack()
        }).catch(() => {
            this.setState({
                busy: false
            })
        })
    }

    render() {
        return <Layout>
            <Layout>
                <Header style={{
                    paddingLeft: 12
                }} className="header1">
                    <div>
                        <h1>
                            <span style={{
                                color: '#F00'
                            }} onClick={() => {
                                this.props.history.goBack()
                            }}> Feedback </span>
                            {this.ticketSubject}
                        </h1>
                    </div>
                </Header>

                <Modal className="reply-drawer" visible={this.state.replyFormShown}
                    // @ts-ignore
                       placement="bottom" onCancel={() => {
                    this.setState({
                        replyFormShown: false
                    })
                }} bodyStyle={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    paddingRight: 0
                }}>
                    <TicketReplyForm onFormSubmit={this.submitForm}/>
                </Modal>

                <Header style={{
                    paddingLeft: 12
                }} className="header2">
                    <div>
                        <Button style={styles.headerButton} icon="message" onClick={this.showReplyForm}>Reply</Button>
                        <Popconfirm
                            // @ts-ignore
                            size="default" title="Are you sure delete this issue?" onConfirm={() => {
                            this.deleteIssue()
                        }} onCancel={() => {
                        }} okText="Yes" cancelText="No">
                            <Button style={styles.deleteButton} icon="delete">Delete</Button>
                        </Popconfirm>
                        {this.state.currentTicket != null && this.state.currentTicket.ticketStatus.ticketStatusId === 5 &&
                          <Button style={styles.headerButton} icon="close" onClick={this.closeIssue}>Resolve</Button>}
                        {this.state.currentTicket != null && this.state.currentTicket.ticketStatus.ticketStatusId === 6 &&
                          <Button style={styles.headerButton} icon="redo" onClick={this.reopenIssue}>Re-Open</Button>}
                    </div>
                </Header>

                <Spin style={{
                    alignSelf: "center",
                    minHeight: 400
                }} spinning={this.state.busy} size="large">

                    {this.state.currentTicket != null &&
                      <TicketMessage userName={this.state.currentTicket.from} subject={this.state.currentTicket.subject}
                                     message={this.state.currentTicket.body}
                                     attachments={this.state.currentTicket.attachments}
                                     messageDate={DateUtil.formatEmpowerDate(this.state.currentTicket.dateCreated)}
                                     firstItem={true}/>}
                    {this.state.currentTicket != null && this.state.currentTicket.replies.map(reply => {
                        return <TicketMessage
                            // @ts-ignore
                            userName={this.state.currentTicket.from} replierName={reply.from}
                            messageDate={DateUtil.formatEmpowerDate(reply.dateCreated)} message={reply.body}
                            attachments={reply.attachments} firstItem={false}/>
                    })}

                </Spin>

            </Layout>
        </Layout>
    }

}

export function navigateToTicketDetail(history: any, ticketId: string, ticketSubject: string) {
    history.push("/ticketDetails?ticketId=" + ticketId + "&ticketSubject=" + ticketSubject)
}
