import React from "react"
import styled from "styled-components"
import DefaultTheme from "../util/Helper"

interface NoResultsBlockProps {
    title: string
    text: string
    show?: boolean
}

export const NoResultsBlock: React.FC<NoResultsBlockProps> = (props) => {
    const {title, text, show} = props
    
    if (show === false) {
        return null
    }
    
    return <NoResultsBlockWrapper>
        <h3>No results for "{title}".</h3>
        <p>{text}</p>
    </NoResultsBlockWrapper>
}

const NoResultsBlockWrapper = styled.div`
	max-width: 380px;
	margin: 64px auto auto;
	text-align: center;
	
	h3 {
		font-size: 18px;
        font-weight: bold;
		line-height: 28px;
		color: ${DefaultTheme.color.grey8};
	}
	
	p {
		font-size: 16px;
		line-height: 24px;
		color: ${DefaultTheme.color.grey6};
	}
`
