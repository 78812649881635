import {CXReport} from "./CXReport"
import React from "react"
import {Layout} from "antd"

export const CXReportScreen: React.FC = (props: any) => {
    return (
        <Layout style={{backgroundColor: '#F6F6F7', paddingBottom: 50}}>
            <CXReport/>
        </Layout>
    )
}
