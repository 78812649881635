import React from "react"
import {GreyContainer, ReportingGreyBody} from "../feature/cx/library/CXStyleSheet"

interface UserRolesProps {
    data: string[]
    show?: boolean
}

export const UserRoles: React.FC<UserRolesProps> = (props) => {
    const {data, show} = props

    if (show === false) {
        return null
    }

    return <>
        {data.map((item, index) =>
            <GreyContainer key={index} style={{backgroundColor: "#eee"}}>
                <ReportingGreyBody style={{color: "#4d4d4d"}}>
                    {item}
                </ReportingGreyBody>
            </GreyContainer>
        )}
    </>
}
