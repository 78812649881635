import React, {useCallback, useContext, useEffect, useState} from "react"
import {Modal} from "antd"
import {
    BodyText,
    BodyTextBold,
    CheckboxText,
    CheckImageContainer,
    ErrorText,
    HorizontalFlexContainer,
    ModalCancelButton,
    ModalHeading,
    ModalSubmitInput,
    StyledInput,
    VerticalFlexContainer
} from "./CXStyleSheet"
import greyCheck from "../../../img/check-grey.png"
import checkEmpty from "../../../img/checkEmpty.png"
import Select from "react-select"
import {useForm} from "react-hook-form"
import {CreateTemplateRequest, LibraryFocusArea} from "../../../data/api/generated/EmpowerSwaggerApi"
import {CXContext} from "../../../context/CXContext"
import {CXSelectionContext} from "../../../context/CXSelectionContext"

interface Roles {
    id: any;
    value: string;
    label: string;
    role: string;
}

interface RenderFocusAreaProps {
    focusArea: LibraryFocusArea | undefined
    isChecked: boolean
    setIsChecked: (checked: boolean) => void
}

const RenderFocusArea: React.FC<RenderFocusAreaProps> = (props) => {
    return (
        <VerticalFlexContainer style={{marginTop: "4px"}}>
            <HorizontalFlexContainer>
                <CheckImageContainer src={props.isChecked ? greyCheck : checkEmpty}
                                     onClick={() => props.setIsChecked(!props.isChecked)}/>
                <CheckboxText>{props.focusArea ? `${props.focusArea.name} (${props.focusArea.abbreviation})` : ""}</CheckboxText>
            </HorizontalFlexContainer>
        </VerticalFlexContainer>
    )
}

interface CXModalMakeTemplateProps {
    show?: boolean;
    onClose: () => void;
}

export const CXModalMakeTemplate: React.FC<CXModalMakeTemplateProps> = (props) => {
    const {show, onClose} = props
    const cxContext = useContext(CXContext)
    const selectionContext = useContext(CXSelectionContext)
    const templateIssues = selectionContext.templateIssues()
    const hasTemplateIssues = templateIssues.length > 0
    const [selectedRoles, setSelectedRoles] = useState<string[]>([])
    const [focusAreas, setFocusAreas] = useState<LibraryFocusArea[]>([])

    const rolesOptions: Roles[] = cxContext.availableRoles.map(role => {
            return {
                id: role.roleId,
                value: role.description ?? "",
                label: role.description ?? "",
                role: role.description ?? ""
            }
        }
    ) ?? []

    const {
        handleSubmit,
        register,
        formState: {errors}
    } = useForm()

    const onSubmit = useCallback((data: any) => {
        if (!hasTemplateIssues) {
            return
        }

        const request: CreateTemplateRequest = {
            name: data.templateNameInput,
            roleIds: selectedRoles,
            issues: templateIssues.map((issue) => {
                return {
                    ...issue,
                }
            })
        }

        cxContext.api.createTemplate(request)
        onClose()
    }, [hasTemplateIssues, templateIssues, cxContext.api, onClose, selectedRoles])

    if (errors.templateNameInput) {
        console.log(errors.templateNameInput)
    }

    if (errors.templateNameInput) {
        console.log(errors.templateNameInput)
    }

    const handleCancel = () => {
        onClose()
    }
    const renderModalFooter = () => {
        return (
            <div>
                <span>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <div>
                            <ModalCancelButton onClick={handleCancel}>Cancel</ModalCancelButton>
                        </div>
                         <form onSubmit={handleSubmit(onSubmit)}>
                            <ModalSubmitInput type={"submit"} name={'submit'} value={'Done'}
                                              disabled={!hasTemplateIssues}/>
                        </form>
                    </div>
                </span>
            </div>
        )
    }

    const getIsChecked = (focusArea: LibraryFocusArea) => {
        return selectionContext.selectedFocusAreas.filter(s => s.id === focusArea.id).length > 0
    }

    const toggleFocusArea = (focusArea: LibraryFocusArea, isSelected: boolean) => {
        selectionContext.toggleFocusArea(focusArea, isSelected)
    }

    useEffect(() => {
        setFocusAreas(cxContext.availableFocusAreas)
    }, [cxContext.availableFocusAreas])

    useEffect(() => {
        if (!show) return
        selectionContext.setAutoSectionEnabled(cxContext.activeFocusArea?.name!)
    }, [show, selectionContext, cxContext.activeFocusArea?.name])

    return (
        <Modal
            visible={show}
            centered
            footer={renderModalFooter()}
            onCancel={handleCancel}>

            <ModalHeading>New Store Template</ModalHeading>
            <BodyText>Template Name {errors.templateNameInput &&
              <ErrorText>({errors.templateNameInput.message})</ErrorText>}</BodyText>
            <StyledInput
                {...register("templateNameInput", {
                    required: "Enter Template Name."
                })}
                style={{marginBottom: 24, height: 42}}
                name="templateNameInput"
                id="templateNameInput"
                type="text"
            />
            <BodyText style={{marginTop: 15}}>Assign this template to a role</BodyText>

            <Select
                isMulti
                options={rolesOptions}
                isClearable={false}
                components={{DropdownIndicator: null}}
                onChange={(value) => setSelectedRoles(value.map((role) => role.id))}
            />

            <BodyTextBold style={{lineHeight: 4}}>Focus Area</BodyTextBold>
            {focusAreas.length === 0 &&
              <div><ErrorText>Please select at least one issue to make a template.</ErrorText></div>}
            {focusAreas.map(fa => <RenderFocusArea key={fa.id} focusArea={fa}
                                                   isChecked={getIsChecked(fa)}
                                                   setIsChecked={(checked) => toggleFocusArea(fa, checked)}/>)}
        </Modal>
    )
}
