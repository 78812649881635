import React, {useContext, useEffect, useState} from "react"
import {Modal} from "antd"
import {
    BodyText,
    CheckboxText,
    CheckImageContainer,
    ErrorText,
    GreyTextModal,
    HorizontalFlexContainer,
    ModalCancelButton,
    ModalConfirmButton,
    ModalHeading,
    StyledInput,
    styles,
    VerticalFlexContainer
} from "./CXStyleSheet"
import emptyCheck from "../../../img/checkEmpty.png"
import greyCheck from "../../../img/check-grey.png"
import {useForm} from "react-hook-form"
import {CXContext} from "../../../context/CXContext"

interface CXModalSubcategoryProps {
    categoryId: string
    show: boolean
    onClose?: () => void
}

export const CXModalAddSubcategory: React.FC<CXModalSubcategoryProps> = (props) => {
    const {show, onClose} = props
    const cxContext = useContext(CXContext)

    const {
        handleSubmit,
        register,
        reset,
        formState: {errors}
    } = useForm()

    const onSubmit = (data: any) => {
        cxContext.api.createOrUpdateSubcategory(props.categoryId!!, data.subcategoryInput, isChecked)
        handleCancel()
    }

    if (errors.subcategoryInput) {
        console.log(errors.subcategoryInput)
    }

    const [isChecked, setIsChecked] = useState(false)
    const [isVisible, setIsVisible] = useState(false)

    const handleCancel = () => {
        onClose && onClose()
        reset({subcategoryInput: ''})
        setIsChecked(false)
    }

    const renderModalFooter = () => {
        return (
            <div>
                <span>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <div>
                            <ModalCancelButton onClick={handleCancel}>
                                Cancel
                            </ModalCancelButton>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <ModalConfirmButton type="submit">
                                Add
                            </ModalConfirmButton>
                        </form>
                    </div>
                </span>
            </div>
        )
    }

    useEffect(() => {
        return setIsVisible(show)
    }, [show])

    return (
        <Modal
            visible={isVisible}
            centered
            footer={renderModalFooter()}>

            <VerticalFlexContainer>
                <ModalHeading>Add Subcategory</ModalHeading>
                <BodyText>Subcategory Name</BodyText>
                <div style={{marginBottom: 28, width: '100%'}}>
                    <StyledInput
                        {...register("subcategoryInput", {
                            required: "Enter Subcategory Name."
                        })}
                        style={{marginTop: 5, marginBottom: 5, height: 42}}
                        name="subcategoryInput"
                        id="subcategoryInput"
                        type="text"
                        placeholder={'E.g. Windows'}
                    />
                    {errors.subcategoryInput && <ErrorText>{errors.subcategoryInput.message}</ErrorText>}
                </div>

                <BodyText style={styles.bodyBold}>Element Type</BodyText>
                <HorizontalFlexContainer style={{marginTop: 10}}>
                    <CheckImageContainer onClick={() => setIsChecked(!isChecked)}
                                         src={isChecked ? (greyCheck) : (emptyCheck)}/>
                    <CheckboxText style={{fontSize: 15, fontWeight: 700}}>Repeat Element</CheckboxText>
                </HorizontalFlexContainer>
                <GreyTextModal style={{marginLeft: 35, marginTop: 5}}>
                    This subcategory only allows for 1 element. This element and it’s
                    issues will be asked as many times as there are features in the store.
                    An example is Windows, which will be asked 3 times if there are 3 windows.</GreyTextModal>

            </VerticalFlexContainer>

        </Modal>
    )
}
