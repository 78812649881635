import {Layout} from "antd"
import React from "react"
import {CXLibraryTemplate} from "./CXLibraryTemplate"

export const CXTemplatesScreen: React.FC = (props: any) => {
    return (
        <Layout style={{backgroundColor: '#fff', paddingBottom: 50}}>
            <CXLibraryTemplate/>
        </Layout>
    )
}
