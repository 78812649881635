import React, {useEffect, useState} from "react"

interface ProgressBarProps{
    completed: number
}
export const ProgressBarWithBackground:React.FC<ProgressBarProps> = (props) => {
    const [completed, setCompleted] = useState(0)

    useEffect(() => {
        setCompleted(props.completed)
    }, [props.completed])


    return (
        <div style={{
            height: 20,
            width: '100%',
            backgroundColor: "#e0e0de",
            borderRadius: 2,
        }}>
            <div style={{
                height: '100%',
                width: `${completed}%`,
                backgroundImage:`linear-gradient(to right, #B51E24, #DA2131)`,
                borderRadius: 'inherit',
                textAlign:'right',
                transition:'width 1s ease-in-out'
            }}>
                {/*<span style={{
                    padding: 5,
                    color: 'white',
                    fontWeight: 'bold'
                }}>
                    {`${props.completed}%`}
                </span>*/}
            </div>
        </div>
    )

}
