import React, {useCallback, useContext, useEffect, useState} from "react"
import {
    ButtonFlexContainer,
    DefaultButton,
    HeaderFlexContainer,
    HorizontalFlexContainer,
    PageTitle,
    ReportingGreyBody,
    StyledLoader,
    SubcategoryHeading,
    VerticalFlexContainer
} from "./CXStyleSheet"
import {CXContext} from "../../../context/CXContext"
import DefaultTheme, {getFormattedDate} from "../../../util/Helper"
import {CXTemplateSummary} from "../../../data/api/generated/EmpowerSwaggerApi"
import {Link, Route, Switch as RouteSwitch, useRouteMatch} from "react-router-dom"
import {SearchControl} from "../../../components/SearchControl"
import {ButtonGroup} from "../../../components/ButtonGroup"
import {NoResultsBlock} from "../../../components/NoResultsBlock"
import {PulseLoader} from "react-spinners"
import {css} from "@emotion/react"
import {UserRoles} from "../../../components/UserRoles"

interface ListTemplateProps {
    templates: CXTemplateSummary[]
    show?: boolean
}

const ListTemplates: React.FC<ListTemplateProps> = (props) => {
    const {templates, show} = props

    if (show === false) {
        return null
    }

    return (
        <div style={{backgroundColor: "#fff", margin: '24px 24px 48px 24px'}}>
            {templates.map((data, index) => {
                return <Link key={data.templateId} to={`/cxtemplate/${data.templateId}`}>
                    <HorizontalFlexContainer
                        style={{
                            borderBottom: `1px solid ${DefaultTheme.color.grey2}`,
                            padding: "10px 0",
                            width: "100%",
                            cursor: "pointer"
                        }}>
                        <VerticalFlexContainer>
                            <SubcategoryHeading>{data.name}</SubcategoryHeading>
                            <HorizontalFlexContainer>
                                <UserRoles data={data.roles ?? []}/>
                            </HorizontalFlexContainer>
                        </VerticalFlexContainer>
                        <VerticalFlexContainer>
                            <ReportingGreyBody
                                style={{color: "#999"}}>Created {getFormattedDate(data.dateCreated)}</ReportingGreyBody>
                            {data.username &&
                                <ReportingGreyBody style={{color: "#999"}}>by {data.username}</ReportingGreyBody>}
                        </VerticalFlexContainer>
                    </HorizontalFlexContainer>
                </Link>
            })}
        </div>
    )
}

export const CXLibraryTemplate = () => {
    const cxContext = useContext(CXContext)
    const {path} = useRouteMatch()

    const [templates, setTemplates] = useState<CXTemplateSummary[]>([])
    const [templateFilters, setTemplateFilters] = useState<{ isActive: boolean }>({isActive: true})
    const [searchResults, setSearchResults] = useState<CXTemplateSummary[]>([])

    const currentTemplates = searchResults ?? templates

    const getActiveTemplates = useCallback((items: CXTemplateSummary[]) => {
        return items.filter((item) => item.isActive === templateFilters.isActive)
    }, [templateFilters.isActive])

    const templateFilterHandler = (key: string, value: any) => {
        setTemplateFilters(prevState => ({...prevState, [key]: value}))
    }

    useEffect(() => {
        if (!cxContext.availableTemplates) { return }
        setTemplates(getActiveTemplates(cxContext.availableTemplates))
        // eslint-disable-next-line
    }, [cxContext.availableTemplates, templateFilters.isActive])

    return (
        <StyledLoader
            active={cxContext.loading}
            text="Loading content..."
            classNamePrefix="MyLoader_"
            spinner={<PulseLoader
                color={`${DefaultTheme.color.primaryRed}`}
                css={css`display: block;
									margin: 0 auto;`}
                speedMultiplier={0.5}
            />
            }>
            <RouteSwitch>
                <Route exact path={path}>
                    <>
                        <HeaderFlexContainer>
                            <PageTitle>Store Visit Templates</PageTitle>
                            <SearchControl searchItems={templates} setFilteredItems={setSearchResults}/>
                        </HeaderFlexContainer>

                        <ButtonFlexContainer>
                            <ButtonGroup defaultSelected={0}>
                                <DefaultButton
                                    onClick={() => templateFilterHandler("isActive", true)}>Active</DefaultButton>
                                <DefaultButton
                                    onClick={() => templateFilterHandler("isActive", false)}>Inactive</DefaultButton>
                            </ButtonGroup>
                        </ButtonFlexContainer>

                        <NoResultsBlock
                            show={currentTemplates.length === 0 && cxContext.currentSearchQuery.length > 0}
                            title={cxContext.currentSearchQuery}
                            text={"Please try another search term or check in the Inactive Templates tab."}
                        />

                        <ListTemplates
                            show={currentTemplates.length > 0}
                            templates={currentTemplates}
                        />
                    </>
                </Route>
                <Route path={`${path}`}>

                </Route>
            </RouteSwitch>
        </StyledLoader>
    )
}

