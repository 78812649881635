import React from "react";
import { Divider } from "antd";
import { Attachment } from "../../data/api/APITypes";
import { AttachmentListing } from "../../components/AttachmentListing";
type TicketMessageProps = {
  subject?: string | null | undefined;
  userName: string;
  replierName?: string | null | undefined;
  messageDate: string;
  creatorName?: string;
  message: string;
  firstItem: boolean;
  attachments?: Array<Attachment> | null | undefined;
};
const styles = {
  header: {
    backgroundColor: "#F9F9F9",
    padding: 5,
    borderRadius: 5
  },
  container: {
    margin: "10px",
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    paddingBottom: 30
  },
  message: {
    padding: 5
  },
  attachmentContainer: {
    marginBottom: 10,
    padding: 5
  }
};
export class TicketMessage extends React.Component<TicketMessageProps, any> {
  constructor(props: TicketMessageProps) {
    super(props);
    this.state = {};
  }

  render() {
    return <div className="card-shadow" style={styles.container}>
        <div style={styles.header}>
          {this.props.firstItem && <div>
            {this.props.subject != null && <h1>{this.props.subject}</h1>}
            <h3>{this.props.userName} Reported an issue </h3>
            <h4>{this.props.messageDate}</h4>
          </div>}
          {!this.props.firstItem && <div>
            <h3>{this.props.replierName} Replied to: {this.props.userName}</h3>
            <h4>{this.props.messageDate}</h4>
          </div>}
        </div>
        <Divider style={{
        marginTop: 0
      }} />
        <div style={styles.message}>
          <h4>{this.props.message}</h4>
        </div>
        {this.props.attachments != null && this.props.attachments.length > 0 && <div style={styles.attachmentContainer}>
          <Divider />
          <AttachmentListing attachments={this.props.attachments} />
        </div>}
      </div>;
  }

}
