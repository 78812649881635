import React, {useCallback, useContext, useEffect, useState} from 'react'
import {DefaultButton, HeaderFlexContainer, PageTitle, StyledLoader} from './CXStyleSheet'
import {ProgressBarWithBackground} from './ProgressBarWithBackground'
import {CXContext} from '../../../context/CXContext'
import styled from 'styled-components'
import {ProgressBar} from './ProgressBar'
import {PulseLoader} from 'react-spinners'
import DefaultTheme, {toSentenceCase} from '../../../util/Helper'
import {css} from '@emotion/react'
import {OptionOutsideSelect} from './OptionOutsideSelect'
import {FilterOptions} from '../../../data/api/generated/EmpowerSwaggerApi'
import {OptionValue} from '../../../data/api/APITypes'
import Select, {MultiValue, RemoveValueActionMeta, SingleValue} from 'react-select'

interface IsSelectedInterface {
  Year: OptionValue[]
  Month: OptionValue[]
  Region: OptionValue | undefined
  Store: OptionValue | undefined
}

const initialState = {
  Month: [],
  Region: undefined,
  Store: undefined,
  Year: [],
}
export const CXReport = () => {
  const cxContext = useContext(CXContext)
  const {kpiAssessments, kpiElementAnswers, filterOptionValues, commonIssues, kpiQueryParameters} = cxContext.kpiData
  const [isSelected, setIsSelected] = useState<IsSelectedInterface>(initialState)
  const [buttonIsActive, setButtonIsActive] = useState<boolean>(false)

  const onChangeHandler = (
    key: keyof IsSelectedInterface,
    value: SingleValue<OptionValue> | MultiValue<OptionValue>,
    actionType?: any
  ) => {
    let newValue = value
    if (Array.isArray(value)) {
      const preValues = [...(isSelected[key] as OptionValue[])]
      if (actionType?.action === 'remove-value') {
        newValue = preValues.filter(p => p.value !== actionType.removedValue.value)
      } else {
        const valueExists = preValues.filter(p => p.value === value[0].value).length > 0
        if (valueExists) return (newValue = null)
        newValue = [...preValues, ...value]
      }
    }
    setButtonIsActive(true)
    return setIsSelected(prevState => ({...prevState, [key]: newValue}))
  }

  const applyHandler = useCallback(
    (savedQuery?: IsSelectedInterface) => {
      const selectedOptions = savedQuery ?? isSelected
      const query = {
        year: selectedOptions.Year.map(v => v.value!),
        month: selectedOptions.Month.map(v => v.value!),
        region: selectedOptions.Region?.value,
        store: selectedOptions.Store?.value,
      }
      cxContext.api.getKpiData(query)

      const shouldNotSave =
        Object.entries(query).filter(q => {
          const initialStateElement = initialState[toSentenceCase(q[0]) as keyof IsSelectedInterface]
          if (Array.isArray(initialStateElement) && Array.isArray(q[1])) {
            return initialStateElement.length !== q[1].length
          }
          return initialStateElement !== q[1]
        }).length === 0

      !shouldNotSave && localStorage.setItem('savedFilterOptions', JSON.stringify(selectedOptions))
    },
    [isSelected, cxContext.api]
  )

  const getInitOption = (key: string): OptionValue => {
    return {
      value: undefined,
      label: `All ${toSentenceCase(key)}s`,
    }
  }

  const getOptions = useCallback(
    (key: keyof FilterOptions, optionValues?: FilterOptions): OptionValue[] => {
      const values = optionValues ?? (filterOptionValues && filterOptionValues)
      if (!values) return []
      switch (key) {
        case 'year':
        case 'month':
          return (
            values[key]?.map(v => {
              return {
                value: v,
                label: v,
              }
            }) ?? []
          )
        case 'region':
        case 'store':
          const structuredValues =
            values[key]?.map(v => {
              return {
                value: v.id!,
                label: v.name!,
              }
            }) ?? []
          return [...[getInitOption(key)], ...structuredValues]
      }
    },
    [filterOptionValues]
  )

  const getOption = useCallback(
    (key: keyof Pick<FilterOptions, 'region' | 'store'>, value?: string): OptionValue | undefined => {
      if (!value) {
        return getInitOption(key)
      }
      if (filterOptionValues) {
        const found = filterOptionValues[key]
          ?.filter(v => v.id === value)
          ?.map(v => {
            return {
              value: v.id!,
              label: v.name!,
            } as OptionValue
          })
        return found && found[0]
      }
    },
    [filterOptionValues]
  )

  useEffect(() => {
    if (!kpiQueryParameters) return
    Object.entries(kpiQueryParameters).forEach(q => {
      let value: OptionValue | OptionValue[] | undefined
      if (['year', 'month'].includes(q[0])) {
        value = getOptions(q[0] as keyof FilterOptions, {
          [q[0]]: q[1] as FilterOptions,
        })
      } else {
        value = getOption(q[0] as keyof Pick<FilterOptions, 'region' | 'store'>, q[1])
      }
      return setIsSelected(prevState => {
        return {
          ...prevState,
          [toSentenceCase(q[0])]: value,
        }
      })
    })
    setButtonIsActive(false)
  }, [getOption, getOptions, kpiQueryParameters])

  useEffect(() => {
    const savedFilterOptions = localStorage.getItem('savedFilterOptions')
      ? JSON.parse(String(localStorage.getItem('savedFilterOptions')))
      : undefined
    applyHandler(savedFilterOptions)
  }, [])

  return (
    <StyledLoader
      active={cxContext.loading}
      text="Loading content..."
      classNamePrefix="MyLoader_"
      spinner={
        <PulseLoader
          color={`${DefaultTheme.color.primaryRed}`}
          css={css`
            display: block;
            margin: 0 auto;
          `}
          speedMultiplier={0.5}
        />
      }>
      <HeaderFlexContainer>
        <PageTitle>Stores Visit Reporting</PageTitle>
      </HeaderFlexContainer>
      <Container>
        <ResultsWrapper>
          <StatsBlock>
            <ReportingHeading>Total Count of CX Completed</ReportingHeading>
            <ReportingText style={{marginBottom: 10}}>
              {kpiAssessments?.completed} / {kpiAssessments?.total} completed
            </ReportingText>
            <ProgressBarWithBackground completed={kpiAssessments?.percentage!} />
          </StatsBlock>

          <StatsBlock>
            <ReportingHeading>Correct vs Incorrect CX</ReportingHeading>
            <ReportingText style={{marginBottom: 10}}>
              {kpiElementAnswers?.positive} Correct vs {kpiElementAnswers?.negative} Issues
            </ReportingText>
            <ProgressBarWithBackground completed={kpiElementAnswers?.percentage!} />
          </StatsBlock>

          <StatsBlock>
            <ReportingHeading>Common Issues</ReportingHeading>
            <ReportingText>Top 5 Issues</ReportingText>
            {commonIssues?.map((issue, index) => (
              <CommonIssue key={issue.name}>
                <ReportingText style={{flex: 1}}>{issue.name}</ReportingText>
                <div
                  style={{
                    flex: 2,
                  }}>
                  <ProgressBar numberOfIssues={issue.count!} />
                </div>
              </CommonIssue>
            ))}
          </StatsBlock>
        </ResultsWrapper>
        <FilterWrapper>
          <FilterHeader>Filters</FilterHeader>
          <FilterLabel>Year</FilterLabel>
          <OptionOutsideSelect
            isMulti
            onChange={(value: any, actionType: RemoveValueActionMeta<any>) =>
              onChangeHandler('Year', value, actionType)
            }
            options={getOptions('year')}
            components={{DropdownIndicator: null}}
            value={isSelected.Year}
            placeholder={'Enter Year'}
            formatCreateLabel={() => undefined}
            onCreateOption={() => undefined}
          />
          <FilterLabel>Month</FilterLabel>
          <OptionOutsideSelect
            isMulti
            onChange={(value: OptionValue, actionType: RemoveValueActionMeta<OptionValue>) =>
              onChangeHandler('Month', value, actionType)
            }
            options={getOptions('month')}
            components={{DropdownIndicator: null}}
            value={isSelected.Month}
            placeholder={'Select Month'}
            formatCreateLabel={() => undefined}
            onCreateOption={() => undefined}
          />
          <FilterLabel>Region</FilterLabel>
          <Select
            onChange={(value: SingleValue<OptionValue>) => onChangeHandler('Region', value)}
            options={getOptions('region')}
            value={isSelected.Region}
          />
          <FilterLabel>Store</FilterLabel>
          <Select
            onChange={(value: SingleValue<OptionValue>) => onChangeHandler('Store', value)}
            options={getOptions('store')}
            value={isSelected.Store}
          />
          <DefaultButton style={{marginTop: 20}} isActive={buttonIsActive} onClick={() => applyHandler()}>
            Apply
          </DefaultButton>
        </FilterWrapper>
      </Container>
    </StyledLoader>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`
const StatsBlock = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 16px 20px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 8px rgba(37, 37, 40, 0.08);
`
const ReportingHeading = styled.h4`
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 0;
`
const ReportingText = styled.label`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #707070;
  display: flex;
`
const ResultsWrapper = styled.div`
  flex-grow: 2;
  padding: 24px;
  height: 100%;
`
const FilterWrapper = styled.div`
  width: 288px;
  background: #ffffff;
  height: 100%;
  padding: 20px 22px;
`
const FilterHeader = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #333333;
`
const FilterLabel = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #707070;
  margin-top: 12px;
`
const CommonIssue = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
`
