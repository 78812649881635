import {AttachmentResponse, EmpowerAPIResponse} from "./APITypes"
import {apiPost, EmpowerAPIConfig} from "./APIConfig"
import {UserStore} from "../store/UserStore"

export class EmpowerAttachmentsAPI {
  static Endpoints = {
    fetchAttachment: (id: string) => "/api/v1/attachments/" + id,
    downloadAttachment: (id: string) => "/api/v1/attachments/" + id + "?isDownload=true",
    uploadAttachment: () => "/api/v1/attachments"
  }
  static asyncDownloadFile = (filename: string, attachmentId: string) => {
    let url = EmpowerAttachmentsAPI.Endpoints.downloadAttachment(attachmentId)
    EmpowerAttachmentsAPI.downloadViaXhttp(filename, url)
  }
  static fetchAttachment = (attachmentId: string): Promise<string> => {
    let url = EmpowerAttachmentsAPI.Endpoints.fetchAttachment(attachmentId)
    return EmpowerAttachmentsAPI.loadIntoImage(url)
  }
  static uploadFile = (filename: string, file: any, fileType: string): Promise<any> => {
    const data = new FormData()
    data.set('file', file)
    // return customHeaderClient({ "Content-Type" : "multipart/form-data"}).post(EmpowerAttachmentsAPI.Endpoints.uploadAttachment(), data)
    return apiPost(EmpowerAttachmentsAPI.Endpoints.uploadAttachment(), data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
      // @ts-ignore
    }).then((response: EmpowerAPIResponse<AttachmentResponse>) => {
      return response.data.attachmentId
    })
  }
  static downloadViaXhttp = (filename: string, url: string) => {
    // Use XMLHttpRequest instead of Jquery $ajax
    let token = UserStore.getAzureToken()
    let xhttp = new XMLHttpRequest()

    xhttp.onreadystatechange = function () {
      var a

      if (xhttp.readyState === 4 && xhttp.status === 200) {
        // Trick for making downloadable link
        a = document.createElement('a')
        a.href = window.URL.createObjectURL(xhttp.response)
        // Give filename you wish to download
        a.download = filename
        a.style.display = 'none'

        if (document.body != null) {
          document.body.appendChild(a)
          a.click()
        }
      }
    }

    // Post data to URL which handles post request
    xhttp.open("GET", EmpowerAPIConfig.baseURL + url)
    xhttp.setRequestHeader("Accept", "*/*")

    if (token != null) {
      xhttp.setRequestHeader("Authorization", token)
    }

    // You should set responseType as blob for binary responses
    xhttp.responseType = 'blob'
    xhttp.send()
  }
  static loadIntoImage = (url: string): Promise<string> => {
    return new Promise(function (resolve, reject) {
      let token = UserStore.getAzureToken()
      let xhttp = new XMLHttpRequest()

      xhttp.onload = function () {
        if (xhttp.readyState === 4 && xhttp.status === 200) {
          var blob = xhttp.response
          var reader = new FileReader()

          reader.onload = function () {
            // @ts-ignore
            var base64data: string = reader.result
            resolve(base64data)
          }

          reader.readAsDataURL(blob)
        }
      }

      // Post data to URL which handles post request
      xhttp.open("GET", EmpowerAPIConfig.baseURL + url)
      xhttp.setRequestHeader("Accept", "*/*")

      if (token != null) {
        xhttp.setRequestHeader("Authorization", token)
      }

      // You should set responseType as blob for binary responses
      xhttp.responseType = 'blob'
      xhttp.send()
    })
  }
}
