import {format} from "date-fns"

export const getFormattedDate = (date: string | undefined, dateFormat?: string) => {
    if (!date) {
        return null
    }
    if (date === '' || date === null) {
        return date
    }
    return format(Date.parse(date), dateFormat ?? 'dd/MM/yyyy')
}

export const toSentenceCase = (text: string) => {
    return text.substring(0, 1).toUpperCase() + text.substring(1, text.length)
}

enum Colours {
    primaryRed = "#DA2131",
    primaryBlue = "#2976A7",
    yellow = "#FFC107",
    yellow20 = "#FFF3CD",
    good = "#8BC34A",
    good20 = "#E8F3DB",
    orange = "#F47909",
    orange20 = "#FDE4CE",
    red = "#EA130E",
    red20 = "#FDD9D7",
    linkColour = "#0A6CBD",
    white = "#FFFFFF",
    mediumGrey = "#e1e1e1",
    lightGrey = "#FAFAFA",
    grey1a = "#FDFDFD",
    grey1b = "#FAFAFA",
    grey2 = "#F2F2F2",
    grey2a = "#EEEEEE",
    grey3 = "#E0E0E0",
    grey4 = "#C4C4C4",
    grey5 = "#999999",
    grey6 = "#707070",
    grey7 = "#4D4D4D",
    grey8 = "#333333",
    grey9 = "#262626",
    black = "#000000",
    lightBlue = "#D2E9F9",
    plpImageBackground = 'clear',
    heading = "#333333",
    heading5 = "#707070",
    text = "#333333",
}

enum Fonts {
    default = 'font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;',
}

const DefaultTheme = {
    color: Colours,
    font: Fonts,
}

export default DefaultTheme
