import React from "react"
import {version} from '../../package.json'
import styled from "styled-components"
import {UserStore} from "../data/store/UserStore"

interface FooterProps {
    style?: React.CSSProperties
}

export const Footer: React.FC<FooterProps> = (props) => {
    if (!UserStore.getAzureToken()) return null
    return <FooterWrapper style={props.style}>
        <span>Version: {version}</span>
    </FooterWrapper>
}

const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 64px;
  right: 0;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #ffffff;
  border-top: 1px solid #E0E0E0;
  justify-content: center;
`
