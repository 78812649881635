import {
    AnnouncementDetailResponse,
    AnnouncementStatus,
    AnnouncementSummaryResponse,
    CreateAnnouncementRequest,
    EmpowerAPIResponse,
    MessageStatusResponse
} from "./APITypes";
import {apiDelete, apiGet, apiPatch, apiPost, calculateNextPageNumber, EmpowerAPIConfig} from "./APIConfig";
export class EmpowerAnnouncementAPI {
    static Endpoints = {
        fetchAnnouncements: (pageNumber: number) => "/api/v1/announcements/sent?pageSize=" + EmpowerAPIConfig.defaultPageSize + "&pageNumber=" + pageNumber,
        refreshAnnouncements: (totalItemCount: number) => "/api/v1/announcements/sent?pageSize=" + totalItemCount,
        fetchAnnouncementStatuses: () => "/api/v1/announcements/statuses",
        fetchAnnouncementDetails: (id: string) => "/api/v1/announcements/" + id,
        toggleAnnouncementRead: (id: string) => "/api/v1/announcements/" + id + "/toggleRead",
        createAnnouncements: () => "/api/v1/announcements",
        updateAnnouncementStatus: (id: string) => "/api/v1/announcements/" + id + "/status",
        deleteAnnouncement: (id: string) => "/api/v1/announcements/" + id
    };
    static fetchAnnouncements = (startIndex: number = 0): Promise<EmpowerAPIResponse<AnnouncementSummaryResponse>> => apiGet(EmpowerAnnouncementAPI.Endpoints.fetchAnnouncements(calculateNextPageNumber(startIndex)));
    static refreshAnnouncements = (currentCount: number): Promise<EmpowerAPIResponse<AnnouncementSummaryResponse>> => apiGet(EmpowerAnnouncementAPI.Endpoints.refreshAnnouncements(currentCount));
    static fetchAnnouncementStatuses = (): Promise<EmpowerAPIResponse<Array<MessageStatusResponse>>> => apiGet(EmpowerAnnouncementAPI.Endpoints.fetchAnnouncementStatuses());
    static fetchAnnouncementDetails = (id: string): Promise<EmpowerAPIResponse<AnnouncementDetailResponse>> => apiGet(EmpowerAnnouncementAPI.Endpoints.fetchAnnouncementDetails(id));
    static toggleAnnouncement = (id: string): Promise<any> => apiPatch(EmpowerAnnouncementAPI.Endpoints.toggleAnnouncementRead(id));
    // @ts-ignore
    static createAnnouncement = (request: CreateAnnouncementRequest): Promise<any> => apiPost(EmpowerAnnouncementAPI.Endpoints.createAnnouncements(), request);
    static deleteAnnouncment = (id: string): Promise<any> => apiDelete(EmpowerAnnouncementAPI.Endpoints.deleteAnnouncement(id));
    static publishAnnouncement = (announcementId: string): Promise<any> => {

        return apiGet(EmpowerAnnouncementAPI.Endpoints.fetchAnnouncementStatuses()).then(response => {
            return response.data;
            // @ts-ignore
        }).then((statuses: Array<AnnouncementStatus>) => {
            console.log(JSON.stringify(statuses));
            return statuses.find((value: AnnouncementStatus) => {
                return value.description.includes("Publish");
            });
        }).then((status: AnnouncementStatus | null | undefined) => {
            if (status != null) {
                return apiPatch(EmpowerAnnouncementAPI.Endpoints.updateAnnouncementStatus(announcementId), {
                    "announcementStatusId": status.messageStatusId
                });
            } else {
                return null;
            }
        });
    };
    static unpublishAnnouncement = (announcementId: string): Promise<any> => {
        return apiGet(EmpowerAnnouncementAPI.Endpoints.fetchAnnouncementStatuses()).then(response => {
            return response.data;
            // @ts-ignore
        }).then((statuses: Array<AnnouncementStatus>) => {
            console.log(JSON.stringify(statuses));
            return statuses.find((value: AnnouncementStatus) => {
                return value.description.includes("Create");
            });
        }).then((status: AnnouncementStatus | null | undefined) => {
            if (status != null) {
                return apiPatch(EmpowerAnnouncementAPI.Endpoints.updateAnnouncementStatus(announcementId), {
                    "announcementStatusId": status.messageStatusId
                });
            } else {
                return null;
            }
        });
    };
}
