import {
    BodyText,
    CheckboxText,
    CheckImageContainer,
    ChevronImageContainer,
    CollapseContainer,
    DefaultButton, EditImageContainer,
    GreyContainer,
    HorizontalFlexContainer,
    HorizontalLine,
    PanelContainer,
    styles
} from "./CXStyleSheet"
import React, {useContext, useEffect, useState} from "react"
import chevronDown from "../../../img/chevron-down.png"
import chevronUp from "../../../img/chevron-up.png"
import add from "../../../img/add.png"
import emptyCheck from "../../../img/checkEmpty.png"
import redCheck from "../../../img/check-red.png"
import chevronRightGrey from "../../../img/chevron-right-grey.png"
import chevronRight from "../../../img/chevron-right.png"
import {CXModalAddCategory} from "./CXModalAddCategory"
import {CXModalAddSubcategory} from "./CXModalAddSubcategory"
import {CXContext} from "../../../context/CXContext"
import {LibraryCategory, LibrarySubcategory} from "../../../data/api/generated/EmpowerSwaggerApi"
import {CXSelectionContext} from "../../../context/CXSelectionContext"
import editIcon from "../../../img/edit.png"
import {CxModalEditFocusAreaItems} from "./CXModalEditFocusAreaData"
import {ImageButton} from "../../../components/ImageButton"

interface RenderCategoryProps {
    category: LibraryCategory
    hasSelectedSubcategory: boolean
    onSelect: () => void
    index: number
}

interface RenderSubcategoryProps {
    subcategory: LibrarySubcategory
    isSelected: boolean
    onSelect: () => void
    hasCurrentTemplate: boolean
    categoryId: string
}


const RenderSubcategory: React.FC<RenderSubcategoryProps> = (props) => {
    const {hasCurrentTemplate} = props
    const selectionContext = useContext(CXSelectionContext)
    const [showModal, setShowModal] = useState<'editSubcategory' | null>(null)

    const isSelectedSubcat = (): boolean => {
        return selectionContext.isSelectedSubcategory(props.subcategory.id!!)
    }

    const [isChecked, setIsChecked] = useState(isSelectedSubcat)

    useEffect(() => {
        setIsChecked(isSelectedSubcat)
        // eslint-disable-next-line
    }, [selectionContext])

    return (
        <>
            <PanelContainer style={{backgroundColor: props.isSelected ? ("#fafafa") : ("transparent")}}
                            onClick={props.onSelect}>
                <HorizontalFlexContainer>
                    {!hasCurrentTemplate && <CheckImageContainer
                      onClick={() => {
                          selectionContext.toggleSubcategory(props.subcategory.id!!, !isChecked)
                          setIsChecked(!isChecked)
                      }}
                      src={isChecked ? (redCheck) : (emptyCheck)}/>}
                    <CheckboxText style={{fontWeight: props.isSelected ? (700) : (400), cursor: "pointer"}}>
                        <span>{props.subcategory.name}</span>
                    </CheckboxText>
                </HorizontalFlexContainer>
                <HorizontalFlexContainer alignItems={'center'}>
                    <GreyContainer style={!props.subcategory.isRepeat ? {backgroundColor: 'transparent'} : {}}>
                        {props.subcategory.isRepeat && 'Repeat'}
                    </GreyContainer>
                    <HorizontalFlexContainer>
                        {!hasCurrentTemplate &&
                          <EditImageContainer style={{right: 15}} onClick={() => setShowModal('editSubcategory')}>
                            <img src={editIcon} alt={"edit-icon"} width={10} height={10}/>
                          </EditImageContainer>}
                        <ChevronImageContainer src={props.isSelected ? (chevronRight) : (chevronRightGrey)}/>
                    </HorizontalFlexContainer>
                </HorizontalFlexContainer>
            </PanelContainer>
            <HorizontalLine style={{marginLeft: 20}}/>
            <CxModalEditFocusAreaItems id={props.subcategory.id!} name={props.subcategory.name!} type={'Subcategory'}
                                       show={showModal === 'editSubcategory'}
                                       additionalData={{
                                           categoryId: props.categoryId,
                                           isRepeat: props.subcategory.isRepeat,
                                           repeatType: props.subcategory.repeatType!
                                       }}
                                       onClose={() => setShowModal(null)}/>
        </>
    )
}


const RenderCategory: React.FC<RenderCategoryProps> = (props) => {
    const [showSubcategory, setShowSubcategory] = useState(props.index === 0)
    const [showModal, setShowModal] = useState<'addSubcategory' | 'editSubcategory' | null>(null)
    const [selectedSubcategory, setSelectedSubcategory] = useState(-1)
    const cxContext = useContext(CXContext)
    const currentTemplate = cxContext.currentTemplate !== null

    const toggleSubcategory = () => {
        return setShowSubcategory(prevState => !prevState)
    }

    const onSelectHandler = (index: number, subcategory: LibrarySubcategory) => {
        return () => {
            setSelectedSubcategory(index)
            props.onSelect()
            cxContext.setActiveSubcategory(subcategory.id!)
        }
    }

    return (
        <>
            <CollapseContainer>
                <BodyText style={{...styles.bodyBold, flex: 1}}
                          onClick={toggleSubcategory}>{props.category.name}</BodyText>
                <HorizontalFlexContainer>
                    {!currentTemplate && <EditImageContainer onClick={() => setShowModal('editSubcategory')}>
                      <img src={editIcon} alt={"edit-icon"} width={10} height={10}/>
                    </EditImageContainer>}
                    <ChevronImageContainer onClick={toggleSubcategory}
                                           src={showSubcategory ? (chevronUp) : (chevronDown)}/>
                </HorizontalFlexContainer>
            </CollapseContainer>
            {showSubcategory
                && props.category.subcategories != null
                && props.category.subcategories.map((subcategory, index) => <RenderSubcategory
                    key={index}
                    isSelected={props.hasSelectedSubcategory && index === selectedSubcategory}
                    onSelect={onSelectHandler(index, subcategory)}
                    subcategory={subcategory}
                    hasCurrentTemplate={currentTemplate}
                    categoryId={props.category.id!}
                />)}
            {!currentTemplate && showSubcategory && <div style={{padding: '20px'}}>
              <ImageButton
                text={'Add Subcategory'}
                imagePosition={'left'}
                image={{src: add, height: 14, width: 14, alt: 'add-icon'}}
                onClick={() => setShowModal('addSubcategory')}
              />
            </div>}
            <CXModalAddSubcategory categoryId={props.category!!.id!!} show={showModal === 'addSubcategory'}
                                   onClose={() => setShowModal(null)}/>
            <CxModalEditFocusAreaItems id={props.category.id!} name={props.category.name!} type={'Category'}
                                       show={showModal === 'editSubcategory'}
                                       onClose={() => setShowModal(null)}/>
        </>
    )
}

export const CXSplitLeft: React.FC = () => {
    const [showModalCategory, setShowModalCategory] = useState(false)
    const [categoryWithSelectedSubcategory, setCategoryWithSelectedSubcategory] = useState(-1)
    const [isChecked, setIsChecked] = useState(false)

    const cxContext = useContext(CXContext)
    const selectionContext = useContext(CXSelectionContext)
    const focusArea = cxContext.activeFocusArea
    const hasCurrentTemplate = cxContext.currentTemplate

    useEffect(() => {
        setCategoryWithSelectedSubcategory(-1)
    }, [focusArea])

    useEffect(() => {
        focusArea && setIsChecked(selectionContext.hasAllSelected(focusArea))
    }, [focusArea, selectionContext])

    if (!focusArea) {
        return null
    }

    return (
        <>
            {!hasCurrentTemplate &&
              <HorizontalFlexContainer style={{width: 'fit-content', margin: '10px 0 10px 20px'}} onClick={() => {
                  focusArea && selectionContext.toggleFocusArea(focusArea, !isChecked)
              }}>
                <CheckImageContainer src={isChecked ? (redCheck) : (emptyCheck)}/>
                <CheckboxText style={{cursor: "pointer"}}>
                  <span>Select All</span>
                </CheckboxText>
              </HorizontalFlexContainer>}
            {focusArea.categories != null &&
                focusArea.categories.map((category, index) => <RenderCategory
                    key={index}
                    index={index}
                    category={category}
                    hasSelectedSubcategory={categoryWithSelectedSubcategory === index}
                    onSelect={() => setCategoryWithSelectedSubcategory(index)}
                />)}
            {!hasCurrentTemplate && <div style={{padding: '20px 0'}}>
              <ImageButton
                text={'Add Category'}
                imagePosition={'left'}
                image={{src: add, height: 14, width: 14, alt: 'add-icon'}}
                onClick={() => setShowModalCategory(!showModalCategory)}
              />
            </div>}
            <CXModalAddCategory show={showModalCategory} onClose={() => setShowModalCategory(false)}/>
        </>
    )
}
