import React, {useContext, useEffect, useState} from "react"
import {Modal} from "antd"
import {BodyText, ModalCancelButton, ModalHeading, ModalSubmitInput, StyledInput} from "./CXStyleSheet"
import CreatableSelect from "react-select/creatable"
import {CXContext} from "../../../context/CXContext"
import {TemplateSurvey, UserRole} from "../../../data/api/generated/EmpowerSwaggerApi"

interface CXTemplateSettingsProps {
    show: boolean
    selectedTemplate: TemplateSurvey | undefined
    onClose?: () => void
}

export const CXTemplateSettings: React.FC<CXTemplateSettingsProps> = (props) => {
    const {show, selectedTemplate, onClose} = props
    const cxContext = useContext(CXContext)

    const [isVisible, setIsVisible] = useState(false)
    const [templateName, setTemplateName] = useState<string>(selectedTemplate?.name ?? '')
    const [userRoles, setUserRoles] = useState<UserRole[]>([])

    const handleOk = () => {
    }

    const handleClose = () => {
        onClose && onClose()
    }

    const onSubmit = () => {
        cxContext.api.updateTemplate({
            templateId: selectedTemplate?.templateId,
            name: templateName,
            roleIds: userRoles.map(r => r.roleId!) ?? []
        })
        handleClose()
    }

    const renderModalFooter = () => {
        return (
            <div>
                <span>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <div>
                            <ModalCancelButton onClick={handleClose}>Cancel</ModalCancelButton>
                        </div>
                            <ModalSubmitInput type={"button"} name={'submit'} value={'Done'} onClick={onSubmit}/>
                    </div>
                </span>
            </div>
        )
    }

    useEffect(() => {
        return setIsVisible(show)
    }, [show])

    useEffect(() => {
        const defaultRoleNames = selectedTemplate?.roles
        const defaultRoles = cxContext.availableRoles.filter(r => defaultRoleNames?.includes(r.description!))
        setUserRoles(defaultRoles)
    }, [selectedTemplate, cxContext.availableRoles])

    return (
        <Modal
            visible={isVisible}
            centered={true}
            footer={renderModalFooter()}
            onOk={handleOk}
        >
            <ModalHeading>Template Settings</ModalHeading>
            <BodyText>Template Name</BodyText>
            <StyledInput
                style={{marginBottom: 20}}
                type="text"
                defaultValue={templateName}
                onChange={(e) =>  setTemplateName(e.target.value)}
            />
            <BodyText>Assign this template to a role</BodyText>

            <CreatableSelect
                isMulti
                defaultValue={userRoles.map(r => {
                    return {
                        value: r.roleId,
                        label: r.role?.description,
                    }
                })}
                options={cxContext.availableRoles.map(r => {
                    return {
                        value: r.roleId!,
                        label: r.description!,
                    }
                }) ?? []}
                isClearable={false}
                components={{DropdownIndicator: null}}
                onChange={(values) => setUserRoles(values.map(r => {
                    return {
                        roleId: r.value,
                        description: r.label
                    }
                }))}
            />

        </Modal>
    )
}
