import React, {forwardRef} from "react"
import {Value, ValuesContainer, XButton} from "./CXStyleSheet"
import CreatableSelect from "react-select/creatable"

export const OptionOutsideSelect = forwardRef((props: any, ref) => {
    const {isMulti, value, onChange} = props

    const handleRemoveValue = (e: any) => {
        if (!onChange) return
        const {name: buttonName} = e.currentTarget
        const removedValue = value.find((val: any) => val.value === buttonName)
        if (!removedValue) return
        onChange(
            value.filter((val: any) => val.value !== buttonName),
            {action: "remove-value", removedValue}
        )
    }

    return (
        <div style={props.style}>
            <CreatableSelect {...props} value controlShouldRenderValue={!isMulti}/>
            <ValuesContainer>
                {isMulti && value.length > 0
                    && value.map((val: any) => (
                        <div key={val.value}>
                            <Value key={val.value}>
                                {val.label}
                                <div/>
                                <XButton
                                    name={val.value}
                                    onClick={handleRemoveValue}>
                                    ✕
                                </XButton>
                            </Value>
                        </div>
                    ))}
            </ValuesContainer>
        </div>
    )

})
