import {RecipientsStore} from "../../data/store/RecipientsStore"
import {Button, Checkbox, Form, FormInstance, Input, Spin} from "antd"
import TextArea from "antd/lib/input/TextArea"
import React from "react"
import {GroupDetailResponse} from "../../data/api/APITypes"
import {RecipientsGroupSelect} from "../../components/RecipientsGroupSelect"
import {AttachmentDescriptor, AttachmentUploader} from "../../components/AttachmentUploader"
const FormItem = Form.Item
type AnnouncementFormState = {
    submitInAction: boolean
    isSelectAllRecipients: boolean
    groups: Array<GroupDetailResponse>
    currentAttachments: Array<AttachmentDescriptor>
    uploadingFiles: boolean
}
type AnnouncementFormProps = {
    onFormSubmit: any
    form: any
}

class AnnouncementForm extends React.Component<AnnouncementFormProps, AnnouncementFormState> {
    constructor(props: AnnouncementFormProps) {
        super(props)
        const parentState = this.state
        this.state = {
            // @ts-ignore
            isSelectAllRecipients: false,
            // @ts-ignore
            uploadingFiles: false,
            ...parentState
        }
        RecipientsStore.getRecipientGroups().then(groups => {
            this.setState({
                groups: groups
            })
        })
        
    }
    formRef = React.createRef<FormInstance<any>>()
    showBusy = (isBusy: boolean) => {
        this.setState({
            uploadingFiles: isBusy
        })
    }
    // @ts-ignore
    handleSubmit = (values: any) => {
        // @ts-ignore
       
       
                this.props.onFormSubmit({
                    ...values,
                    attachments: values.currentAttachments
                })
                this.formRef.current!.resetFields()
            }
     
 

    render() {
        const formItemLayout = {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 14
            }
        }
        return <Spin spinning={this.state.uploadingFiles}>
            <Form ref={this.formRef} onFinish={this.handleSubmit}>
                <FormItem {...formItemLayout} label="Subject" name={"subject"} rules={[{
                    required: true,
                    message: "Please input your subject!"
                }]}>

                    <Input placeholder="Subject"/>
                </FormItem>

                <FormItem {...formItemLayout} label="Body" name={"body"} rules={[{
                    required: true,
                    message: "Please input your body"
                }]}>

                    <TextArea placeholder="" style={{
                        resize: "none"
                    }}/>
                </FormItem>

                <FormItem {...formItemLayout} label="Recipients" name={"groupRecipients"} rules={[{
                    required: true,
                    message: "Please choose recipients for the comms"
                }]}>
                    <RecipientsGroupSelect/>
                </FormItem>

                <Form.Item {...formItemLayout} label="Attach" name={"currentAttachments"}>
                    <AttachmentUploader isBusy={this.showBusy} onChange={() => {
                    }}/>
                </Form.Item>

                <FormItem {...formItemLayout} label="Publish" name={"shouldPublish"} rules={[{
                    required: false,
                    message: "Should the build auto publish"
                }]}>
                    <Checkbox>Automatically Publish</Checkbox>
                </FormItem>

                <FormItem wrapperCol={{
                    span: 12,
                    offset: 6
                }}>
                    <Button type="primary" htmlType="submit" loading={this.state.submitInAction} style={{
                        display: "block",
                        margin: "auto"
                    }}>
                        Submit
                    </Button>
                </FormItem>
            </Form>
        </Spin>
    }

}

export class CreateAnnouncementForm extends React.Component<any, any> {
    render() {
        let WrappedForm = AnnouncementForm
        // @ts-ignore
        return <WrappedForm onFormSubmit={this.props.onFormSubmit}/>
    }

}
