import {AzureLoginResponse} from "../api/generated/EmpowerSwaggerApi"

export class UserStore {
  static storeAzureToken(token: string  | undefined) {
    localStorage.removeItem("azureToken");
    localStorage.setItem("azureToken", "Bearer " + token);
  }

  static storeLogin(loginResponse: AzureLoginResponse) {
    localStorage.setItem("divisionId", loginResponse.divisionId ?? '');
    localStorage.setItem("currentTeamName", loginResponse.currentTeamName ?? '');
    localStorage.setItem("isoCountryCode", loginResponse.isoCountryCode ?? '');
    localStorage.setItem("userName", loginResponse.userName ?? '');
    localStorage.setItem("userTypeId", loginResponse.userTypeId ? loginResponse.userTypeId.toString() : '');
  }

  static clear() {
    localStorage.removeItem("azureToken");
    localStorage.removeItem("divisionId");
    localStorage.removeItem("currentTeamName");
    localStorage.removeItem("isoCountryCode");
    localStorage.removeItem("userName");
    localStorage.removeItem("userTypeId");
    localStorage.removeItem("savedFilterOptions");
  }

  static getdivisionId = (): string | null | undefined => localStorage.getItem("divisionId");
  static getcurrentTeamName = (): string | null | undefined => localStorage.getItem("currentTeamName");
  static getisoCountryCode = (): string | null | undefined => localStorage.getItem("isoCountryCode");
  static getuserName = (): string | null | undefined => localStorage.getItem("userName");
  static getuserTypeId = (): string | null | undefined => localStorage.getItem("userTypeId");
  static getAzureToken = (): string | null | undefined => localStorage.getItem("azureToken");
}
