import React, {useCallback, useContext, useEffect, useState} from 'react'
import styled from "styled-components"
import DefaultTheme from "../util/Helper"
import {CXContext} from "../context/CXContext"

interface SearchControlProps {
    searchItems: any[]
    setFilteredItems: (items: any[]) => void
    returnSearchQuery?: (query: string) => void
    show?: boolean
}

interface State {
    isOpen: boolean
    query: string
}

export const SearchControl: React.FC<SearchControlProps> = (props) => {
    const {searchItems, setFilteredItems, show} = props
    const cxContext = useContext(CXContext)
    
    const [state, setState] = useState<State>({
        isOpen: false,
        query: '',
    })
    
    useEffect(() => {
        cxContext.setCurrentSearchQuery(state.query)
        if (state.query.length === 0) { return setFilteredItems(searchItems) }
        const filteredItems = searchItems.filter((item) => item.name.includes(state.query))
        setFilteredItems(filteredItems)
        return () => cxContext.setCurrentSearchQuery('')
    }, [state.query, searchItems, setFilteredItems, cxContext])
    
    const searchOnEnter = useCallback((searchTerm?: string) => {
        const query = ((typeof searchTerm === 'string') && searchTerm) ? searchTerm : state.query
        if (query.length === 0) { return }
        setState(prevState => ({...prevState, isOpen: false, query}))
    }, [state.query])
    
    const searchOnType = (query: string) => {
        setState(prevState => ({...prevState, query: query}))
    }
    
    if (show === false) {
        return null
    }
    
    return (
        <>
            <StyledInitialInputWrap>
                <StyledInitialInputContent>
                    <StyledInitialInputIcon>
                        <img src={'/images/icons/search-icon.png'} alt="search icon" width={18} height={18} />
                    </StyledInitialInputIcon>
                    <input
                        onFocus={() => setState(prevState => ({...prevState, isOpen: true}))}
                        onChange={(e) => searchOnType(e.target.value)}
                        onKeyPress={(e) => e.key.toLowerCase() === 'enter' && searchOnEnter()}                        placeholder={'Search'}
                        defaultValue={state.query}
                    />
                </StyledInitialInputContent>
            </StyledInitialInputWrap>
        </>
    )
}

const StyledInitialInputWrap = styled.div`
  width: 100%;
  max-width: 300px;
  height: 42px;
  border: 1px solid ${DefaultTheme.color.grey3};
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  >input {
    width: 100%;
    height: 100%;
    border: none;
  }
  @media (max-width: 700px) {
    display: none;
    visibility: hidden;
  }
`

const StyledInitialInputContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  input {
    border: none;
    width: 100%;
    height: 100%;
    padding-left: 48px;
  }
  input:focus {
    outline: none;
  }
`

const StyledInitialInputIcon = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 14px;
  img {
    height: 18px;
    width: auto;
  }
`
