export type EmpowerAPIResponse<T> = {
    data: T;
};
export type AnnouncementDetailResponse = {
    announcementId: string;
    announcementStatusId: number;
    subject: string;
    body: string;
    from: string;
    announcementStatusDescription: string;
    datePublished: string;
    attachments: Array<Attachment>;
};
export type MessageStatusResponse = {
    messageStatusId: number;
    description: string;
};
export type AnnouncementSummaryResponse = {
    totalItemCount: number;
    sentAnnouncements: Array<AnnouncementSummary>;
};
export type AnnouncementSummary = {
    announcementId: string;
    subject: string;
    from: string;
    attachementIds: Array<string>;
    isRead: boolean;
    isPinned: boolean;
    datePublished: string;
    announcementStatusDescription: string;
};
export type AnnouncementStatus = {
    messageStatusId: number;
    description: string;
};
export type AttachmentResponse = {
    attachmentId: string;
};
export type RecipientRequest = {
    recipientId: string;
    recipientType: string;
};
export type CreateAnnouncementRequest = {
    announcementStatusId: number;
    subject: string;
    body: string;
    recipients: Array<RecipientRequest>;
};
export type LoginRequest = {
    userCode: string;
    password: string;
    token?: string;
    cardNumber?: string;
};
export type LoginResponse = {
   
    divisionId: string;
    currentTeamName: string;
    isoCountryCode: string;
    userName: string;
    userTypeId: number;
    userRoleTitles:string[];
    associatedStore :string;
    isManager:boolean;
    accessToken: string;
    inTouchAccessToken:string;
};

export type UserDetailResponse = {
    recipientId: string;
    recipientType: string;
    firstName: string;
    lastName: string;
    fullName: string;
    countryDescription: string;
    areaDescription: string;
    regionDescription: string;
    teamDescription: string;
    countryId: string;
    regionId: string;
    areaId: string;
    teamId: string;
};
export type GroupDetailResponse = {
    recipientId: string;
    recipientType: string;
    recipientDescription: string;
    countryDescription: string;
    areaDescription: string;
    regionDescription: string;
    teamDescription: string;
    countryId: string;
    regionId: string;
    areaId: string;
    teamId: string;
};
export type CreateTaskRequest = {
    taskPriorityId: number;
    taskStatusId: number;
    subject: string;
    body: string;
    dueDate: string;
};
export type GetTasksResponse = {
    totalItemCount: number;
    sentTasks: Array<TaskSummaryResponse>;
};
export type TaskSummaryResponse = {
    taskId: string;
    taskStatusId: number;
    taskPriorityId: number;
    subject: string;
    completionPercentage: number;
    body: string;
    dueDate: string;
};
export type GetTaskStatusesResponse = {
    statuses: Array<TaskStatusResponse>;
};
export type TaskStatusResponse = {
    taskStatusId: number;
    description: string;
};
export type GetTaskPrioritiesResponse = {
    priorities: Array<TaskPriorityResponse>;
};
export type TaskPriorityResponse = {
    taskPriorityId: number;
    description: string;
};
export type UpdateTaskStatusRequest = {
    taskStatusId: number;
};
export type TaskDetailsResponse = {
    taskPriorityId: number;
    taskStatusId: number;
    subject: string;
    body: string;
    dueDate: string;
    from: string;
    attachments: Array<Attachment>;
    recipients: Array<SubTaskResponse>;
};
export type SubTaskResponse = {
    completedDate: string;
    completedBy: string;
    isCompleted: boolean;
    recipient: RecipientResponse;
};
export type RecipientResponse = {
    recipientId: string;
    recipientName: string;
    recipientType: number;
};
export type Attachment = {
    attachmentId: string;
    fileType: string;
    description: string;
};
export type AttachmentData = {};
export type TicketSummaryResponse = {
    itemCount: number;
    tickets: Array<TicketsSummaryResponse>;
};
export type TicketsSummaryResponse = {
    ticketId: string;
    ticketPriority: TicketPriorityResponse;
    ticketStatus: TicketStatusResponse;
    ticketCategory: TicketCategoryResponse;
    subject: string;
    body: string;
    from: string;
    attachments: Array<Attachment>;
    dateCreated: string;
    isPinned: boolean;
    isRead: boolean;
    replies: Array<TicketReply>;
    lastUpdatedDate?: string;
};
export type CreateTicketRequest = {
    messageId: string;
    ticketPriorityId: number;
    ticketStatusId: number;
    ticketCategoryId: string;
    assignedUserId: string;
    mergedTicketId: string;
    subject: string;
    body: string;
};
export type UpdateTicketStatusResponse = {
    ticketStatusId: number;
};
export type TicketPriorityResponse = {
    ticketPriorityId: number;
    ticketPriorityDescription: string;
};
export type TicketStatusResponse = {
    ticketStatusId: number;
    ticketStatusDescription: string;
};
export type TicketCategoryResponse = {
    ticketCategoryId: string;
    ticketCategoryDescription: string;
    parentTicketCategory: TicketCategoryResponse;
};
export type TicketReply = {
    ticketReplyId: string;
    body: string;
    subject: string;
    from: string;
    attachments: Array<Attachment>;
    dateCreated: string;
};
export type TicketDetailResponse = {
    ticketPriority: TicketPriorityResponse;
    ticketStatus: TicketStatusResponse;
    ticketCategory: TicketCategoryResponse;
    subject: string;
    body: string;
    from: string;
    attachments: Array<Attachment>;
    dateCreated: string;
    isPinned: boolean;
    isRead: boolean;
    replies: Array<TicketReply>;
};
export type GetUserTypesResponse = {
    userTypes: Array<UserTypeResponse>;
};
export type UserTypeResponse = {
    userTypeId: number;
    name: string;
};

export interface OptionValue {
    value: string | undefined
    label: string
}

export enum RepeatType {
    Window = 'Window',
    Entrance = 'Entrance',
    DriveAisle = 'DriveAisle'
}
