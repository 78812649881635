import chevronLeft from "../img/chevron-left.png"
import React from "react"
import styled from "styled-components"
import {useHistory} from "react-router-dom"

interface BackButtonProps {
    onClick?: () => void
    show?: boolean
}

export const BackButton: React.FC<BackButtonProps> = (props) => {
    const {onClick, show} = props
    const history = useHistory()
    
    if (show === false) {
        return null
    }
    
    const handleOnclick = () => {
        return onClick ? onClick() : history.goBack()
    }
    
    return <BackButtonWrapper onClick={handleOnclick}>
        <img src={chevronLeft} alt={'left-chevron-icon'}/>
    </BackButtonWrapper>
}

const BackButtonWrapper = styled.div`
    cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
  
    img {
      max-height: 20px;
    }
`
