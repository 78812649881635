import {Button, DatePicker, Form, FormInstance, Input, Select, Spin} from "antd"
import TextArea from "antd/lib/input/TextArea"
import React, {PureComponent} from "react"
import {RecipientsGroupSelect} from "../../components/RecipientsGroupSelect"
import {AttachmentUploader} from "../../components/AttachmentUploader"

type TaskFormProps = {
    onFormSubmit: any
}

type TaskFormState = {
    submitInAction: boolean
    uploadingFiles: boolean
    currentAttachments: undefined | any
}

class TaskForm extends React.Component<TaskFormProps, TaskFormState> {
    constructor(props: TaskFormProps) {
        super(props)
        this.state = {
            submitInAction: false,
            uploadingFiles: false,
            currentAttachments: undefined
        }
    }

    formRef = React.createRef<FormInstance<any>>()

    showBusy = (isBusy: boolean) => {
        this.setState({
            uploadingFiles: isBusy
        })
    }

    handleSubmit = (values: any) => {
        console.log("Received values of form: ", values)
        this.props.onFormSubmit({
            ...values,
            attachments: values.currentAttachments
        })
        this.formRef.current!.resetFields()
    }

    render() {
        const formItemLayout = {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 14
            }
        }
        return <Spin spinning={this.state.uploadingFiles}>
            <Form onFinish={this.handleSubmit} ref={this.formRef}>
                <Form.Item {...formItemLayout} label="Priority" hasFeedback name={"priority"} rules={[{
                    required: true,
                    message: "Please select priority preference"
                }]}>
                    <Select placeholder="Please select priority preference">
                        <Select.Option value="1">High</Select.Option>
                        <Select.Option value="2">Medium</Select.Option>
                        <Select.Option value="3">Low</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item {...formItemLayout} label="Subject" name={"subject"} rules={[{
                    required: true,
                    message: "Please input your username!"
                }]}>
                    <Input placeholder="Subject"/>
                </Form.Item>

                <Form.Item {...formItemLayout} label="Body" name={"body"} rules={[{
                    required: true,
                    message: "Please input your task"
                }]}>
                    <TextArea placeholder="" style={{
                        resize: "none"
                    }}/>
                </Form.Item>

                <Form.Item {...formItemLayout} label="DatePicker" name={"datepicker"} rules={[{
                    required: true
                }]}>
                    <DatePicker showTime/>
                </Form.Item>

                <Form.Item {...formItemLayout} label="Recipients" name={"groupRecipients"} rules={[{
                    required: true,
                    message: "Please choose recipients for the comms"
                }]}>
                    <RecipientsGroupSelect/>
                </Form.Item>

                <Form.Item {...formItemLayout} label="Attach" name={"currentAttachments"}>
                    <AttachmentUploader isBusy={this.showBusy}
                                        onChange={() => {
                                        }}/>
                </Form.Item>

                <Form.Item wrapperCol={{
                    span: 12,
                    offset: 6
                }}>
                    <Button type="primary" htmlType="submit" loading={this.state.submitInAction} style={{
                        display: "block",
                        margin: "auto"
                    }}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    }

}

export class CreateTaskForm extends PureComponent<any, any> {
    render() {
        let WrappedForm = TaskForm
        // @ts-ignore
        return <WrappedForm onFormSubmit={this.props.onFormSubmit}/>
    }

}
