import React, {useCallback, useEffect, useRef, useState} from 'react'
import {FocusAreaData} from '../data/models/CXLibraryModels'
import {EmpowerAPIConfig} from '../data/api/APIConfig'
import {pretty} from '../util/Log'
import {
  Api,
  BasicLibraryFocusArea,
  ChangeTemplateActiveStateRequest,
  CreateFocusAreaRequest,
  CreateLibraryElementRequest,
  CreateLibraryIssuesRequest,
  CreateLibrarySubcategoryRequest,
  CreateTemplateRequest,
  CXTemplateSummary,
  KPIQueryParameters,
  KPIResponse,
  LibraryFocusArea,
  LibrarySubcategory,
  RepeatType,
  TemplateSurvey,
  UpdateLibraryIssuesRequest,
  UpdateTemplateRequest,
  UserRoles,
} from '../data/api/generated/EmpowerSwaggerApi'
import {UserStore} from '../data/store/UserStore'
import {useHistory} from 'react-router-dom'
import {notification} from 'antd'
import {ExclamationCircleTwoTone} from '@ant-design/icons'

export interface KpiDataQuery {
  Year?: string[]
  Month?: string[]
  Region?: string
  Store?: string
}

export type CXAPI = {
  createFocusArea: (focusArea: CreateFocusAreaRequest) => void
  refreshAvailableFocusAreas: () => void
  refreshActiveFocusArea: () => void
  createOrUpdateCategory: (categoryName: string, categoryId?: string) => void
  createOrUpdateSubcategory: (
    categoryId: string,
    subcategoryName: string,
    isRepeat: boolean,
    repeatType?: string,
    subcategoryId?: string
  ) => void
  createOrUpdateElement: (
    subcategoryId: string,
    ElementName: string,
    Issues: string[],
    elementId?: string,
    repeatType?: RepeatType
  ) => void
  createIssue: (elementId: string, Issues: string[]) => void
  updateIssue: (issueId: string, name: string, elementId?: string) => void
  updateFocusArea: (
    focusAreaId: string,
    name: string,
    abbreviation: string
  ) => void
  createTemplate: (request: CreateTemplateRequest) => void
  changeTemplateActiveState: (request: ChangeTemplateActiveStateRequest) => void
  getTemplate: (templateId: string) => void
  updateTemplate: (request: UpdateTemplateRequest) => void
  getKpiData: (query: KPIQueryParameters) => void
  deleteFocusArea: (focusAreaId: string) => void
  deleteCategory: (categoryId: string) => void
  deleteSubcategory: (subcategoryId: string) => void
  deleteElement: (elementId: string) => void
  deleteIssue: (issueId: string) => void
}

export type CXContextType = {
  availableFocusAreas: Array<FocusAreaData>
  setActiveFocusArea: (arg0: string) => void
  setActiveSubcategory: (subcatId: string | null) => void
  api: CXAPI
  authFailed: boolean
  activeFocusArea?: LibraryFocusArea | null
  activeSubcategory?: LibrarySubcategory | null
  availableRoles: Array<UserRoles>
  availableTemplates: Array<CXTemplateSummary>
  loading: boolean
  currentSearchQuery: string
  setCurrentSearchQuery: (query: string) => void
  currentTemplate: TemplateSurvey | null
  kpiData: KPIResponse
  setAuthFailed: (hasFailed: boolean) => void
  setIsLoading: (loading: boolean) => void
}

export const CXContext = React.createContext<CXContextType>({
  api: {
    createFocusArea: (focusArea: CreateFocusAreaRequest) => {},
    refreshAvailableFocusAreas: () => {},
    refreshActiveFocusArea: () => {},
    createOrUpdateCategory: (categoryName, categoryId) => {},
    createOrUpdateSubcategory: (
      categoryId,
      subcategoryName,
      isRepeat,
      repeatType,
      subcategoryId
    ) => {},
    createOrUpdateElement: (
      subcategoryId,
      ElementName,
      Issues,
      elementId,
      repeatType
    ) => {},
    createIssue: (elementId, Issues) => {},
    updateIssue: (issueId, name, elementId) => {},
    updateFocusArea: (focusAreaId, name, abbreviation) => {},
    createTemplate: request => {},
    changeTemplateActiveState: request => {},
    getTemplate: templateId => {},
    updateTemplate: request => {},
    getKpiData: query => {},
    deleteFocusArea: focusAreaId => {},
    deleteCategory: categoryId => {},
    deleteSubcategory: subcategoryId => {},
    deleteElement: elementId => {},
    deleteIssue: issueId => {},
  },
  authFailed: false,
  availableFocusAreas: [],
  availableRoles: [],
  availableTemplates: [],
  currentSearchQuery: '',
  currentTemplate: null,
  loading: false,
  kpiData: {},
  setActiveFocusArea: arg0 => {},
  setActiveSubcategory: subCatId => {},
  setCurrentSearchQuery: query => {},
  setAuthFailed: hasFailed => {},
  setIsLoading: loading => {},
})

export const CXContextProvider: React.FC = props => {
  const history = useHistory()
  const storedCXArea = {
    currentFocusAreaGuid: localStorage.getItem('user_selected_focus_area_id'),
    currentTemplate: JSON.parse(
      localStorage.getItem('user_selected_template')!
    ),
  }

  const [availableFocusAreas, setAvailableFocusAreas] = useState<
    Array<FocusAreaData>
  >([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentFocusArea, setCurrentFocusArea] =
    useState<LibraryFocusArea | null>(null)
  const [currentSubcategory, setCurrentSubcategory] =
    useState<LibrarySubcategory | null>(null)
  const [availableRoles, setAvailableRoles] = useState<Array<UserRoles>>([])
  const [availableTemplates, setAvailableTemplates] = useState<
    Array<CXTemplateSummary>
  >([])
  const [isAuthFailed, setAuthFailed] = useState(false)
  const [currentSearchQuery, setCurrentSearchQuery] = useState('')
  const [currentTemplate, setCurrentTemplate] = useState<TemplateSurvey | null>(
    null
  )
  const [kpiData, setKpiData] = useState<KPIResponse>({})

  let currentFocusAreaGuid = useRef<string | undefined>()

  if (currentFocusArea != null) {
    currentFocusAreaGuid.current = currentFocusArea.id
  }

  const api = new Api({
    baseURL: EmpowerAPIConfig.baseURL,
    headers: {
      Authorization: `${UserStore.getAzureToken()}`,
    },
  })

  const logResponse = (label: string, data: any) => {
    console.log(`Response: ${label}\n${pretty(data)}`)
  }

  const setErrorNotification = (message: string) => {
    notification.open({
      message: 'Error',
      type: 'error',
      icon: (
        <div style={{margin: '5px -10px'}}>
          <ExclamationCircleTwoTone
            twoToneColor={'rgb(223, 34, 36)'}
            style={{fontSize: 40}}
          />
        </div>
      ),
      description: message,
      duration: 10,
    })
  }

  const authCheck = (message: string, reason?: string) => {
    const authFailed =
      message.indexOf('Request failed with status code 401') > -1
    if (authFailed) {
      return setAuthFailed(authFailed)
    }
    if (message.indexOf('Request failed with status code 500') > -1 && !reason)
      return
    setErrorNotification(reason ?? message)
  }

  const refreshAvailableTemplates = () => {
    setIsLoading(true)

    api.api
      .v1CustomerexperienceCxTemplateFetchList()
      .then(data => {
        setAvailableTemplates(data.data)
        setIsLoading(false)
      })
      .catch(e => authCheck(e.message))
  }

  const refreshActiveFocusArea = useCallback(() => {
    if (currentFocusAreaGuid.current != null) {
      setIsLoading(true)
      if (history.location.pathname.includes('cxtemplate/')) {
        const currentTemplateFocusArea = currentTemplate?.focusAreas?.filter(
          item => item.focusAreaId === currentFocusAreaGuid.current
        )
        if (currentTemplateFocusArea) {
          const structuredArray: LibraryFocusArea = {
            id: currentTemplateFocusArea[0].focusAreaId!,
            name: currentTemplateFocusArea[0].name,
            abbreviation: currentTemplateFocusArea[0].abbreviation,
            categories: currentTemplateFocusArea[0].categories,
          }
          setCurrentFocusArea(structuredArray)
          setIsLoading(false)
        }
      } else if (!isLoading) {
        api.api
          .v1CustomerexperienceCxLibraryGetFocusAreaDetail(
            currentFocusAreaGuid.current
          )
          .then(data => {
            setCurrentFocusArea(data.data)
            if (currentSubcategory) {
              if ('id' in currentSubcategory) {
                setActiveSubcategory(currentSubcategory.id!!, data.data)
              }
            }
          })
          .catch(async e => {
            authCheck(e.message)
            setCurrentFocusArea(availableFocusAreas[0])
            setActiveFocusAreaID(availableFocusAreas[0].id)
          })
          .finally(() => setIsLoading(false))
      }
    }
  }, [currentFocusAreaGuid, currentTemplate, api.api, isLoading])

  const setActiveFocusAreaID = useCallback(
    (id: string) => {
      currentFocusAreaGuid.current = id
      localStorage.setItem('user_selected_focus_area_id', id)
      refreshActiveFocusArea()
    },
    [currentFocusAreaGuid, refreshActiveFocusArea]
  )

  const refreshAvailableFocusAreas = useCallback(
    (focusAreas?: BasicLibraryFocusArea[]) => {
      setIsLoading(true)

      const setFocusAreaHandler = (data: BasicLibraryFocusArea[]) => {
        // @ts-ignore
        setAvailableFocusAreas(data)
        if (data && data.length > 0) {
          if (storedCXArea.currentFocusAreaGuid) {
            const focusArea = data.filter(
              fa => fa.id === storedCXArea.currentFocusAreaGuid
            )
            if (focusArea.length > 0) {
              setActiveFocusAreaID(focusArea[0].id!)
            } else {
              setActiveFocusAreaID(data[0].id!)
            }
          } else {
            setActiveFocusAreaID(data[0].id!)
          }
        }
        setIsLoading(false)
      }

      if (focusAreas) {
        return setFocusAreaHandler(focusAreas)
      }

      const dataPromise =
        api.api.v1CustomerexperienceCxLibraryGetFocusAreasList()

      dataPromise
        .then((data: {data: BasicLibraryFocusArea[]}) =>
          setFocusAreaHandler(data.data)
        )
        .catch((e: {message: string}) => {
          authCheck(e.message)
          setIsLoading(false)
        })
    },
    [
      setActiveFocusAreaID,
      storedCXArea.currentFocusAreaGuid,
      history.location.pathname,
    ]
  )

  const setActiveSubcategory = useCallback(
    (subcatId: string | null, focusArea?: LibraryFocusArea) => {
      let subcats = currentFocusArea?.categories?.map(
        cat => cat.subcategories!!
      )!!

      if (focusArea) {
        subcats = focusArea.categories?.map(cat => cat.subcategories!!)!!
      }

      const flatArray = ([] as LibrarySubcategory[]).concat(...subcats)

      const activeSubcat = flatArray.find(value => {
        return value.id === subcatId
      })

      setCurrentSubcategory(activeSubcat ? activeSubcat : null)
    },
    [currentFocusArea]
  )

  const createFocusArea = (focusArea: CreateFocusAreaRequest) => {
    setIsLoading(true)
    api.api
      .v1CustomerexperienceCxLibraryCreateLibraryFocusAreaCreate(focusArea)
      .then(data => {
        refreshAvailableFocusAreas()
        setIsLoading(false)
      })
      .catch(e => {
        authCheck(e.message)
        setIsLoading(false)
      })
  }

  const createOrUpdateCategory = (
    categoryName: string,
    categoryId?: string
  ) => {
    setIsLoading(true)
    api.api
      .v1CustomerexperienceCxLibraryCreateLibraryCategoryCreate({
        categoryId,
        focusAreaId: currentFocusArea?.id,
        name: categoryName,
      })
      .then(data => {
        setCurrentFocusArea(data.data)
        refreshAvailableFocusAreas()
      })
      .catch(e => {
        authCheck(e.message)
        setIsLoading(false)
      })
  }

  const createOrUpdateSubcategory = (
    categoryId: string,
    name: string,
    isRepeat: boolean,
    repeatType?: string,
    subcategoryId?: string
  ) => {
    setIsLoading(true)
    api.api
      .v1CustomerexperienceCxLibraryCreateLibrarySubcategoryCreate({
        categoryId,
        name,
        isRepeat,
        repeatType,
        subcategoryId,
      } as CreateLibrarySubcategoryRequest)
      .then(data => {
        const category = data.data.categories!.filter(
          category => category.id === categoryId
        )
        const newSubcategory = category[0].subcategories!.filter(
          subCategory => subCategory.name === name
        )
        setCurrentFocusArea(data.data)
        setActiveSubcategory(newSubcategory[0].id!)
        refreshAvailableFocusAreas()
      })
      .catch(e => {
        authCheck(e.message)
        setIsLoading(false)
      })
  }

  const createOrUpdateElement = (
    subcategoryId: string,
    name: string,
    issues: string[],
    elementId?: string,
    repeatType?: RepeatType
  ) => {
    setIsLoading(true)

    const request: CreateLibraryElementRequest = {
      subcategoryId,
      name,
      issues,
      elementId,
      repeatType,
    }

    api.api
      .v1CustomerexperienceCxLibraryCreateLibraryElementCreate(request)
      .then(data => {
        if (data) {
          logResponse('Create Element', data)
          setCurrentFocusArea(data.data)
        }
        refreshAvailableFocusAreas()
      })
      .catch(e => {
        authCheck(e.message)
        setIsLoading(false)
      })
  }

  const createIssue = (elementId: string, Issue: string[]) => {
    setIsLoading(true)
    const request: CreateLibraryIssuesRequest = {
      elementId: elementId,
      issues: Issue,
    }

    api.api
      .v1CustomerexperienceCxLibraryAddLibraryIssuesCreate(request)
      .then(data => {
        logResponse('Create Issue', data)
        setCurrentFocusArea(data.data)
        refreshAvailableFocusAreas()
        setIsLoading(false)
      })
      .catch(e => {
        authCheck(e.message)
        setIsLoading(false)
      })
  }

  const updateIssue = (issueId: string, name: string, elementId?: string) => {
    setIsLoading(true)

    const request: UpdateLibraryIssuesRequest = {
      issueId,
      name,
      elementId,
    }

    api.api
      .v1CustomerexperienceCxLibraryUpdateLibraryIssueCreate(request)
      .then(data => {
        setCurrentFocusArea(data.data)
        refreshAvailableFocusAreas()
      })
      .catch(e => {
        authCheck(e.message)
        setIsLoading(false)
      })
  }

  const updateFocusArea = (
    focusAreaId: string,
    name: string,
    abbreviation: string
  ) => {
    setIsLoading(true)

    const request = {
      focusAreaGuid: focusAreaId,
      focusName: name,
      focusAbbrev: abbreviation,
    }

    logResponse('req', request)

    api.api
      .v1CustomerexperienceCxLibraryUpdateLibraryFocusAreaCreate(request)
      .then(data => {
        setCurrentFocusArea(data.data)
        refreshAvailableFocusAreas()
      })
      .catch(e => {
        authCheck(e.message)
        setIsLoading(false)
      })
  }

  const createTemplate = (request: CreateTemplateRequest) => {
    setIsLoading(true)

    logResponse('templates req', request)

    api.api
      .v1CustomerexperienceCxTemplateCreateCreate(request)
      .then(() => {
        setIsLoading(false);
        history.push('/cxtemplates');
       // window.location.href = '/cxtemplates'
      })
      .catch(e => {
        authCheck(e.message)
        setIsLoading(false)
      })
  }

  const changeTemplateActiveState = (
    request: ChangeTemplateActiveStateRequest
  ) => {
    setIsLoading(true)

    logResponse('template activate req', request)

    api.api
      .v1CustomerexperienceCxTemplateActivateCreate(request)
      .then(() => {
        setIsLoading(false)
        refreshAvailableTemplates()
      })
      .catch(e => {
        authCheck(e.message)
        setIsLoading(false)
      })
  }

  const getTemplate = (templateId: string) => {
    setIsLoading(true)

    logResponse('single template req', templateId)

    api.api
      .v1CustomerexperienceCxTemplateList({templateId})
      .then(data => {
        setCurrentTemplate(data.data)
      })
      .catch(e => {
        authCheck(e.message)
        setIsLoading(false)
      })
  }

  const updateTemplate = (request: UpdateTemplateRequest) => {
    setIsLoading(true)

    logResponse('templates req', request)

    api.api
      .v1CustomerexperienceCxTemplateUpdateCreate(request)
      .then(() => {
        setIsLoading(false)
        getTemplate(request.templateId!)
        refreshAvailableTemplates()
      })
      .catch(e => {
        authCheck(e.message)
        setIsLoading(false)
      })
  }

  const deleteFocusArea = (focusAreaId: string) => {
    setIsLoading(true)

    api.api
      .v1CustomerexperienceCxLibraryDeleteLibraryFocusAreaCreate(focusAreaId)
      .then(() => {
        setCurrentFocusArea(null)
        refreshAvailableFocusAreas()
      })
      .catch(e => {
        authCheck(e.message, 'Unable to delete focus area.')
        setIsLoading(false)
      })
      .catch(e => {
        authCheck(e.message)
        setIsLoading(false)
      })
  }

  const deleteCategory = (categoryId: string) => {
    setIsLoading(true)

    api.api
      .v1CustomerexperienceCxLibraryDeleteLibraryCategoryCreate(categoryId)
      .then(() => {
        refreshAvailableFocusAreas()
        setActiveSubcategory(null)
      })
      .catch(e => {
        authCheck(e.message, 'Unable to delete category.')
        setIsLoading(false)
      })
  }

  const deleteSubcategory = (subcategoryId: string) => {
    setIsLoading(true)

    api.api
      .v1CustomerexperienceCxLibraryDeleteLibrarySubcategoryCreate(
        subcategoryId
      )
      .then(() => {
        refreshAvailableFocusAreas()
        setActiveSubcategory(null)
      })
      .catch(e => {
        authCheck(e.message, 'Unable to delete subcategory.')
        setIsLoading(false)
      })
  }

  const deleteElement = (elementId: string) => {
    setIsLoading(true)

    api.api
      .v1CustomerexperienceCxLibraryDeleteLibraryElementCreate(elementId)
      .then(() => {
        refreshAvailableFocusAreas()
      })
      .catch(e => {
        authCheck(e.message, 'Unable to delete element.')
        setIsLoading(false)
      })
  }

  const deleteIssue = (issueId: string) => {
    setIsLoading(true)

    api.api
      .v1CustomerexperienceCxLibraryDeleteLibraryIssueCreate(issueId)
      .then(() => {
        refreshAvailableFocusAreas()
      })
      .catch(e => {
        authCheck(e.message, 'Unable to delete issue.')
        setIsLoading(false)
      })
  }

  const getKpiData = (query: KPIQueryParameters) => {
    setIsLoading(true)

    api.api
      .v1CustomerexperienceCxAssessmentKpiCreate(query)
      .then(data => {
        setIsLoading(false)
        setKpiData(data.data)
      })
      .catch(e => {
        authCheck(e.message)
      })
  }

  useEffect(() => {
    if (currentSubcategory) {
      if ('id' in currentSubcategory) {
        setActiveSubcategory(currentSubcategory.id!!)
      }
    }
    // eslint-disable-next-line
  }, [currentSubcategory])

  useEffect(() => {
    api.api
      .v1UsersRolesList()
      .then(data => setAvailableRoles(data.data.roles!))
      .catch(e => {
        authCheck(e.message)
        setIsLoading(false)
      })

    refreshAvailableTemplates()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!currentTemplate) return
    localStorage.setItem(
      'user_selected_template',
      JSON.stringify(currentTemplate)
    )
    refreshAvailableFocusAreas(
      currentTemplate.focusAreas!.map(item => {
        const area: BasicLibraryFocusArea = {
          id: item.focusAreaId!,
          name: item.name,
          abbreviation: item.abbreviation,
          categories: item.categories,
        }
        return area
      })
    )
  }, [currentTemplate])

  useEffect(() => {
    history.listen(location => {
      return () => {
        if (!location.pathname.includes('cxtemplate/')) {
          localStorage.removeItem('user_selected_template')
          setCurrentTemplate(null)
        }
      }
    })
  }, [history])

  const cxAPI: CXAPI = {
    refreshAvailableFocusAreas: refreshAvailableFocusAreas,
    refreshActiveFocusArea: refreshActiveFocusArea,
    createFocusArea,
    createOrUpdateCategory,
    createOrUpdateSubcategory,
    createOrUpdateElement,
    createIssue,
    updateIssue,
    updateFocusArea,
    createTemplate,
    changeTemplateActiveState,
    getTemplate,
    updateTemplate,
    deleteFocusArea,
    deleteCategory,
    deleteSubcategory,
    deleteElement,
    deleteIssue,
    getKpiData,
  }

  const contextValue: CXContextType = {
    availableFocusAreas,
    setActiveFocusArea: setActiveFocusAreaID,
    setActiveSubcategory,
    api: cxAPI,
    authFailed: isAuthFailed,
    activeFocusArea: currentFocusArea,
    activeSubcategory: currentSubcategory,
    loading: isLoading,
    availableRoles,
    availableTemplates,
    currentSearchQuery,
    setCurrentSearchQuery,
    currentTemplate,
    kpiData,
    setAuthFailed,
    setIsLoading,
  }
  return (
    <CXContext.Provider value={contextValue}>
      {props.children}
    </CXContext.Provider>
  )
}
