/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Announcement {
  /** @format uuid */
  announcementId?: string

  /** @format uuid */
  messageId?: string

  /** @format int32 */
  announcementStatusId?: number

  /** @format date-time */
  datePublished?: string | null
  announcementStatus?: MessageStatus
  message?: Message
}

export interface AnnouncementDetailViewModel {
  /** @format uuid */
  announcementId: string
  announcementDisplayId: string

  /** @format int32 */
  announcementStatusId: number
  subject: string
  body?: string | null
  from?: string | null
  announcementStatusDescription?: string | null
  attachments?: FileAttachment[] | null

  /** @format date-time */
  datePublished?: string | null

  /** @format date-time */
  dateCreated?: string
}

/**
 * @format int32
 */
export type AnnouncementFilterValue = 0 | 1 | 2

export interface AnnouncementSummaryResponse {
  /** @format int32 */
  itemCount?: number
  announcements?: AnnouncementSummaryViewModel[] | null
}

export interface AnnouncementSummaryViewModel {
  /** @format uuid */
  announcementId: string
  announcementDisplayId: string
  subject: string
  from?: string | null
  attachments?: FileAttachment[] | null
  isRead?: boolean
  isPinned?: boolean

  /** @format date-time */
  datePublished?: string | null
  announcementStatusDescription?: string | null

  /** @format uuid */
  messageId?: string

  /** @format date-time */
  dateCreated?: string
}

export interface AnswerElement {
  /** @format uuid */
  elementId?: string
  answered?: boolean

  /** @format int32 */
  repeatIndex?: number
  answer?: string | null

  /** @format uuid */
  answerId?: string
  isElementComplete?: boolean | null
  issueAnswers?: AnswerIssue[] | null
}

export interface AnswerElementRequest {
  /** @format uuid */
  assessmentId?: string

  /** @format uuid */
  elementId?: string

  /** @format int32 */
  index?: number | null
  answer?: string | null
  decision?: boolean | null
}

export interface AnswerIssue {
  /** @format uuid */
  issueAnswerId?: string
  evidenceComment?: string | null

  /** @format uuid */
  evidenceVideo?: string | null

  /** @format uuid */
  evidenceImage?: string | null
  isSelected?: boolean | null
  assessmentIssue?: AssessmentIssue
}

export interface Area {
  /** @format uuid */
  areaId?: string
  description?: string | null
  foreignId?: string | null
  groupAttributes?: GroupAttribute[] | null
  userAttributes?: UserAttribute[] | null
}

export interface Assessment {
  /** @format uuid */
  assessmentId?: string

  /** @format uuid */
  storeId?: string
  name?: string | null
  username?: string | null

  /** @format date-time */
  dateCreated?: string | null
  survey?: AssessmentSurvey
  isComplete?: boolean
}

export interface AssessmentElement {
  /** @format uuid */
  elementId?: string
  name?: string | null
  isElementComplete?: boolean
  issues?: AssessmentIssue[] | null
  answers?: AnswerElement[] | null
}

export interface AssessmentIssue {
  /** @format uuid */
  guid?: string
  name?: string | null
  evidenceCommentRequired?: boolean
  evidenceVideoRequired?: boolean
  evidenceImageRequired?: boolean
  isRepeat?: boolean

  /** @format int32 */
  repeatCount?: number
  comment?: string | null
  image?: string | null
  video?: string | null
  isSelected?: boolean | null
}

export interface AssessmentIssueAssessmentElementAssessmentIssueAssessmentElementCategory {
  /** @format uuid */
  id?: string
  name?: string | null
  subcategories?: AssessmentIssueAssessmentElementAssessmentIssueAssessmentElementSubcategory[] | null
}

export interface AssessmentIssueAssessmentElementAssessmentIssueAssessmentElementFocusArea {
  focusAreaId?: string | null
  name?: string | null
  abbreviation?: string | null

  /** @format int32 */
  decisionType?: number
  categories?: AssessmentIssueAssessmentElementAssessmentIssueAssessmentElementCategory[] | null
  mustComplete?: boolean
}

export interface AssessmentIssueAssessmentElementAssessmentIssueAssessmentElementSubcategory {
  /** @format uuid */
  id?: string
  name?: string | null
  isRepeat?: boolean
  elements?: AssessmentElement[] | null
}

export interface AssessmentSummary {
  /** @format uuid */
  assessmentId?: string

  /** @format uuid */
  storeId?: string
  name?: string | null
  username?: string | null

  /** @format date-time */
  dateCreated?: string | null
  isComplete?: boolean

  /** @format uuid */
  templateId?: string
}

export interface AssessmentSurvey {
  focusAreas?: AssessmentIssueAssessmentElementAssessmentIssueAssessmentElementFocusArea[] | null
  name?: string | null

  /** @format uuid */
  templateId?: string
  roles?: string[] | null
  username?: string | null

  /** @format date-time */
  dateCreated?: string | null
}

export interface AssignUserCategoriesRequest {
  userCategoryAssignments?: UserCategoryAssignmentViewModel[] | null
}

export interface Attachment {
  /** @format uuid */
  attachmentId?: string

  /** @format uuid */
  messageId?: string | null
  fileName?: string | null
  description?: string | null

  /** @format int64 */
  fileSize?: number
  mimeType?: string | null

  /** @format date-time */
  createdDate?: string

  /** @format uuid */
  userId?: string
  message?: Message
  user?: User
  evidenceImages?: EvidenceImage[] | null
  evidenceVideos?: EvidenceVideo[] | null
}

export interface AuthenticationLoginRequest {
  token?: string | null
  udid?: string | null
  cardNumber?: string | null
  userCode?: string | null
  password?: string | null
}

export interface AzureLoginResponse {
  divisionId?: string | null
  currentTeamName?: string | null
  isoCountryCode?: string | null
  userName?: string | null

  /** @format int32 */
  userTypeId?: number
  userRoleTitles?: string[] | null
  associatedStore?: string | null
  isManager?: boolean
  accessToken?: string | null
  inTouchAccessToken?: string | null
}

export interface BasicLibraryFocusArea {
  /** @format uuid */
  id?: string
  name?: string | null
  abbreviation?: string | null
  categories?: LibraryCategory[] | null
}

export interface CXTemplateSummary {
  name?: string | null

  /** @format uuid */
  templateId?: string
  roles?: string[] | null
  username?: string | null

  /** @format date-time */
  dateCreated?: string
  isActive?: boolean
}

export interface ChangeTemplateActiveStateRequest {
  /** @format uuid */
  templateId?: string
  activeState?: boolean
}

export interface CommentOnIssueRequest {
  /** @format uuid */
  assessmentId?: string

  /** @format uuid */
  elementAnswerId?: string

  /** @format uuid */
  issueId?: string
  comment?: string | null
}

export interface CommonIssue {
  name?: string | null

  /** @format int32 */
  count?: number
}

export interface CompletedTask {
  /** @format uuid */
  messageId?: string

  /** @format uuid */
  taskId?: string

  /** @format date-time */
  completedDate?: string

  /** @format uuid */
  recipientId?: string

  /** @format int32 */
  recipientTypeId?: number

  /** @format uuid */
  completedByUserId?: string
  completedByUser?: User
  messageRecipient?: MessageRecipient
  recipientType?: RecipientType
  task?: Task
}

export interface Country {
  /** @format uuid */
  countryId?: string
  description?: string | null
  foreignId?: string | null
  groupAttributes?: GroupAttribute[] | null
  userAttributes?: UserAttribute[] | null
}

export interface CreateAnnouncementRequest {
  /** @format int32 */
  announcementStatusId?: number
  subject: string
  body: string
  recipients: Recipient[]
  attachmentIds?: string[] | null
}

export interface CreateAnnouncementResponse {
  /** @format uuid */
  announcementId?: string
}

export interface CreateAssessmentRequest {
  /** @format uuid */
  templateId?: string

  /** @format uuid */
  storeId?: string
}

export interface CreateFocusAreaRequest {
  focusName?: string | null
  focusAbbrev?: string | null
  decisionType?: string | null
  allComplete?: boolean
  discussionWord?: string | null
  positiveWord?: string | null
  negativeWord?: string | null
}

export interface CreateLibraryCategoryRequest {
  /** @format uuid */
  focusAreaId?: string

  /** @format uuid */
  categoryId?: string | null
  name?: string | null
}

export interface CreateLibraryElementRequest {
  /** @format uuid */
  subcategoryId?: string

  /** @format uuid */
  elementId?: string | null
  name?: string | null
  issues?: string[] | null
  repeatType?: RepeatType
}

export interface CreateLibraryIssuesRequest {
  /** @format uuid */
  elementId?: string
  issues?: string[] | null
}

export interface CreateLibrarySubcategoryRequest {
  /** @format uuid */
  categoryId?: string

  /** @format uuid */
  subcategoryId?: string | null
  name?: string | null
  isRepeat?: boolean
  repeatType?: string | null
}

export interface CreateTaskReplyRequest {
  body?: string | null
  subject?: string | null
  attachmentIds?: string[] | null
}

export interface CreateTaskRequest {
  /** @format int32 */
  taskPriorityId: number

  /** @format int32 */
  taskStatusId: number
  subject: string
  body?: string | null

  /** @format date-time */
  dueDate?: string
  recipients: Recipient[]
  attachmentIds?: string[] | null
  isCxApp?: boolean | null
}

export interface CreateTemplateIssue {
  /** @format uuid */
  issueId?: string
  requiresImage?: boolean
  requiresVideo?: boolean
  requiresComment?: boolean
}

export interface CreateTemplateRequest {
  issues?: CreateTemplateIssue[] | null
  name?: string | null
  roleIds?: string[] | null
}

export interface CreateTicketReplyRequest {
  body?: string | null
  subject?: string | null
  attachmentIds?: string[] | null
}

export interface CreateTicketReplyResponse {
  /** @format uuid */
  ticketReplyId?: string
}

export interface CreateTicketRequest {
  /** @format int32 */
  ticketPriorityId?: number

  /** @format int32 */
  ticketStatusId?: number

  /** @format uuid */
  ticketCategoryId?: string
  subject: string
  body: string
  attachmentIds?: string[] | null
}

export interface CreateTicketResponse {
  /** @format uuid */
  ticketId?: string
}

export interface CxAssessment {
  /** @format uuid */
  assessmentId?: string

  /** @format uuid */
  userId?: string

  /** @format uuid */
  storeConfig?: string

  /** @format uuid */
  templateId?: string

  /** @format date-time */
  dateCreated?: string
  isComplete?: boolean

  /** @format date-time */
  dateUpdated?: string
  storeConfigNavigation?: CxStoreConfig
  template?: CxTemplate
  user?: User
  cxElementAnswers?: CxElementAnswer[] | null
}

export interface CxElementAnswer {
  /** @format uuid */
  answerId?: string

  /** @format uuid */
  user?: string

  /** @format uuid */
  store?: string

  /** @format int32 */
  repeatIndex?: number
  decision?: boolean | null
  decisionString?: string | null
  isCorrect?: boolean

  /** @format uuid */
  assessmentId?: string

  /** @format uuid */
  templateId?: string

  /** @format uuid */
  elementId?: string

  /** @format date-time */
  dateUpdated?: string
  assessment?: CxAssessment
  element?: CxLibraryElement
  storeNavigation?: CxStoreConfig
  template?: CxTemplate
  userNavigation?: User
  cxIssueAnswers?: CxIssueAnswer[] | null
}

export interface CxIssueAnswer {
  /** @format uuid */
  issueAnswerId?: string

  /** @format uuid */
  elementAnswer?: string
  evidenceComment?: string | null

  /** @format uuid */
  evidenceVideo?: string | null

  /** @format uuid */
  evidenceImage?: string | null

  /** @format uuid */
  elementAnswerId?: string

  /** @format uuid */
  issueId?: string
  isSelected?: boolean | null

  /** @format date-time */
  dateUpdated?: string
  elementAnswerNavigation?: CxElementAnswer
  evidenceImageNavigation?: EvidenceImage
  evidenceVideoNavigation?: EvidenceVideo
  issue?: CxTemplateIssue
}

export interface CxLibraryCategory {
  /** @format uuid */
  categoryId?: string

  /** @format uuid */
  libraryFocusArea?: string
  name?: string | null
  libraryFocusAreaNavigation?: CxLibraryFocusArea
  cxLibrarySubcategories?: CxLibrarySubcategory[] | null
}

export interface CxLibraryElement {
  /** @format uuid */
  elementId?: string
  name?: string | null

  /** @format uuid */
  librarySubcategory?: string
  librarySubcategoryNavigation?: CxLibrarySubcategory
  cxElementAnswers?: CxElementAnswer[] | null
  cxLibraryIssues?: CxLibraryIssue[] | null
}

export interface CxLibraryFocusArea {
  /** @format uuid */
  focusAreaId?: string
  name?: string | null
  abbreviation?: string | null

  /** @format int32 */
  decisionType?: number
  actionWord?: string | null
  positiveWord?: string | null
  negativeWord?: string | null
  mustCompleteAll?: boolean

  /** @format uuid */
  divisionId?: string | null
  cxLibraryCategories?: CxLibraryCategory[] | null
}

export interface CxLibraryIssue {
  /** @format uuid */
  issueId?: string
  name?: string | null

  /** @format uuid */
  libraryElement?: string
  libraryElementNavigation?: CxLibraryElement
  cxTemplateIssues?: CxTemplateIssue[] | null
}

export interface CxLibrarySubcategory {
  /** @format uuid */
  subcategoryId?: string

  /** @format uuid */
  libraryCategory?: string
  name?: string | null
  isRepeat?: boolean

  /** @format int32 */
  countSource?: number | null
  repeatType?: string | null
  libraryCategoryNavigation?: CxLibraryCategory
  cxLibraryElements?: CxLibraryElement[] | null
}

export interface CxStoreConfig {
  /** @format uuid */
  storeId?: string

  /** @format int32 */
  windowCount?: number

  /** @format int32 */
  entranceCount?: number

  /** @format int32 */
  driveAisle?: number
  cxAssessments?: CxAssessment[] | null
  cxElementAnswers?: CxElementAnswer[] | null
  cxWindowPriorities?: CxWindowPriority[] | null
}

export interface CxTemplate {
  /** @format uuid */
  templateId?: string
  name?: string | null
  isActive?: boolean

  /** @format uuid */
  userId?: string

  /** @format date-time */
  dateCreated?: string

  /** @format uuid */
  divisionId?: string | null
  user?: User
  cxAssessments?: CxAssessment[] | null
  cxElementAnswers?: CxElementAnswer[] | null
  cxTemplateIssues?: CxTemplateIssue[] | null
  cxTemplateRoleJoins?: CxTemplateRoleJoin[] | null
}

export interface CxTemplateIssue {
  /** @format uuid */
  issueId?: string

  /** @format uuid */
  libraryIssue?: string

  /** @format uuid */
  template?: string
  evidenceCommentRequired?: boolean | null
  evidenceVideoRequired?: boolean | null
  evidenceImageRequired?: boolean | null
  isRepeat?: boolean | null

  /** @format int32 */
  repeatCount?: number | null
  libraryIssueNavigation?: CxLibraryIssue
  templateNavigation?: CxTemplate
  cxIssueAnswers?: CxIssueAnswer[] | null
}

export interface CxTemplateRoleJoin {
  /** @format uuid */
  roleId?: string

  /** @format uuid */
  templateId?: string
  role?: Role
  template?: CxTemplate
}

export interface CxWindowPriority {
  /** @format uuid */
  windowPriorityId?: string

  /** @format uuid */
  store?: string

  /** @format int32 */
  windowIndex?: number

  /** @format int32 */
  entranceNumber?: number

  /** @format uuid */
  windowPosition?: string
  storeNavigation?: CxStoreConfig
  windowPositionNavigation?: WindowPosition
}

/**
 * @format int32
 */
export type DecisionType = 0 | 1

export interface Division {
  /** @format uuid */
  divisionId?: string
  description?: string | null
  foreignId?: string | null
  groupAttributes?: GroupAttribute[] | null
  messageCategories?: MessageCategory[] | null
  userAttributes?: UserAttribute[] | null
}

export interface DivisionsResponse {
  divisions?: DivisionsViewModel[] | null
}

export interface DivisionsViewModel {
  /** @format uuid */
  divisionId?: string
  description?: string | null
  foreignId?: string | null
}

export interface Element {
  /** @format uuid */
  elementId?: string
  name?: string | null
  isElementComplete?: boolean
  issues?: Issue[] | null
}

export interface EvidenceImage {
  /** @format uuid */
  typeId?: string
  url?: string | null

  /** @format uuid */
  attachmentId?: string | null
  thumbnail?: string | null
  attachment?: Attachment
  cxIssueAnswers?: CxIssueAnswer[] | null
}

export interface EvidenceVideo {
  /** @format uuid */
  typeId?: string
  url?: string | null

  /** @format uuid */
  attachmentId?: string | null
  thumbnail?: string | null
  attachment?: Attachment
  cxIssueAnswers?: CxIssueAnswer[] | null
}

export type File = object

export interface FileAttachment {
  /** @format uuid */
  attachmentId?: string
  fileType?: string | null
  description?: string | null
}

export interface FileUploadResponse {
  /** @format uuid */
  attachmentId?: string
}

export interface FilterOption {
  /** @format uuid */
  id?: string
  name?: string | null
}

export interface FilterOptions {
  year?: string[] | null
  month?: string[] | null
  region?: FilterOption[] | null
  store?: FilterOption[] | null
}

export interface GetAreasResponse {
  areas?: RecipientAttribute[] | null
}

export interface GetCountriesResponse {
  countries?: RecipientAttribute[] | null
}

export interface GetDivisionsResponse {
  divisions?: RecipientAttribute[] | null
}

export interface GetGroupRecipientsResponse {
  /** @format int32 */
  totalItemCount?: number
  recipients?: RecipientViewModel[] | null
}

export interface GetNewsFeedItemsResponse {
  /** @format int32 */
  totalItemCount?: number
  items?: NewsFeedItemViewModel[] | null
}

export interface GetRecipientTasksResponse {
  /** @format int32 */
  totalItemCount?: number
  tasks?: TaskViewModel[] | null
}

export interface GetRegionsResponse {
  regions?: RecipientAttribute[] | null
}

export interface GetSentAnnouncementsResponse {
  /** @format int32 */
  totalItemCount?: number
  sentAnnouncements?: SentAnnouncementDetailViewModel[] | null
}

export interface GetSentTasksResponse {
  /** @format int32 */
  totalItemCount?: number
  sentTasks?: SentTaskDetailViewModel[] | null
}

export interface GetTaskDetailsResponse {
  /** @format uuid */
  taskId?: string
  taskDisplayId?: string | null

  /** @format int32 */
  taskPriorityId: number

  /** @format int32 */
  taskStatusId: number
  subject: string
  body?: string | null

  /** @format date-time */
  dueDate?: string
  from?: string | null
  attachments?: FileAttachment[] | null
  recipients?: TaskRecipientViewModel[] | null

  /** @format date-time */
  dateCreated?: string
  replies?: TaskRepliesViewModel[] | null
  isCxApp?: boolean | null

  /** @format date-time */
  lastUpdatedDate?: string | null
  canMarkAsComplete?: boolean
  storeName?: string | null
}

export interface GetTaskPrioritiesResponse {
  priorities?: TaskPriorityViewModel[] | null
}

export interface GetTaskStatusesResponse {
  statuses?: TaskStatusViewModel[] | null
}

export interface GetTeamsResponse {
  teams?: RecipientAttribute[] | null
}

export interface GetTicketCategoriesResponse {
  ticketCategories?: TicketCategoryViewModel[] | null
}

export interface GetTicketsResponse {
  /** @format int32 */
  itemCount?: number
  tickets?: TicketSummaryViewModel[] | null
}

export interface GetUnreadCountResponse {
  /** @format int32 */
  unreadCount?: number
}

export interface GetUserRecipientsResponse {
  /** @format int32 */
  totalItemCount?: number
  recipients?: RecipientViewModel[] | null
}

export interface GetUserRolesResponse {
  roles?: UserRoles[] | null
}

export interface GetUserTypesResponse {
  userTypes?: UserTypeViewModel[] | null
}

export interface Group {
  /** @format uuid */
  groupId?: string
  description?: string | null
  foreignId?: string | null
  groupAttributes?: GroupAttribute[] | null
  userGroups?: UserGroup[] | null
}

export interface GroupAttribute {
  /** @format uuid */
  groupAttributeId?: string

  /** @format uuid */
  groupId?: string

  /** @format uuid */
  divisionId?: string | null

  /** @format uuid */
  countryId?: string | null

  /** @format uuid */
  regionId?: string | null

  /** @format uuid */
  areaId?: string | null

  /** @format uuid */
  teamId?: string | null
  area?: Area
  country?: Country
  division?: Division
  group?: Group
  region?: Region
  team?: Team
}

export interface IsIssueSelectedRequest {
  /** @format uuid */
  assessmentId?: string

  /** @format uuid */
  elementAnswerId?: string

  /** @format uuid */
  issueId?: string
  isSelected?: boolean | null
}

export interface Issue {
  /** @format uuid */
  guid?: string
  name?: string | null
  evidenceCommentRequired?: boolean
  evidenceVideoRequired?: boolean
  evidenceImageRequired?: boolean
  isRepeat?: boolean

  /** @format int32 */
  repeatCount?: number
}

export interface IssueElementIssueElementCategory {
  /** @format uuid */
  id?: string
  name?: string | null
  subcategories?: IssueElementIssueElementSubcategory[] | null
}

export interface IssueElementIssueElementFocusArea {
  focusAreaId?: string | null
  name?: string | null
  abbreviation?: string | null

  /** @format int32 */
  decisionType?: number
  categories?: IssueElementIssueElementCategory[] | null
  mustComplete?: boolean
}

export interface IssueElementIssueElementSubcategory {
  /** @format uuid */
  id?: string
  name?: string | null
  isRepeat?: boolean
  elements?: Element[] | null
}

export interface KPIAssessmentsData {
  /** @format int32 */
  completed?: number

  /** @format int32 */
  total?: number

  /** @format double */
  percentage?: number
}

export interface KPIElementAnswersData {
  /** @format int32 */
  positive?: number

  /** @format int32 */
  negative?: number

  /** @format double */
  percentage?: number
}

export interface KPIQueryParameters {
  year?: string[] | null
  month?: string[] | null

  /** @format uuid */
  region?: string | null

  /** @format uuid */
  store?: string | null
}

export interface KPIResponse {
  kpiAssessments?: KPIAssessmentsData
  kpiElementAnswers?: KPIElementAnswersData
  commonIssues?: CommonIssue[] | null
  kpiQueryParameters?: KPIQueryParameters
  filterOptionValues?: FilterOptions
}

export interface LibraryCategory {
  /** @format uuid */
  id?: string
  name?: string | null
  subcategories?: LibrarySubcategory[] | null
}

export interface LibraryElement {
  /** @format uuid */
  id?: string
  name?: string | null
  issues?: LibraryIssue[] | null
}

export interface LibraryFocusArea {
  /** @format uuid */
  id?: string
  name?: string | null
  abbreviation?: string | null
  decisionType?: DecisionType
  categories?: LibraryCategory[] | null
  decisionActionWord?: string | null
  decisionPositiveWord?: string | null
  decisionNegativeWord?: string | null
}

export interface LibraryIssue {
  /** @format uuid */
  id?: string
  name?: string | null
}

export interface LibrarySubcategory {
  /** @format uuid */
  id?: string
  name?: string | null
  elements?: LibraryElement[] | null
  isRepeat?: boolean
  repeatType?: string | null
}

export interface Message {
  /** @format uuid */
  messageId?: string
  from?: string | null
  subject?: string | null
  body?: string | null

  /** @format int32 */
  messageTypeId?: number

  /** @format uuid */
  fromUserId?: string
  isDeleted?: boolean

  /** @format date-time */
  deletedDate?: string | null

  /** @format date-time */
  createdDate?: string

  /** @format int32 */
  messageNumberId?: number
  messageNumber?: string | null
  notificationSent?: boolean | null

  /** @format int32 */
  messagesInThread?: number | null

  /** @format date-time */
  lastUpdatedDate?: string | null
  fromUser?: User
  messageType?: MessageType
  announcements?: Announcement[] | null
  attachments?: Attachment[] | null
  messageRecipients?: MessageRecipient[] | null
  pinnedMessages?: PinnedMessage[] | null
  readReceipts?: ReadReceipt[] | null
  replyMessages?: Reply[] | null
  replyParentMessages?: Reply[] | null
  tasks?: Task[] | null
  tickets?: Ticket[] | null
}

export interface MessageCategory {
  /** @format uuid */
  messageCategoryId?: string
  description?: string | null

  /** @format uuid */
  parentMessageCategoryId?: string | null

  /** @format uuid */
  divisionId?: string

  /** @format int32 */
  messageTypeId?: number
  foreignId?: string | null
  division?: Division
  messageType?: MessageType
  parentMessageCategory?: MessageCategory
  inverseParentMessageCategory?: MessageCategory[] | null
  tickets?: Ticket[] | null
  userMessageCategories?: UserMessageCategory[] | null
}

export interface MessagePriority {
  /** @format int32 */
  messagePriorityId?: number
  description?: string | null

  /** @format int32 */
  messageTypeId?: number
  messageType?: MessageType
  tasks?: Task[] | null
  tickets?: Ticket[] | null
}

export interface MessageRecipient {
  /** @format uuid */
  messageId?: string

  /** @format uuid */
  recipientId?: string

  /** @format int32 */
  recipientTypeId?: number
  message?: Message
  recipientType?: RecipientType
  completedTasks?: CompletedTask[] | null
}

export interface MessageStatus {
  /** @format int32 */
  messageStatusId?: number
  description?: string | null

  /** @format int32 */
  messageTypeId?: number
  messageType?: MessageType
  announcements?: Announcement[] | null
  tasks?: Task[] | null
  tickets?: Ticket[] | null
}

export interface MessageStatusViewModel {
  /** @format int32 */
  messageStatusId?: number
  description?: string | null
}

export interface MessageType {
  /** @format int32 */
  messageTypeId?: number
  description?: string | null
  displayPrefix?: string | null
  messageCategories?: MessageCategory[] | null
  messagePriorities?: MessagePriority[] | null
  messageStatuses?: MessageStatus[] | null
  messages?: Message[] | null
}

export interface Module {
  /** @format uuid */
  moduleId?: string
  description?: string | null
  foreignId?: string | null
  newsFeedItems?: NewsFeedItem[] | null
  permissions?: Permission[] | null
}

export interface NewsFeedItem {
  /** @format uuid */
  newsFeedItemId?: string

  /** @format uuid */
  moduleId?: string

  /** @format int32 */
  newsFeedOperationId?: number
  moduleReferenceId?: string | null
  displayId?: string | null
  deepLinkUrl?: string | null
  title?: string | null
  message?: string | null
  from?: string | null

  /** @format date-time */
  startDate?: string | null

  /** @format date-time */
  endDate?: string | null

  /** @format date-time */
  publishDate?: string
  thumbnailImageUrl?: string | null
  category?: string | null
  isArchived?: boolean

  /** @format date-time */
  createdDate?: string
  module?: Module
  newsFeedOperation?: NewsFeedOperation
  newsFeedReadReceipts?: NewsFeedReadReceipt[] | null
  newsFeedRecipients?: NewsFeedRecipient[] | null
}

export interface NewsFeedItemViewModel {
  /** @format uuid */
  newsFeedItemId?: string
  module?: string | null
  moduleReferenceId?: string | null
  displayId?: string | null
  operation?: NewsFeedOperationEnum
  deepLinkUrl?: string | null
  from?: string | null
  title?: string | null
  message?: string | null

  /** @format date-time */
  publishedDate?: string
  thumbnailImageUrl?: string | null

  /** @format date-time */
  startDate?: string | null

  /** @format date-time */
  endDate?: string | null
  category?: string | null
  isRead?: boolean
}

/**
 * @format int32
 */
export type NewsFeedItemsFilter = 0 | 1 | 2

export interface NewsFeedOperation {
  /** @format int32 */
  newsFeedOperationId?: number
  description?: string | null
  newsFeedItems?: NewsFeedItem[] | null
}

/**
 * @format int32
 */
export type NewsFeedOperationEnum = 1 | 2 | 3

export interface NewsFeedReadReceipt {
  /** @format uuid */
  newsFeedItemId?: string

  /** @format uuid */
  userId?: string

  /** @format date-time */
  readDate?: string
  newsFeedItem?: NewsFeedItem
  user?: User
}

export interface NewsFeedRecipient {
  /** @format uuid */
  newsFeedItemId?: string

  /** @format uuid */
  recipientId?: string

  /** @format int32 */
  recipientTypeId?: number
  newsFeedItem?: NewsFeedItem
  recipientType?: RecipientType
}

export interface Permission {
  /** @format uuid */
  permissionId?: string

  /** @format uuid */
  moduleId?: string
  description?: string | null

  /** @format int64 */
  value?: number
  module?: Module
  rolePermissions?: RolePermission[] | null
}

export interface PinnedMessage {
  /** @format uuid */
  messageId?: string

  /** @format uuid */
  userId?: string

  /** @format date-time */
  pinnedDate?: string
  message?: Message
  user?: User
}

export interface ProblemDetails {
  type?: string | null
  title?: string | null

  /** @format int32 */
  status?: number | null
  detail?: string | null
  instance?: string | null
}

export interface ProfileResponse {
  storeName?: string | null

  /** @format int32 */
  taskCount?: number

  /** @format uuid */
  userId?: string
  currentProfile?: boolean
}

export interface ReAssignTicketRequest {
  /** @format uuid */
  ticketCategoryId?: string
}

export interface ReadReceipt {
  /** @format uuid */
  messageId?: string

  /** @format uuid */
  userId?: string

  /** @format date-time */
  readDate?: string
  message?: Message
  user?: User
}

export interface Recipient {
  /** @format uuid */
  recipientId?: string
  recipientName?: string | null
  recipientType?: RecipientTypeEnum
}

export interface RecipientAttribute {
  description?: string | null

  /** @format uuid */
  id?: string
  recipientAttributeType?: RecipientAttributeEnum
}

/**
 * @format int32
 */
export type RecipientAttributeEnum = 1 | 2 | 3 | 4 | 5

export interface RecipientType {
  /** @format int32 */
  recipientTypeId?: number
  description?: string | null
  completedTasks?: CompletedTask[] | null
  messageRecipients?: MessageRecipient[] | null
  newsFeedRecipients?: NewsFeedRecipient[] | null
}

/**
 * @format int32
 */
export type RecipientTypeEnum = 1 | 2

export interface RecipientViewModel {
  /** @format uuid */
  recipientId?: string
  recipientType?: RecipientTypeEnum
  recipientDescription?: string | null
  countryDescription?: string | null
  areaDescription?: string | null
  regionDescription?: string | null
  teamDescription?: string | null

  /** @format uuid */
  countryId?: string | null

  /** @format uuid */
  regionId?: string | null

  /** @format uuid */
  areaId?: string | null

  /** @format uuid */
  teamId?: string | null

  /** @format uuid */
  roleId?: string
}

export interface Region {
  /** @format uuid */
  regionId?: string
  description?: string | null
  foreignId?: string | null
  groupAttributes?: GroupAttribute[] | null
  userAttributes?: UserAttribute[] | null
}

export interface RejectCXTaskRequest {
  body?: string | null
  subject?: string | null
  attachmentIds?: string[] | null
}

export interface RemoveMediaOnIssueRequest {
  /** @format uuid */
  assessmentId?: string

  /** @format uuid */
  elementAnswerId?: string

  /** @format uuid */
  issueId?: string

  /** @format uuid */
  evidenceImage?: string | null

  /** @format uuid */
  evidenceVideo?: string | null
}

/**
 * @format int32
 */
export type RepeatType = 0 | 1 | 2

export interface Reply {
  /** @format uuid */
  replyId?: string

  /** @format uuid */
  messageId?: string

  /** @format uuid */
  parentMessageId?: string
  message?: Message
  parentMessage?: Message
}

export interface Role {
  /** @format uuid */
  roleId?: string
  description?: string | null
  isAdTitleRole?: boolean
  isStoreManager?: boolean | null
  isAreaManager?: boolean | null
  isAssociate?: boolean | null
  cxTemplateRoleJoins?: CxTemplateRoleJoin[] | null
  rolePermissions?: RolePermission[] | null
  userRoles?: UserRole[] | null
}

export interface RolePermission {
  /** @format uuid */
  roleId?: string

  /** @format uuid */
  permissionId?: string
  permission?: Permission
  role?: Role
}

export interface SentAnnouncementDetailViewModel {
  /** @format uuid */
  announcementId: string
  announcementDisplayId: string
  subject: string
  from?: string | null
  attachments?: FileAttachment[] | null

  /** @format date-time */
  datePublished?: string | null
  announcementStatusDescription?: string | null

  /** @format date-time */
  dateCreated?: string
}

export interface SentTaskDetailViewModel {
  /** @format uuid */
  taskId?: string
  taskDisplayId?: string | null

  /** @format int32 */
  taskStatusId?: number

  /** @format int32 */
  taskPriorityId?: number
  subject?: string | null
  body?: string | null

  /** @format date-time */
  dueDate?: string

  /** @format double */
  completionPercentage?: number

  /** @format date-time */
  dateCreated?: string
}

export interface StoreConfig {
  /** @format uuid */
  storeConfigId?: string

  /** @format int32 */
  windowCount?: number

  /** @format int32 */
  entranceCount?: number

  /** @format int32 */
  driveAisle?: number
}

export interface StoresList {
  storeId?: string | null
  description?: string | null
  location?: string | null
  internalId?: string | null
  foreignId?: string | null
  code?: string | null
  divisionId?: string | null
  gpsLatitude?: string | null
  gpsLongitude?: string | null
}

export interface StoresResponse {
  stores?: StoresList[] | null
}

export interface SubmitAssessmentRequest {
  /** @format uuid */
  assessmentId?: string
}

export interface Task {
  /** @format uuid */
  taskId?: string

  /** @format uuid */
  messageId?: string

  /** @format int32 */
  taskPriorityId?: number

  /** @format int32 */
  taskStatusId?: number

  /** @format date-time */
  dueDate?: string
  isCxApp?: boolean | null
  message?: Message
  taskPriority?: MessagePriority
  taskStatus?: MessageStatus
  completedTasks?: CompletedTask[] | null
}

/**
 * @format int32
 */
export type TaskFilterValue = 0 | 1 | 2 | 3

export interface TaskPriorityViewModel {
  /** @format int32 */
  taskPriorityId?: number
  description?: string | null
}

export interface TaskRecipientViewModel {
  /** @format date-time */
  completedDate?: string | null
  completedBy?: string | null
  isCompleted?: boolean
  recipient?: Recipient
}

export interface TaskRepliesViewModel {
  /** @format uuid */
  taskReplyId?: string
  body?: string | null
  subject?: string | null
  from?: string | null
  attachments?: FileAttachment[] | null

  /** @format date-time */
  dateCreated?: string
}

export interface TaskStatusViewModel {
  /** @format int32 */
  taskStatusId?: number
  description?: string | null
  isCxApp?: boolean
  isTaskApp?: boolean
}

export interface TaskViewModel {
  /** @format uuid */
  taskId?: string
  taskDisplayId?: string | null

  /** @format int32 */
  taskStatusId?: number

  /** @format int32 */
  taskPriorityId?: number
  subject?: string | null
  body?: string | null

  /** @format date-time */
  dueDate?: string
  taskRecipient?: TaskRecipientViewModel
  attachments?: FileAttachment[] | null

  /** @format date-time */
  dateCreated?: string
  isCxApp?: boolean | null

  /** @format uuid */
  messageId?: string

  /** @format date-time */
  lastUpdatedDate?: string | null
  storeName?: string | null

  /** @format uuid */
  teamId?: string | null
}

export interface Team {
  /** @format uuid */
  teamId?: string
  description?: string | null
  foreignId?: string | null
  storeCode?: string | null

  /** @format int32 */
  divisionId?: number | null
  groupAttributes?: GroupAttribute[] | null
  userAttributes?: UserAttribute[] | null
}

export interface TemplateSurvey {
  focusAreas?: IssueElementIssueElementFocusArea[] | null
  name?: string | null

  /** @format uuid */
  templateId?: string
  roles?: string[] | null
  username?: string | null

  /** @format date-time */
  dateCreated?: string | null
  isActive?: boolean
}

export interface Ticket {
  /** @format uuid */
  ticketId?: string

  /** @format uuid */
  messageId?: string

  /** @format int32 */
  ticketPriorityId?: number

  /** @format int32 */
  ticketStatusId?: number

  /** @format uuid */
  ticketCategoryId?: string

  /** @format uuid */
  mergedTicketId?: string | null
  mergedTicket?: Ticket
  message?: Message
  ticketCategory?: MessageCategory
  ticketPriority?: MessagePriority
  ticketStatus?: MessageStatus
  inverseMergedTicket?: Ticket[] | null
}

export interface TicketCategory {
  /** @format uuid */
  ticketCategoryId?: string
  ticketCategoryDescription?: string | null
  parentTicketCategory?: TicketCategory
}

export interface TicketCategoryViewModel {
  /** @format uuid */
  ticketCategoryId?: string
  description?: string | null
  childCategories?: TicketCategoryViewModel[] | null
}

export interface TicketDetailViewModel {
  ticketDisplayId?: string | null
  ticketPriority: TicketPriority
  ticketStatus: TicketStatus
  ticketCategory: TicketCategory
  subject: string
  body?: string | null
  from?: string | null
  attachments?: FileAttachment[] | null

  /** @format date-time */
  dateCreated?: string
  isPinned?: boolean
  isRead?: boolean
  replies?: TicketReplyViewModel[] | null
}

/**
 * @format int32
 */
export type TicketFilterValue = 0 | 1 | 2 | 3 | 4 | 5

export interface TicketPriority {
  /** @format int32 */
  ticketPriorityId?: number
  ticketPriorityDescription?: string | null
}

export interface TicketPriorityViewModel {
  /** @format int32 */
  ticketPriorityId?: number
  description?: string | null
}

export interface TicketReplyViewModel {
  /** @format uuid */
  ticketReplyId?: string
  body?: string | null
  subject?: string | null
  from?: string | null
  attachments?: FileAttachment[] | null

  /** @format date-time */
  dateCreated?: string
}

export interface TicketStatus {
  /** @format int32 */
  ticketStatusId?: number
  ticketStatusDescription?: string | null
}

export interface TicketStatusViewModel {
  /** @format int32 */
  ticketStatusId?: number
  description?: string | null
}

export interface TicketSummaryViewModel {
  subject?: string | null
  ticketStatus?: TicketStatus

  /** @format uuid */
  ticketId?: string
  ticketDisplayId?: string | null
  isPinned?: boolean
  isRead?: boolean
  from?: string | null
  ticketCategory?: TicketCategory
  attachments?: FileAttachment[] | null

  /** @format date-time */
  dateCreated?: string
  ticketPriority?: TicketPriority

  /** @format int32 */
  numberOfMessagesInThread?: number

  /** @format date-time */
  lastUpdatedDate?: string
  message?: Message

  /** @format uuid */
  messageId?: string
}

export interface ToggleCompletionRequest {
  recipient: Recipient
}

export interface UpdateAnnouncementStatusRequest {
  /** @format int32 */
  announcementStatusId: number
}

export interface UpdateFocusAreaRequest {
  /** @format uuid */
  focusAreaGuid?: string
  focusName?: string | null
  focusAbbrev?: string | null
}

export interface UpdateLibraryIssuesRequest {
  /** @format uuid */
  elementId?: string

  /** @format uuid */
  issueId?: string
  name?: string | null
}

export interface UpdateTaskStatusRequest {
  /** @format int32 */
  taskStatusId: number
}

export interface UpdateTemplateRequest {
  /** @format uuid */
  templateId?: string
  name?: string | null
  roleIds?: string[] | null
}

export interface UpdateTicketStatusViewModel {
  /** @format int32 */
  ticketStatusId: number
}

export interface User {
  /** @format uuid */
  userId?: string
  firstName?: string | null
  lastName?: string | null
  fullName?: string | null
  password?: string | null

  /** @format int32 */
  userTypeId?: number
  userName?: string | null
  emailAddress?: string | null
  foreignId?: string | null
  isoCountryCode?: string | null
  associatedStore?: string | null
  employeeNumber?: string | null
  userType?: UserType
  attachments?: Attachment[] | null
  completedTasks?: CompletedTask[] | null
  cxAssessments?: CxAssessment[] | null
  cxElementAnswers?: CxElementAnswer[] | null
  cxTemplates?: CxTemplate[] | null
  messages?: Message[] | null
  newsFeedReadReceipts?: NewsFeedReadReceipt[] | null
  pinnedMessages?: PinnedMessage[] | null
  readReceipts?: ReadReceipt[] | null
  userAttributes?: UserAttribute[] | null
  userCurrentStores?: UserCurrentStore[] | null
  userGroups?: UserGroup[] | null
  userMessageCategories?: UserMessageCategory[] | null
  userRoles?: UserRole[] | null
}

export interface UserAttribute {
  /** @format uuid */
  userAttributeId?: string

  /** @format uuid */
  userId?: string

  /** @format uuid */
  divisionId?: string | null

  /** @format uuid */
  countryId?: string | null

  /** @format uuid */
  regionId?: string | null

  /** @format uuid */
  areaId?: string | null

  /** @format uuid */
  teamId?: string | null
  area?: Area
  country?: Country
  division?: Division
  region?: Region
  team?: Team
  user?: User
}

export interface UserCategoryAssignmentViewModel {
  /** @format int32 */
  foreignDivisionId: number

  /** @format int32 */
  categoryId: number
  categoryName: string

  /** @format int32 */
  parentCategoryId?: number | null
  parentCategoryName?: string | null
  userCategories?: UserCategoryViewModel[] | null
}

export interface UserCategoryViewModel {
  userName: string

  /** @format int32 */
  foreignTeamId: number
  isOwner?: boolean
}

export interface UserCurrentStore {
  /** @format uuid */
  userCurrentStoreId?: string

  /** @format uuid */
  userId?: string | null

  /** @format int32 */
  userProfileId?: number | null
  accessToken?: string | null
  user?: User
}

export interface UserGroup {
  /** @format uuid */
  groupId?: string

  /** @format uuid */
  userId?: string
  group?: Group
  user?: User
}

export interface UserMessageCategory {
  /** @format uuid */
  userMessageCategoryId?: string

  /** @format uuid */
  messageCategoryId?: string

  /** @format uuid */
  userId?: string
  isOwner?: boolean
  messageCategory?: MessageCategory
  user?: User
}

export interface UserRole {
  /** @format uuid */
  roleId?: string

  /** @format uuid */
  userId?: string
  role?: Role
  user?: User
}

export interface UserRoles {
  /** @format uuid */
  roleId?: string
  description?: string | null
}

export interface UserType {
  /** @format int32 */
  userTypeId?: number
  description?: string | null
  users?: User[] | null
}

export interface UserTypeViewModel {
  /** @format int32 */
  userTypeId?: number
  name?: string | null
}

export interface VersionResponse {
  versionCode?: string | null

  /** @format int32 */
  major?: number

  /** @format int32 */
  minor?: number

  /** @format int32 */
  build?: number

  /** @format int32 */
  revision?: number
}

export interface WeatherForecast {
  /** @format date-time */
  date?: string

  /** @format int32 */
  temperatureC?: number

  /** @format int32 */
  temperatureF?: number
  summary?: string | null
}

export interface WindowPosition {
  /** @format uuid */
  typeId?: string
  description?: string | null
  cxWindowPriorities?: CxWindowPriority[] | null
}

import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType} from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({securityWorker, secure, format, ...axiosConfig}: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({...axiosConfig, baseURL: axiosConfig.baseURL || '/Empower/Api/Dev2'})
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  private mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === 'object' && property !== null
          ? JSON.stringify(property)
          : `${property}`
      )
      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = (format && this.format) || void 0

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      requestParams.headers.common = {Accept: '*/*'}
      requestParams.headers.post = {}
      requestParams.headers.put = {}

      body = this.createFormData(body as Record<string, unknown>)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? {'Content-Type': type} : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })
  }
}

/**
 * @title Empower API .net Core
 * @version v1
 * @baseUrl /Empower/Api/Dev2
 * @contact Sebastian de Charmoy <sdecharmoy@mrpg.com>
 *
 * InTouch API's
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Announcements
     * @name V1AnnouncementsDetail
     * @request GET:/api/v1/announcements/{announcementId}
     */
    v1AnnouncementsDetail: (announcementId: string, params: RequestParams = {}) =>
      this.request<AnnouncementDetailViewModel, ProblemDetails>({
        path: `/api/v1/announcements/${announcementId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Announcements
     * @name V1AnnouncementsDelete
     * @request DELETE:/api/v1/announcements/{announcementId}
     */
    v1AnnouncementsDelete: (announcementId: string, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/v1/announcements/${announcementId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Announcements
     * @name V1AnnouncementsStatusesList
     * @request GET:/api/v1/announcements/statuses
     */
    v1AnnouncementsStatusesList: (params: RequestParams = {}) =>
      this.request<MessageStatusViewModel[], ProblemDetails>({
        path: `/api/v1/announcements/statuses`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Announcements
     * @name V1AnnouncementsList
     * @request GET:/api/v1/announcements
     */
    v1AnnouncementsList: (
      query?: {
        filterValue?: AnnouncementFilterValue
        filterText?: string
        sortField?: string
        sortDirection?: string
        pageSize?: number
        pageNumber?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<AnnouncementSummaryResponse, ProblemDetails>({
        path: `/api/v1/announcements`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Announcements
     * @name V1AnnouncementsCreate
     * @request POST:/api/v1/announcements
     */
    v1AnnouncementsCreate: (data: CreateAnnouncementRequest, params: RequestParams = {}) =>
      this.request<CreateAnnouncementResponse, ProblemDetails>({
        path: `/api/v1/announcements`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Announcements
     * @name V1AnnouncementsSentList
     * @request GET:/api/v1/announcements/sent
     */
    v1AnnouncementsSentList: (
      query?: {filterText?: string; sortField?: string; sortDirection?: string; pageSize?: number; pageNumber?: number},
      params: RequestParams = {}
    ) =>
      this.request<GetSentAnnouncementsResponse, ProblemDetails>({
        path: `/api/v1/announcements/sent`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Announcements
     * @name V1AnnouncementsToggleReadPartialUpdate
     * @request PATCH:/api/v1/announcements/{announcementId}/toggleRead
     */
    v1AnnouncementsToggleReadPartialUpdate: (announcementId: string, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/v1/announcements/${announcementId}/toggleRead`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Announcements
     * @name V1AnnouncementsTogglePinPartialUpdate
     * @request PATCH:/api/v1/announcements/{announcementId}/togglePin
     */
    v1AnnouncementsTogglePinPartialUpdate: (announcementId: string, params: RequestParams = {}) =>
      this.request<any, ProblemDetails>({
        path: `/api/v1/announcements/${announcementId}/togglePin`,
        method: 'PATCH',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Announcements
     * @name V1AnnouncementsStatusPartialUpdate
     * @request PATCH:/api/v1/announcements/{announcementId}/status
     */
    v1AnnouncementsStatusPartialUpdate: (
      announcementId: string,
      data: UpdateAnnouncementStatusRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, ProblemDetails>({
        path: `/api/v1/announcements/${announcementId}/status`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Attachments
     * @name V1AttachmentsCreate
     * @request POST:/api/v1/attachments
     */
    v1AttachmentsCreate: (
      data: {
        ContentType?: string
        ContentDisposition?: string
        Headers?: Record<string, string[]>
        Length?: number
        Name?: string
        FileName?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<FileUploadResponse, ProblemDetails>({
        path: `/api/v1/attachments`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Attachments
     * @name V1AttachmentsDetail
     * @request GET:/api/v1/attachments/{attachmentId}
     */
    v1AttachmentsDetail: (attachmentId: string, query?: {isDownload?: boolean}, params: RequestParams = {}) =>
      this.request<File, ProblemDetails>({
        path: `/api/v1/attachments/${attachmentId}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Attachments
     * @name V1AttachmentsDelete
     * @request DELETE:/api/v1/attachments/{attachmentId}
     */
    v1AttachmentsDelete: (attachmentId: string, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/v1/attachments/${attachmentId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Attachments
     * @name V1AttachmentsStreamDetail
     * @request GET:/api/v1/attachments/stream/{attachmentId}
     */
    v1AttachmentsStreamDetail: (attachmentId: string, params: RequestParams = {}) =>
      this.request<File, ProblemDetails>({
        path: `/api/v1/attachments/stream/${attachmentId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Attachments
     * @name V1AttachmentsAttachmentsUrlDetail
     * @request GET:/api/v1/attachments/attachmentsUrl/{attachmentId}
     */
    v1AttachmentsAttachmentsUrlDetail: (attachmentId: string, params: RequestParams = {}) =>
      this.request<File, ProblemDetails>({
        path: `/api/v1/attachments/attachmentsUrl/${attachmentId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name V1AuthUserDetailList
     * @request GET:/api/v1/Auth/UserDetail
     */
    v1AuthUserDetailList: (params: RequestParams = {}) =>
      this.request<AzureLoginResponse, ProblemDetails>({
        path: `/api/v1/Auth/UserDetail`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name V1AuthLoginCreate
     * @request POST:/api/v1/Auth/Login
     */
    v1AuthLoginCreate: (data: AuthenticationLoginRequest, params: RequestParams = {}) =>
      this.request<AzureLoginResponse, ProblemDetails>({
        path: `/api/v1/Auth/Login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceStoreConfigDetail
     * @request GET:/api/v1/customerexperience/storeConfig/{storeId}
     */
    v1CustomerexperienceStoreConfigDetail: (storeId: string, params: RequestParams = {}) =>
      this.request<StoreConfig, ProblemDetails>({
        path: `/api/v1/customerexperience/storeConfig/${storeId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCreateStoreConfigCreate
     * @request POST:/api/v1/customerexperience/createStoreConfig
     */
    v1CustomerexperienceCreateStoreConfigCreate: (data: StoreConfig, params: RequestParams = {}) =>
      this.request<StoreConfig, ProblemDetails>({
        path: `/api/v1/customerexperience/createStoreConfig`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceGetWindowPositionsList
     * @request GET:/api/v1/customerexperience/getWindowPositions
     */
    v1CustomerexperienceGetWindowPositionsList: (params: RequestParams = {}) =>
      this.request<WindowPosition[], ProblemDetails>({
        path: `/api/v1/customerexperience/getWindowPositions`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceAddWindowPriorityCreate
     * @request POST:/api/v1/customerexperience/addWindowPriority
     */
    v1CustomerexperienceAddWindowPriorityCreate: (data: CxWindowPriority, params: RequestParams = {}) =>
      this.request<boolean, ProblemDetails>({
        path: `/api/v1/customerexperience/addWindowPriority`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxLibraryCreateLibraryFocusAreaCreate
     * @request POST:/api/v1/customerexperience/cx/library/createLibraryFocusArea
     */
    v1CustomerexperienceCxLibraryCreateLibraryFocusAreaCreate: (
      data: CreateFocusAreaRequest,
      params: RequestParams = {}
    ) =>
      this.request<LibraryFocusArea, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/library/createLibraryFocusArea`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxLibraryUpdateLibraryFocusAreaCreate
     * @request POST:/api/v1/customerexperience/cx/library/updateLibraryFocusArea
     */
    v1CustomerexperienceCxLibraryUpdateLibraryFocusAreaCreate: (
      data: UpdateFocusAreaRequest,
      params: RequestParams = {}
    ) =>
      this.request<LibraryFocusArea, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/library/updateLibraryFocusArea`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxLibraryDeleteLibraryFocusAreaCreate
     * @request POST:/api/v1/customerexperience/cx/library/deleteLibraryFocusArea/{focusAreaId}
     */
    v1CustomerexperienceCxLibraryDeleteLibraryFocusAreaCreate: (focusAreaId: string, params: RequestParams = {}) =>
      this.request<boolean, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/library/deleteLibraryFocusArea/${focusAreaId}`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxLibraryCreateLibraryCategoryCreate
     * @request POST:/api/v1/customerexperience/cx/library/createLibraryCategory
     */
    v1CustomerexperienceCxLibraryCreateLibraryCategoryCreate: (
      data: CreateLibraryCategoryRequest,
      params: RequestParams = {}
    ) =>
      this.request<LibraryFocusArea, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/library/createLibraryCategory`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxLibraryDeleteLibraryCategoryCreate
     * @request POST:/api/v1/customerexperience/cx/library/deleteLibraryCategory/{categoryId}
     */
    v1CustomerexperienceCxLibraryDeleteLibraryCategoryCreate: (categoryId: string, params: RequestParams = {}) =>
      this.request<boolean, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/library/deleteLibraryCategory/${categoryId}`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxLibraryCreateLibrarySubcategoryCreate
     * @request POST:/api/v1/customerexperience/cx/library/createLibrarySubcategory
     */
    v1CustomerexperienceCxLibraryCreateLibrarySubcategoryCreate: (
      data: CreateLibrarySubcategoryRequest,
      params: RequestParams = {}
    ) =>
      this.request<LibraryFocusArea, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/library/createLibrarySubcategory`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxLibraryDeleteLibrarySubcategoryCreate
     * @request POST:/api/v1/customerexperience/cx/library/deleteLibrarySubcategory/{subcategoryId}
     */
    v1CustomerexperienceCxLibraryDeleteLibrarySubcategoryCreate: (subcategoryId: string, params: RequestParams = {}) =>
      this.request<boolean, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/library/deleteLibrarySubcategory/${subcategoryId}`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxLibraryCreateLibraryElementCreate
     * @request POST:/api/v1/customerexperience/cx/library/createLibraryElement
     */
    v1CustomerexperienceCxLibraryCreateLibraryElementCreate: (
      data: CreateLibraryElementRequest,
      params: RequestParams = {}
    ) =>
      this.request<LibraryFocusArea, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/library/createLibraryElement`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxLibraryDeleteLibraryElementCreate
     * @request POST:/api/v1/customerexperience/cx/library/deleteLibraryElement/{elementId}
     */
    v1CustomerexperienceCxLibraryDeleteLibraryElementCreate: (elementId: string, params: RequestParams = {}) =>
      this.request<boolean, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/library/deleteLibraryElement/${elementId}`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxLibraryAddLibraryIssuesCreate
     * @request POST:/api/v1/customerexperience/cx/library/addLibraryIssues
     */
    v1CustomerexperienceCxLibraryAddLibraryIssuesCreate: (
      data: CreateLibraryIssuesRequest,
      params: RequestParams = {}
    ) =>
      this.request<LibraryFocusArea, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/library/addLibraryIssues`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxLibraryUpdateLibraryIssueCreate
     * @request POST:/api/v1/customerexperience/cx/library/updateLibraryIssue
     */
    v1CustomerexperienceCxLibraryUpdateLibraryIssueCreate: (
      data: UpdateLibraryIssuesRequest,
      params: RequestParams = {}
    ) =>
      this.request<LibraryFocusArea, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/library/updateLibraryIssue`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxLibraryDeleteLibraryIssueCreate
     * @request POST:/api/v1/customerexperience/cx/library/deleteLibraryIssue/{issueId}
     */
    v1CustomerexperienceCxLibraryDeleteLibraryIssueCreate: (issueId: string, params: RequestParams = {}) =>
      this.request<boolean, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/library/deleteLibraryIssue/${issueId}`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxLibraryGetFocusAreaDetail
     * @request GET:/api/v1/customerexperience/cx/library/getFocusArea/{focusAreaId}
     */
    v1CustomerexperienceCxLibraryGetFocusAreaDetail: (focusAreaId: string, params: RequestParams = {}) =>
      this.request<LibraryFocusArea, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/library/getFocusArea/${focusAreaId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxLibraryGetFocusAreasList
     * @request GET:/api/v1/customerexperience/cx/library/getFocusAreas
     */
    v1CustomerexperienceCxLibraryGetFocusAreasList: (params: RequestParams = {}) =>
      this.request<BasicLibraryFocusArea[], ProblemDetails>({
        path: `/api/v1/customerexperience/cx/library/getFocusAreas`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxTemplateCreateCreate
     * @request POST:/api/v1/customerexperience/cx/template/create
     */
    v1CustomerexperienceCxTemplateCreateCreate: (data: CreateTemplateRequest, params: RequestParams = {}) =>
      this.request<TemplateSurvey, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/template/create`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxTemplateUpdateCreate
     * @request POST:/api/v1/customerexperience/cx/template/update
     */
    v1CustomerexperienceCxTemplateUpdateCreate: (data: UpdateTemplateRequest, params: RequestParams = {}) =>
      this.request<TemplateSurvey, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/template/update`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxTemplateActivateCreate
     * @request POST:/api/v1/customerexperience/cx/template/activate
     */
    v1CustomerexperienceCxTemplateActivateCreate: (
      data: ChangeTemplateActiveStateRequest,
      params: RequestParams = {}
    ) =>
      this.request<TemplateSurvey, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/template/activate`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxTemplateList
     * @request GET:/api/v1/customerexperience/cx/template
     */
    v1CustomerexperienceCxTemplateList: (query?: {templateId?: string}, params: RequestParams = {}) =>
      this.request<TemplateSurvey, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/template`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxTemplateFetchList
     * @request GET:/api/v1/customerexperience/cx/template/fetch
     */
    v1CustomerexperienceCxTemplateFetchList: (query?: {searchRegex?: string}, params: RequestParams = {}) =>
      this.request<CXTemplateSummary[], ProblemDetails>({
        path: `/api/v1/customerexperience/cx/template/fetch`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxTemplateFetchForUserList
     * @request GET:/api/v1/customerexperience/cx/template/fetchForUser
     */
    v1CustomerexperienceCxTemplateFetchForUserList: (params: RequestParams = {}) =>
      this.request<CXTemplateSummary[], ProblemDetails>({
        path: `/api/v1/customerexperience/cx/template/fetchForUser`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxAssessmentCreateCreate
     * @request POST:/api/v1/customerexperience/cx/assessment/create
     */
    v1CustomerexperienceCxAssessmentCreateCreate: (data: CreateAssessmentRequest, params: RequestParams = {}) =>
      this.request<Assessment, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/assessment/create`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxAssessmentFetchIncompleteList
     * @request GET:/api/v1/customerexperience/cx/assessment/fetchIncomplete
     */
    v1CustomerexperienceCxAssessmentFetchIncompleteList: (query?: {storeId?: string}, params: RequestParams = {}) =>
      this.request<AssessmentSummary[], ProblemDetails>({
        path: `/api/v1/customerexperience/cx/assessment/fetchIncomplete`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxAssessmentFetchList
     * @request GET:/api/v1/customerexperience/cx/assessment/fetch
     */
    v1CustomerexperienceCxAssessmentFetchList: (query?: {id?: string}, params: RequestParams = {}) =>
      this.request<Assessment, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/assessment/fetch`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxAssessmentAnswerElementCreate
     * @request POST:/api/v1/customerexperience/cx/assessment/answerElement
     */
    v1CustomerexperienceCxAssessmentAnswerElementCreate: (data: AnswerElementRequest, params: RequestParams = {}) =>
      this.request<Assessment, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/assessment/answerElement`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxAssessmentAddCommentEvidenceCreate
     * @request POST:/api/v1/customerexperience/cx/assessment/addCommentEvidence
     */
    v1CustomerexperienceCxAssessmentAddCommentEvidenceCreate: (
      data: CommentOnIssueRequest,
      params: RequestParams = {}
    ) =>
      this.request<Assessment, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/assessment/addCommentEvidence`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxAssessmentAddMediaEvidenceCreate
     * @request POST:/api/v1/customerexperience/cx/assessment/addMediaEvidence
     */
    v1CustomerexperienceCxAssessmentAddMediaEvidenceCreate: (params: RequestParams = {}) =>
      this.request<Assessment, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/assessment/addMediaEvidence`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxAssessmentSetSelectedIssueCreate
     * @request POST:/api/v1/customerexperience/cx/assessment/setSelectedIssue
     */
    v1CustomerexperienceCxAssessmentSetSelectedIssueCreate: (
      data: IsIssueSelectedRequest,
      params: RequestParams = {}
    ) =>
      this.request<Assessment, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/assessment/setSelectedIssue`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxAssessmentRemoveMediaEvidenceCreate
     * @request POST:/api/v1/customerexperience/cx/assessment/removeMediaEvidence
     */
    v1CustomerexperienceCxAssessmentRemoveMediaEvidenceCreate: (
      data: RemoveMediaOnIssueRequest,
      params: RequestParams = {}
    ) =>
      this.request<Assessment, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/assessment/removeMediaEvidence`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxAssessmentSubmitCreate
     * @request POST:/api/v1/customerexperience/cx/assessment/submit
     */
    v1CustomerexperienceCxAssessmentSubmitCreate: (data: SubmitAssessmentRequest, params: RequestParams = {}) =>
      this.request<SubmitAssessmentRequest, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/assessment/submit`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxAssessmentGetEvidenceImagesList
     * @request GET:/api/v1/customerexperience/cx/assessment/getEvidenceImages
     */
    v1CustomerexperienceCxAssessmentGetEvidenceImagesList: (query?: {typeId?: string}, params: RequestParams = {}) =>
      this.request<EvidenceImage[], ProblemDetails>({
        path: `/api/v1/customerexperience/cx/assessment/getEvidenceImages`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxAssessmentGetEvidenceVideosList
     * @request GET:/api/v1/customerexperience/cx/assessment/getEvidenceVideos
     */
    v1CustomerexperienceCxAssessmentGetEvidenceVideosList: (query?: {typeId?: string}, params: RequestParams = {}) =>
      this.request<EvidenceVideo[], ProblemDetails>({
        path: `/api/v1/customerexperience/cx/assessment/getEvidenceVideos`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerExperience
     * @name V1CustomerexperienceCxAssessmentKpiCreate
     * @request POST:/api/v1/customerexperience/cx/assessment/kpi
     */
    v1CustomerexperienceCxAssessmentKpiCreate: (data: KPIQueryParameters, params: RequestParams = {}) =>
      this.request<KPIResponse, ProblemDetails>({
        path: `/api/v1/customerexperience/cx/assessment/kpi`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags NewsFeedItems
     * @name V1NewsFeedItemsList
     * @request GET:/api/v1/newsFeedItems
     */
    v1NewsFeedItemsList: (
      query?: {filter?: NewsFeedItemsFilter; pageNumber?: number; pageSize?: number},
      params: RequestParams = {}
    ) =>
      this.request<GetNewsFeedItemsResponse, ProblemDetails>({
        path: `/api/v1/newsFeedItems`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags NewsFeedItems
     * @name V1NewsFeedItemsToggleReadPartialUpdate
     * @request PATCH:/api/v1/newsFeedItems/{newsFeedItemId}/toggleRead
     */
    v1NewsFeedItemsToggleReadPartialUpdate: (newsFeedItemId: string, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/v1/newsFeedItems/${newsFeedItemId}/toggleRead`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags NewsFeedItems
     * @name V1NewsFeedItemsMarkAllReadPartialUpdate
     * @request PATCH:/api/v1/newsFeedItems/markAllRead
     */
    v1NewsFeedItemsMarkAllReadPartialUpdate: (params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/v1/newsFeedItems/markAllRead`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags NewsFeedItems
     * @name V1NewsFeedItemsUnreadCountList
     * @request GET:/api/v1/newsFeedItems/unreadCount
     */
    v1NewsFeedItemsUnreadCountList: (params: RequestParams = {}) =>
      this.request<GetUnreadCountResponse, ProblemDetails>({
        path: `/api/v1/newsFeedItems/unreadCount`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name V1ProfileGetProfilesList
     * @request GET:/api/v1/Profile/GetProfiles
     */
    v1ProfileGetProfilesList: (params: RequestParams = {}) =>
      this.request<ProfileResponse[], ProblemDetails>({
        path: `/api/v1/Profile/GetProfiles`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profile
     * @name V1ProfileChangeProfilesCreate
     * @request POST:/api/v1/Profile/ChangeProfiles
     */
    v1ProfileChangeProfilesCreate: (data: ProfileResponse, params: RequestParams = {}) =>
      this.request<ProfileResponse, ProblemDetails>({
        path: `/api/v1/Profile/ChangeProfiles`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipients
     * @name V1RecipientsUsersList
     * @request GET:/api/v1/recipients/users
     */
    v1RecipientsUsersList: (
      query?: {
        divisionId?: string
        countryId?: string
        regionId?: string
        areaId?: string
        teamId?: string
        filterText?: string
        sortField?: string
        sortDirection?: string
        pageSize?: number
        pageNumber?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<GetUserRecipientsResponse, ProblemDetails>({
        path: `/api/v1/recipients/users`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipients
     * @name V1RecipientsGroupsList
     * @request GET:/api/v1/recipients/groups
     */
    v1RecipientsGroupsList: (
      query?: {
        divisionId?: string
        countryId?: string
        regionId?: string
        areaId?: string
        teamId?: string
        filterText?: string
        sortField?: string
        sortDirection?: string
        pageSize?: number
        pageNumber?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<GetGroupRecipientsResponse, ProblemDetails>({
        path: `/api/v1/recipients/groups`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipients
     * @name V1RecipientsDivisionsList
     * @request GET:/api/v1/recipients/divisions
     */
    v1RecipientsDivisionsList: (params: RequestParams = {}) =>
      this.request<GetDivisionsResponse, ProblemDetails>({
        path: `/api/v1/recipients/divisions`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipients
     * @name V1RecipientsCountriesList
     * @request GET:/api/v1/recipients/countries
     */
    v1RecipientsCountriesList: (query?: {divisionId?: string}, params: RequestParams = {}) =>
      this.request<GetCountriesResponse, ProblemDetails>({
        path: `/api/v1/recipients/countries`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipients
     * @name V1RecipientsRegionsList
     * @request GET:/api/v1/recipients/regions
     */
    v1RecipientsRegionsList: (query?: {countryId?: string}, params: RequestParams = {}) =>
      this.request<GetRegionsResponse, ProblemDetails>({
        path: `/api/v1/recipients/regions`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipients
     * @name V1RecipientsAreasList
     * @request GET:/api/v1/recipients/areas
     */
    v1RecipientsAreasList: (query?: {regionId?: string}, params: RequestParams = {}) =>
      this.request<GetAreasResponse, ProblemDetails>({
        path: `/api/v1/recipients/areas`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipients
     * @name V1RecipientsTeamsList
     * @request GET:/api/v1/recipients/teams
     */
    v1RecipientsTeamsList: (query?: {areaId?: string}, params: RequestParams = {}) =>
      this.request<GetTeamsResponse, ProblemDetails>({
        path: `/api/v1/recipients/teams`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stores
     * @name V1StoresGetallstoresList
     * @request GET:/api/v1/stores/getallstores
     */
    v1StoresGetallstoresList: (query?: {divisionId?: string}, params: RequestParams = {}) =>
      this.request<StoresResponse, ProblemDetails>({
        path: `/api/v1/stores/getallstores`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stores
     * @name V1StoresDivisionsList
     * @request GET:/api/v1/stores/divisions
     */
    v1StoresDivisionsList: (params: RequestParams = {}) =>
      this.request<DivisionsResponse, ProblemDetails>({
        path: `/api/v1/stores/divisions`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tasks
     * @name V1TasksSentList
     * @request GET:/api/v1/tasks/sent
     */
    v1TasksSentList: (
      query?: {filterText?: string; sortField?: string; sortDirection?: string; pageSize?: number; pageNumber?: number},
      params: RequestParams = {}
    ) =>
      this.request<GetSentTasksResponse, ProblemDetails>({
        path: `/api/v1/tasks/sent`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tasks
     * @name V1TasksCreate
     * @request POST:/api/v1/tasks
     */
    v1TasksCreate: (data: CreateTaskRequest, params: RequestParams = {}) =>
      this.request<string, ProblemDetails>({
        path: `/api/v1/tasks`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tasks
     * @name V1TasksList
     * @request GET:/api/v1/tasks
     */
    v1TasksList: (
      query?: {
        filterValue?: TaskFilterValue
        filterText?: string
        sortField?: string
        sortDirection?: string
        pageSize?: number
        pageNumber?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<GetRecipientTasksResponse, ProblemDetails>({
        path: `/api/v1/tasks`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tasks
     * @name V1TasksStatusesList
     * @request GET:/api/v1/tasks/statuses
     */
    v1TasksStatusesList: (params: RequestParams = {}) =>
      this.request<GetTaskStatusesResponse, ProblemDetails>({
        path: `/api/v1/tasks/statuses`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tasks
     * @name V1TasksPrioritiesList
     * @request GET:/api/v1/tasks/priorities
     */
    v1TasksPrioritiesList: (params: RequestParams = {}) =>
      this.request<GetTaskPrioritiesResponse, ProblemDetails>({
        path: `/api/v1/tasks/priorities`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tasks
     * @name V1TasksStatusPartialUpdate
     * @request PATCH:/api/v1/tasks/{taskId}/status
     */
    v1TasksStatusPartialUpdate: (taskId: string, data: UpdateTaskStatusRequest, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/v1/tasks/${taskId}/status`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tasks
     * @name V1TasksDetail
     * @request GET:/api/v1/tasks/{taskId}
     */
    v1TasksDetail: (taskId: string, params: RequestParams = {}) =>
      this.request<GetTaskDetailsResponse, ProblemDetails>({
        path: `/api/v1/tasks/${taskId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tasks
     * @name V1TasksDelete
     * @request DELETE:/api/v1/tasks/{taskId}
     */
    v1TasksDelete: (taskId: string, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/v1/tasks/${taskId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tasks
     * @name V1TasksToggleCompletionPartialUpdate
     * @request PATCH:/api/v1/tasks/{taskId}/toggleCompletion
     */
    v1TasksToggleCompletionPartialUpdate: (taskId: string, data: ToggleCompletionRequest, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/v1/tasks/${taskId}/toggleCompletion`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tasks
     * @name V1TasksReplyCreate
     * @request POST:/api/v1/tasks/{taskId}/reply
     */
    v1TasksReplyCreate: (taskId: string, data: CreateTaskReplyRequest, params: RequestParams = {}) =>
      this.request<string, ProblemDetails>({
        path: `/api/v1/tasks/${taskId}/reply`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tasks
     * @name V1TasksRejectCxTaskPartialUpdate
     * @request PATCH:/api/v1/tasks/{taskId}/RejectCXTask
     */
    v1TasksRejectCxTaskPartialUpdate: (taskId: string, data: RejectCXTaskRequest, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/v1/tasks/${taskId}/RejectCXTask`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name V1TicketsList
     * @request GET:/api/v1/tickets
     */
    v1TicketsList: (
      query?: {
        filterValue?: TicketFilterValue
        filterText?: string
        sortField?: string
        sortDirection?: string
        pageSize?: number
        pageNumber?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<GetTicketsResponse, ProblemDetails>({
        path: `/api/v1/tickets`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name V1TicketsCreate
     * @request POST:/api/v1/tickets
     */
    v1TicketsCreate: (data: CreateTicketRequest, params: RequestParams = {}) =>
      this.request<CreateTicketResponse, ProblemDetails>({
        path: `/api/v1/tickets`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name V1TicketsStatusPartialUpdate
     * @request PATCH:/api/v1/tickets/{ticketId}/status
     */
    v1TicketsStatusPartialUpdate: (ticketId: string, data: UpdateTicketStatusViewModel, params: RequestParams = {}) =>
      this.request<UpdateTicketStatusViewModel[], ProblemDetails>({
        path: `/api/v1/tickets/${ticketId}/status`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name V1TicketsPrioritiesList
     * @request GET:/api/v1/tickets/priorities
     */
    v1TicketsPrioritiesList: (params: RequestParams = {}) =>
      this.request<TicketPriorityViewModel[], ProblemDetails>({
        path: `/api/v1/tickets/priorities`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name V1TicketsStatusesList
     * @request GET:/api/v1/tickets/statuses
     */
    v1TicketsStatusesList: (params: RequestParams = {}) =>
      this.request<TicketStatusViewModel[], ProblemDetails>({
        path: `/api/v1/tickets/statuses`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name V1TicketsCategoriesList
     * @request GET:/api/v1/tickets/categories
     */
    v1TicketsCategoriesList: (query?: {parentTicketCategoryId?: string}, params: RequestParams = {}) =>
      this.request<GetTicketCategoriesResponse, ProblemDetails>({
        path: `/api/v1/tickets/categories`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name V1TicketsTogglePinPartialUpdate
     * @request PATCH:/api/v1/tickets/{ticketId}/togglePin
     */
    v1TicketsTogglePinPartialUpdate: (ticketId: string, params: RequestParams = {}) =>
      this.request<any, ProblemDetails>({
        path: `/api/v1/tickets/${ticketId}/togglePin`,
        method: 'PATCH',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name V1TicketsDetail
     * @request GET:/api/v1/tickets/{ticketId}
     */
    v1TicketsDetail: (ticketId: string, params: RequestParams = {}) =>
      this.request<TicketDetailViewModel, ProblemDetails>({
        path: `/api/v1/tickets/${ticketId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name V1TicketsDelete
     * @request DELETE:/api/v1/tickets/{ticketId}
     */
    v1TicketsDelete: (ticketId: string, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/v1/tickets/${ticketId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name V1TicketsReplyCreate
     * @request POST:/api/v1/tickets/{ticketId}/reply
     */
    v1TicketsReplyCreate: (ticketId: string, data: CreateTicketReplyRequest, params: RequestParams = {}) =>
      this.request<CreateTicketReplyResponse, ProblemDetails>({
        path: `/api/v1/tickets/${ticketId}/reply`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name V1TicketsToggleReadPartialUpdate
     * @request PATCH:/api/v1/tickets/{ticketId}/toggleRead
     */
    v1TicketsToggleReadPartialUpdate: (ticketId: string, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/v1/tickets/${ticketId}/toggleRead`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tickets
     * @name V1TicketsReAssignPartialUpdate
     * @request PATCH:/api/v1/tickets/{ticketId}/reAssign
     */
    v1TicketsReAssignPartialUpdate: (ticketId: string, data: ReAssignTicketRequest, params: RequestParams = {}) =>
      this.request<CreateTicketReplyResponse, ProblemDetails>({
        path: `/api/v1/tickets/${ticketId}/reAssign`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name V1UsersUserTypesList
     * @request GET:/api/v1/users/userTypes
     */
    v1UsersUserTypesList: (params: RequestParams = {}) =>
      this.request<GetUserTypesResponse, ProblemDetails>({
        path: `/api/v1/users/userTypes`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name V1UsersRolesList
     * @request GET:/api/v1/users/roles
     */
    v1UsersRolesList: (params: RequestParams = {}) =>
      this.request<GetUserRolesResponse, ProblemDetails>({
        path: `/api/v1/users/roles`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name V1UsersUserCategoriesUpdate
     * @request PUT:/api/v1/users/userCategories
     */
    v1UsersUserCategoriesUpdate: (data: AssignUserCategoriesRequest, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/v1/users/userCategories`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Version
     * @name V1VersionList
     * @request GET:/api/v1/version
     */
    v1VersionList: (params: RequestParams = {}) =>
      this.request<VersionResponse, any>({
        path: `/api/v1/version`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  weatherForecast = {
    /**
     * No description
     *
     * @tags WeatherForecast
     * @name GetWeatherForecast
     * @request GET:/WeatherForecast
     */
    getWeatherForecast: (query: {'api-version': string}, params: RequestParams = {}) =>
      this.request<WeatherForecast[], any>({
        path: `/WeatherForecast`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
}
