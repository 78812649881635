import React from "react"
import {GroupDetailResponse} from "../data/api/APITypes"
import {Checkbox, Select} from "antd"
import {RecipientsStore} from "../data/store/RecipientsStore"

type RecipientsGroupSelectState = {
  value: any
  isSelectAllRecipients: boolean
  groups: Array<GroupDetailResponse>
  groupMap: Map<string, string>
  selectedGroups: any
  selectionState: any
}
type RecipientsGroupSelectProps = {
  onChange?: any
  value?: any
}
export class RecipientsGroupSelect extends React.Component<RecipientsGroupSelectProps, RecipientsGroupSelectState> {
  static getDerivedStateFromProps(props: RecipientsGroupSelectProps, state: RecipientsGroupSelectState): RecipientsGroupSelectState | null | undefined {
    if (props.value === undefined || props.value === null) {
      return {
        value: undefined,
        isSelectAllRecipients: false,
        groups: state.groups,
        groupMap: state.groupMap,
        selectedGroups: [],
        selectionState: []
      }
    }

    return null
  }

  constructor(props: RecipientsGroupSelectProps) {
    super(props)
    this.state = {
      value: undefined,
      isSelectAllRecipients: false,
      groups: [],
      groupMap: new Map(),
      selectedGroups: [],
      selectionState: []
    }
    this.fetchGroups()
  }

  triggerChange = (changedValue: any) => {
    // Should provide an event to pass value to Form.
    const onChange = this.props.onChange

    if (onChange) {
      onChange(changedValue)
    }
  }
  toggleSelectAll = (e: any) => {
    if (e.target.checked) {
      let groups = this.state.groups.map(group => {
        return group.recipientId
      })
      this.setState({
        isSelectAllRecipients: true,
        value: groups
      })
      this.triggerChange(groups)
    } else {
      this.setState({
        value: this.state.selectedGroups,
        isSelectAllRecipients: false
      })
      this.triggerChange(this.state.selectedGroups)
    }
  }
  recipientGroupsOption = () => {
    if (this.state.groups != null) {
      return (
        /*//$FlowFixMe*/
        this.state.groups.map(group => <Select.Option key={group.recipientId} value={group.recipientDescription}>{group.recipientDescription}</Select.Option>)
      )
    } else {
      return undefined
    }
  }

  fetchGroups() {
    RecipientsStore.getRecipientGroups().then(groups => {
      let groupMap = new Map(groups.map(group => {
        return [group.recipientDescription, group.recipientId]
      }))
      this.setState({
        groups: groups,
        groupMap: groupMap
      })
    })
  }

  updateSelectedGroups = (e: Array<string>) => {
    let groups = e.map(groupDescription => {
      return this.state.groupMap.get(groupDescription)
    })

    if (!this.state.isSelectAllRecipients) {
      this.setState({
        selectionState: e,
        value: groups,
        selectedGroups: groups
      })
    }

    this.triggerChange(groups)
  }

  render() {
    return <div>
        <Checkbox checked={this.state.value != null && this.state.isSelectAllRecipients} onChange={this.toggleSelectAll}>Select All Groups</Checkbox>
        <Select disabled={this.state.isSelectAllRecipients} onChange={this.updateSelectedGroups} allowClear={true} mode="multiple" placeholder="" value={this.state.selectionState} style={{
        width: '100%',
        border: "1px solid #d9d9d9"
      }}>
          {this.recipientGroupsOption()}
        </Select>
      </div>
  }

}
