import React, {useCallback, useContext, useEffect, useState} from 'react'
import {Modal} from 'antd'
import {
  BodyText,
  BodyTextBold,
  ErrorText,
  ModalCancelButton,
  ModalConfirmButton,
  ModalHeading,
  StyledInput,
  VerticalFlexContainer,
} from './CXStyleSheet'
import {OptionOutsideSelect} from './OptionOutsideSelect'
import {useForm} from 'react-hook-form'
import {CXContext, CXContextType} from '../../../context/CXContext'
import {
  GetAllIssuesInFocusArea,
  IssuesToStrings,
} from '../../../util/CXDataUtil'
import Select, {MultiValue, SingleValue} from 'react-select'
import {OptionValue, RepeatType} from '../../../data/api/APITypes'
import {InputActionMeta} from 'react-select/dist/declarations/src/types'

interface CXModalAddElementProps {
  subcategoryId: string
  subcategoryName: string
  show: boolean
  isRepeat: boolean
  onClose?: () => void
}

export const CXModalAddElement: React.FC<CXModalAddElementProps> = props => {
  const {subcategoryId, subcategoryName, show, isRepeat, onClose} = props
  const [visible, setVisible] = useState(false)
  const [isSelected, setIsSelected] = useState<MultiValue<OptionValue>>([])
  const [inputValue, setInputValue] = useState('')
  const cxContext = useContext<CXContextType>(CXContext)

  const {
    handleSubmit,
    register,
    formState: {errors},
    clearErrors,
    setValue,
    reset,
  } = useForm()

  const issues = IssuesToStrings(
    GetAllIssuesInFocusArea(cxContext.activeFocusArea)
  ).map(issue => {
    return {
      value: issue,
      label: issue,
    }
  })

  const repeatElementOptions = Object.keys(RepeatType).map(r => {
    return {
      value: r.toString(),
      label: r.toString(),
    }
  })

  const setValueHandler = (name: string, values: any) => {
    setValue(name, values)
    clearErrors(name)
  }

  const handleSelectChange = useCallback(
    (name: string, values: any) => {
      const newValues = [...isSelected, ...values]
      setIsSelected(newValues)
      setValueHandler(name, newValues)
      setInputValue('')
    },
    [isSelected, setValueHandler]
  )

  const onSubmit = (data: any) => {
    const issues = isSelected.map((item: any) => item.label)
    cxContext.api.createOrUpdateElement(
      subcategoryId,
      data.elementInput,
      issues,
      undefined,
      isRepeat && data.elementInput
    )
    handleCancel()
  }

  if (errors.issue) {
    console.log(errors.issue)
  }

  if (errors.elementInput) {
    console.log(errors.elementInput)
  }

  const handleCancel = () => {
    onClose && onClose()
    setIsSelected([])
    setInputValue('')
    reset({elementInput: isRepeat ? '' : subcategoryName, issue: []})
  }

  const renderModalFooter = () => {
    return (
      <div>
        <span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <ModalCancelButton onClick={handleCancel}>Cancel</ModalCancelButton>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalConfirmButton type="submit">Done</ModalConfirmButton>
            </form>
          </div>
        </span>
      </div>
    )
  }

  useEffect(() => {
    return setVisible(show)
  }, [show])

  useEffect(() => {
    reset({elementInput: isRepeat ? '' : subcategoryName})
  }, [subcategoryName, isRepeat])

  return (
    <Modal visible={visible} centered footer={renderModalFooter()}>
      <ModalHeading>Add Element</ModalHeading>
      <BodyText>Element Name</BodyText>
      {!isRepeat && (
        <StyledInput
          {...register('elementInput', {
            required: 'Enter Element Name.',
          })}
          style={{marginTop: 5, height: 42}}
          name="elementInput"
          id="elementInput"
          type="text"
          placeholder={'E.g. Window 1'}
        />
      )}

      {isRepeat && (
        <Select
          {...register('elementInput', {
            required: 'Select an Element Name.',
          })}
          onChange={(value: SingleValue<OptionValue>) =>
            setValueHandler('elementInput', value?.value)
          }
          options={repeatElementOptions}
        />
      )}

      {errors.elementInput && (
        <ErrorText>{errors.elementInput.message}</ErrorText>
      )}

      <VerticalFlexContainer>
        <BodyTextBold style={{marginTop: 20}}>Add Issues</BodyTextBold>
        <BodyText style={{marginTop: 5}}>Issue Name</BodyText>
      </VerticalFlexContainer>

      <OptionOutsideSelect
        {...register('issue', {
          required: 'Select at least 1 issue.',
        })}
        isMulti
        onChange={(value: MultiValue<OptionValue>) => {
          console.log(value)
          handleSelectChange('issue', value)
        }}
        options={issues}
        components={{DropdownIndicator: null}}
        value={isSelected}
        isClearable={false}
        placeholder={'E.g. VM Alignment'}
        onInputChange={(newValue: string, actionMeta: InputActionMeta) => {
          if (actionMeta.action === 'input-change') {
            setInputValue(newValue)
          }
        }}
        inputValue={inputValue}
      />
      {errors.issue && <ErrorText>{errors.issue.message}</ErrorText>}
    </Modal>
  )
}
