import React, {useEffect, useState} from "react"
import {CheckImageContainer} from "../feature/cx/library/CXStyleSheet"
import greyCheck from "../img/check-grey.png"
import emptyCheck from "../img/checkEmpty.png"

interface RenderMediaIconProps {
    initialState: boolean
    onToggled: (value: boolean) => void
    canToggle?: boolean
}

export const RenderMediaIcon: React.FC<RenderMediaIconProps> = (props) => {
    const {initialState, onToggled, canToggle} = props
    const [mediaType, setMediaType] = useState(false)
    const allowToggle = canToggle ?? true

    useEffect(() => {
        setMediaType(initialState)
    }, [initialState])

    return (
        <CheckImageContainer
            style={{marginLeft: 5}}
            onClick={() => {
                if (!allowToggle) return
                onToggled(!mediaType)
                setMediaType(!mediaType)
            }}
            src={mediaType ? (greyCheck) : (emptyCheck)}
            isDisabled={!allowToggle}
        />
    )
}
