import React, {useContext, useState} from "react"
import add from "../../../img/add.png"
import {CXModalAddElement} from "./CXModalAddElement"
import {CXContext} from "../../../context/CXContext"
import {RenderElements} from "../../../components/RenderElements"
import {ImageButton} from "../../../components/ImageButton"

export const CXSplitRight: React.FC = () => {
    const [showElementModal, setShowElementModal] = useState(false)
    const cxContext = useContext(CXContext)
    const activeSubcategory = cxContext.activeSubcategory
    const currentTemplate = cxContext.currentTemplate !== null

    const canAddElementToSubcategory = (
        (activeSubcategory?.isRepeat === false ||
            (activeSubcategory?.isRepeat === true && activeSubcategory.elements!.length < 1)) &&
        !currentTemplate
    )

    return (
        <>
            {activeSubcategory != null &&
                activeSubcategory.elements != null &&
                activeSubcategory.elements!!.map((element, index) =>
                    <RenderElements key={index} element={element} hasCurrentTemplate={currentTemplate}
                                    isRepeat={activeSubcategory?.isRepeat!}
                                    subcategoryId={activeSubcategory.id!}/>)}
            {canAddElementToSubcategory && <div style={{padding: '20px 0'}}>
                <ImageButton
                  text={'Add Element'}
                  imagePosition={'left'}
                  image={{src: add, height: 14, width: 14, alt: 'add-icon'}}
                  onClick={() => setShowElementModal(!showElementModal)}
                />
            </div>}
            <CXModalAddElement
                subcategoryId={activeSubcategory?.id!!}
                subcategoryName={activeSubcategory?.name!}
                show={showElementModal}
                isRepeat={activeSubcategory?.isRepeat!}
                onClose={() => setShowElementModal(false)}
            />
        </>
    )
}
