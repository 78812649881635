
import { useHistory } from "react-router-dom"
import {RecipientsStore} from "../data/store/RecipientsStore"
import {UserStore} from "../data/store/UserStore"

export class LogoutUtil {
    
    static logout() {
        RecipientsStore.clear()
        UserStore.clear()

        
    }
}
