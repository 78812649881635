import React, {Component} from "react"
import {Button, Layout, Modal, Popconfirm, Spin, Table} from "antd"
import Icon from '@ant-design/icons'
import "../../App.less"
import Moment from "moment"
import {EmpowerTasksAPI} from "../../data/api/EmpowerTasksAPI"
import {CreateTaskForm} from "./CreateTaskForm"
import {TaskDetail} from "./TaskDetail"
import {DateUtil} from "../../util/DateUtil"
import {fetchMoreIfNecessary} from "../../data/api/APIConfig"
import {loginAPIErrorRedirector} from "../../data/api/APIErrorRedirector"
import {AttachmentDescriptor} from "../../components/AttachmentUploader"

const {
    Header,
    Content
} = Layout

type TaskFormData = {
    select: string
    subject: string
    body: string
    priority: string
    // @ts-ignore
    datepicker: Moment
    attachments?: Array<AttachmentDescriptor> | null | undefined
    groupRecipients: Array<string>
    taskId: string
}
type TaskTableData = {
    key: number
    subject: string
    dueDate: string
    taskId: string
    completionPercentage: number
}
type TasksState = {
    dataSource: Array<TaskTableData>
    busy: boolean
    totalTasks: number
    announcementVisible: boolean
    detailVisible: boolean
    authFailed: boolean
    taskDetailId: string
}
type TasksProps = {
    history: any
}

class Tasks extends Component<TasksProps, TasksState> {
    constructor(props: TasksProps) {
        super(props)
        this.state = {
            dataSource: [],
            busy: true,
            announcementVisible: false,
            totalTasks: 0,
            detailVisible: false,
            taskDetailId: "",
            authFailed: false
        }
        this.fetchTasks()
    }

    fetchTasks(index: number = 0, isRefresh: boolean = false) {
        this.setState({
            busy: true
        })
        var promise

        if (isRefresh) {
            promise = EmpowerTasksAPI.refreshTasks(this.state.dataSource.length)
        } else {
            promise = EmpowerTasksAPI.fetchTasks(index)
        }

        promise.catch(error => {
            this.setState({
                authFailed: true
            })
            return loginAPIErrorRedirector(() => {
                return this.props
            }).onError(error)
        }).then(tasks => {
            let datasource: Array<TaskTableData> = tasks.data.sentTasks.map((task, index) => {
                return {
                    key: index,
                    subject: task.subject,
                    dueDate: DateUtil.formatEmpowerDate(task.dueDate),
                    taskId: task.taskId,
                    completionPercentage: task.completionPercentage
                }
            })

            if (datasource != null) {
                if (index > 0 && !isRefresh) {
                    this.setState({
                        totalTasks: tasks.data.totalItemCount,
                        dataSource: this.state.dataSource.concat(datasource),
                        busy: false
                    })
                } else {
                    this.setState({
                        totalTasks: tasks.data.totalItemCount,
                        dataSource: datasource,
                        busy: false
                    })
                }
            } else {
                this.setState({
                    busy: false
                })
            }
        })
    }

    deleteTask(taskId: string) {
        this.setState({
            busy: true,
            announcementVisible: false,
            detailVisible: false
        })
        EmpowerTasksAPI.deleteTasks(taskId).catch(error => {
            this.setState({
                authFailed: true
            })
            return loginAPIErrorRedirector(() => {
                return this.props
            }).onError(error)
        }).then(() => {
            this.fetchTasks(0, true)
        })
    }

    createToDoModal = () => {
        this.setState({
            announcementVisible: true
        })
    }
    handleTodoModalOk = (e: any) => {
        this.setState({
            announcementVisible: false
        })
    }
    showTaskDetailModal = (record: TaskFormData) => {
        this.setState({
            detailVisible: true,
            taskDetailId: record.taskId
        })
    }
    closeTaskDetailModal = () => {
        this.setState({
            detailVisible: false
        })
    }
    addTask = (formData: TaskFormData) => {
        this.setState({
            announcementVisible: false,
            busy: true
        })
        var attachments = undefined

        if (formData.attachments != null) {
            attachments = formData.attachments.map(attachment => {
                return attachment.id
            })
        }

        EmpowerTasksAPI.createNewTask({
            taskPriorityId: Number(formData.priority),
            taskStatusId: 1,
            subject: formData.subject,
            body: formData.body,
            dueDate: formData.datepicker.format("YYYY-MM-DDTHH:mm:ss"),
            // @ts-ignore
            attachmentIds: attachments,
            recipients: formData.groupRecipients.map(value => {
                return {
                    recipientId: value,
                    recipientType: "2"
                }
            })
        }).catch(error => {
            this.setState({
                authFailed: true
            })
            return loginAPIErrorRedirector(() => {
                return this.props
            }).onError(error)
        }).then(() => {
            this.fetchTasks(0, true)
        })
    }
    paginationConfig = () => {
        return {
            pageSize: 25,
            total: this.state.totalTasks,
            onChange: (page: number, pageSize: number) => {
                fetchMoreIfNecessary(page, pageSize, this.state.dataSource.length, this.state.totalTasks, () => {
                    this.fetchTasks(this.state.dataSource.length)
                })
            }
        }
    }

    render() {
        const {
            dataSource
        } = this.state
        const columns = this.columnConfig().map(col => {
            return {
                ...col
            }
        })
        return <Layout>
            <Layout>
                <Header className="header1">
                    <div>
                        <h1>To-Dos</h1>
                    </div>
                    <div className="button-container">
                        <Button className="btn-Announcement" onClick={this.createToDoModal}>
                            <Icon type="plus"/>
                            New To-Dos
                        </Button>

                        <Modal title={<span style={{
                            textAlign: "center"
                        }}>New To-Dos</span>} visible={this.state.announcementVisible} onCancel={this.handleTodoModalOk}
                               className="announcement-modal">
                            <CreateTaskForm onFormSubmit={this.addTask}/>
                        </Modal>

                        <Modal visible={this.state.detailVisible} onOk={this.closeTaskDetailModal} bodyStyle={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            paddingRight: 0
                        }} onCancel={this.closeTaskDetailModal} footer={[<div key="dialogFooter" style={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                            <p className="date" style={{
                                position: "relative",
                                top: "7px",
                                marginBottom: "0px"
                            }}/>
                            <div className="rows">
                                <div key="delete-btn-row" className="row">
                                    <Popconfirm className="btn-delete-record"
                                        // @ts-ignore
                                                size="default"
                                                title="Are you sure delete this to-dos?" onConfirm={() => {
                                        this.deleteTask(this.state.taskDetailId)
                                    }} onCancel={() => {
                                    }} okText="Yes" cancelText="No">
                                        <Button key="deleteButton" style={{
                                            marginTop: "8px"
                                        }} className="btn-delete-record" href="#">
                                            Delete
                                        </Button>
                                    </Popconfirm>
                                </div>
                                <div key="back-btn-row" className="row" style={{
                                    marginLeft: 10
                                }}>
                                    <Button key="back" onClick={this.closeTaskDetailModal}>
                                        Back
                                    </Button>
                                </div>
                            </div>
                        </div>]}>
                            {this.state.detailVisible && <TaskDetail taskId={this.state.taskDetailId}/>}
                        </Modal>
                    </div>
                </Header>

                <Spin style={{
                    alignSelf: "center",
                    minHeight: 400
                }} spinning={this.state.busy} size="large">
                    <div className="content-container">
                        {this.state.dataSource != null && this.state.dataSource.length > 0 &&
                        <Content className="table-Todo-container">
                            <div className="table-ToDo">
                                <Table bordered
                                    // @ts-ignore
                                       dataSource={dataSource}
                                    // @ts-ignore
                                       columns={columns}
                                    // @ts-ignore
                                       pagination={this.paginationConfig()}/>
                            </div>
                        </Content>}
                    </div>
                </Spin>
            </Layout>
        </Layout>
    }

    columnConfig = () => {
        return [{
            title: "To-Dos",
            dataIndex: "subject",
            sorter: (a: any, b: any) => a.subject.length - b.subject.length,
            onCell: (record: TaskFormData) => {
                return {
                    onClick: () => {
                        this.showTaskDetailModal(record)
                    }
                }
            }
        }, {
            title: "End Date",
            dataIndex: "dueDate",
            filterMultiple: false,
            onCell: (record: TaskFormData) => {
                return {
                    onClick: () => {
                        this.showTaskDetailModal(record)
                    }
                }
            }
        }, {
            title: "Percentage Completed",
            onCell: (record: TaskFormData) => {
                return {
                    onClick: () => {
                        this.showTaskDetailModal(record)
                    }
                }
            },
            render: (text: string, record: TaskTableData) => {
                return <div>{record.completionPercentage} %</div>
            }
        }, {
            title: "",
            render: (text: string, record: TaskTableData) => {
                return <div>
                    <Popconfirm className="btn-delete-record"
                        // @ts-ignore
                                size="default" title="Are you sure delete this to-dos?"
                                onConfirm={() => {
                                    this.deleteTask(record.taskId)
                                }} onCancel={() => {
                    }} okText="Yes" cancelText="No">
                        <Button style={{
                            marginTop: "8px"
                        }} className="btn-delete-record" href="#">
                            Delete
                        </Button>
                    </Popconfirm>
                </div>
            }
        }]
    }
}

export default Tasks
