import React, {useContext, useState} from 'react'
import {FocusAreaData} from '../../../data/models/CXLibraryModels'
import {CXContext} from '../../../context/CXContext'
import {
  ButtonGroupHorizontalWrapper,
  CXContainer,
  DefaultButton,
} from './CXStyleSheet'
import {CXModalAddFocusArea} from './CXModalAddFocusArea'
import add from '../../../img/add.png'
import {ImageButton} from '../../../components/ImageButton'

interface CXLibraryTopBarProps {
  history: any
}

const CXLibraryTopBar: React.FC<CXLibraryTopBarProps> = props => {
  const [showModal, setShowModal] = useState(false)

  const cxContext = useContext(CXContext)
  const hasTemplate = cxContext.currentTemplate !== null

  const focusData: Array<FocusAreaData> = cxContext.availableFocusAreas
  const activeFocusArea = cxContext.activeFocusArea

  const updateCurrentId = (id: string) => {
    cxContext.setActiveFocusArea(id)
  }

  const button = (focusArea: FocusAreaData) => {
    return (
      <DefaultButton
        key={focusArea.id}
        style={
          focusArea.id === activeFocusArea?.id
            ? {
                backgroundColor: '#DA2131',
                borderColor: '#DA2131',
                color: '#ffffff',
              }
            : {}
        }
        onClick={() => {
          updateCurrentId(focusArea.id)
        }}>
        {focusArea.abbreviation}
      </DefaultButton>
    )
  }

  return (
    <CXContainer>
      <ButtonGroupHorizontalWrapper>
        {focusData.map(fa => button(fa))}
        {!hasTemplate && (
          <ImageButton
            text={'Add Focus Area'}
            imagePosition={'left'}
            hideText={focusData.length > 0}
            image={{src: add, width: 15, height: 15, alt: 'add focus area'}}
            onClick={() => setShowModal(!showModal)}
          />
        )}
      </ButtonGroupHorizontalWrapper>
      <CXModalAddFocusArea
        show={showModal}
        onClose={() => setShowModal(false)}
      />
    </CXContainer>
  )
}

export default CXLibraryTopBar
