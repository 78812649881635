import React, {Context, useContext, useEffect, useState} from "react"
import {CreateTemplateIssue, LibraryFocusArea, LibraryIssue} from "../data/api/generated/EmpowerSwaggerApi"
import {CXContext, CXContextType} from "./CXContext"
import {GetAllIssuesInFocusArea} from "../util/CXDataUtil"
import {pretty} from "../util/Log"
import {FocusAreaData} from "../data/models/CXLibraryModels"

export type CXSelectionContextType = {
    toggleCategory: (catId: string, setSelected: boolean) => void
    toggleSubcategory: (subcatId: string, setSelected: boolean) => void
    toggleElement: (elementId: string, setSelected: boolean, requiresImage: boolean, requiresVideo: boolean, requiresComment: boolean) => void
    toggleIssue: (issueID: string, setSelected: boolean, requiresImage: boolean, requiresVideo: boolean, requiresComment: boolean) => void
    toggleIssues: (issueID: string[], setSelected: boolean, requiresImage: boolean, requiresVideo: boolean, requiresComment: boolean) => void
    toggleFocusArea: (focusArea: LibraryFocusArea, isSelected: boolean) => void
    isSelectedIssue: (issue: LibraryIssue) => boolean
    isSelectedSubcategory: (subCatId: string) => boolean
    isSelectedElement: (elementId: string) => boolean,
    elementRequiresComment: (elementId: string) => boolean,
    elementRequiresVideo: (elementId: string) => boolean,
    elementRequiresImage: (elementId: string) => boolean,
    templateIssues: () => CreateTemplateIssue[]
    selectedFocusAreas: LibraryFocusArea[]
    setAutoSectionEnabled: (focusAreaName: string) => void
    hasAllSelected: (focusArea: LibraryFocusArea) => boolean
}

export const CXSelectionContext: Context<CXSelectionContextType> = React.createContext<CXSelectionContextType>({
    toggleCategory: () => {
    },
    toggleSubcategory: () => {
    },
    toggleElement: () => {
    },
    toggleIssue: () => {
    },
    toggleIssues: () => {
    },
    toggleFocusArea: () => {
    },
    isSelectedIssue: () => false,
    isSelectedSubcategory: () => false,
    isSelectedElement: () => false,
    elementRequiresComment: (elementId: string) => false,
    elementRequiresVideo: (elementId: string) => false,
    elementRequiresImage: (elementId: string) => false,
    templateIssues: () => [],
    selectedFocusAreas: [],
    setAutoSectionEnabled: () => {
    },
    hasAllSelected(focusArea: LibraryFocusArea): boolean {
        return false
    }
})

interface IssueSelection {
    issue: LibraryIssue
    requiresImage: boolean
    requiresVideo: boolean
    requiresComment: boolean
}

enum MEDIA_TYPE {
    VIDEO, IMAGE, COMMENT
}

export const CXSelectionContextProvider: React.FC = (props) => {
    const cxContext: CXContextType = useContext(CXContext)
    const [selectedIssues, setSelectedIssues] = useState<Map<string, IssueSelection>>(new Map<string, IssueSelection>())
    const [currentFocusAreaId, setCurrentFocusAreaId] = useState<string | undefined>()
    const [selectedFocusAreas, setSelectedFocusAreas] = useState<LibraryFocusArea[]>([])
    const [currentFocusArea, setCurrenFocusArea] = useState(cxContext.activeFocusArea)
    const [hasAllSelected, setHasAllSelected] = useState<string[]>([])

    const selectedIds = Array.from(selectedIssues.keys())
    const selectedIssueSelection = Array.from(selectedIssues.values())

    const storedSelectedIssues = localStorage.getItem('selectedIssues')
    const storedAutoSelectionEnabled = localStorage.getItem('autoSelectionEnabled') ? JSON.parse(localStorage.getItem('autoSelectionEnabled')!) : []
    const selectedMediaEvidence = JSON.parse(String(localStorage.getItem('selectedMediaEvidence')))

    const storedIssuesMap = new Map<string, IssueSelection>(selectedMediaEvidence)

    const selectIssues = (
        issues: Array<LibraryIssue>,
        requiresImage: boolean,
        requiresVideo: boolean,
        requiresComment: boolean,
        updateEvidenceMedia: boolean
    ) => {
        const newIssues: Array<IssueSelection> = issues.map(issue => {
            return {
                issue: issue,
                requiresImage: requiresImage,
                requiresVideo: requiresVideo,
                requiresComment: requiresComment
            }
        })

        console.log(`Updated issues:\n${pretty(newIssues)}`)
        console.log(`Current issues:\n${selectedIssues.size}`)
        console.log(`Current issues keys:\n${pretty(selectedIssueSelection)}`)

        const newIssuesMap = new Map(selectedIssues)
        newIssues.forEach((issue) => {
            newIssuesMap.set(issue.issue.id!!, issue)
        })

        console.log(`New selection map:\n${newIssuesMap.size}`)

        localStorage.setItem('selectedIssues', JSON.stringify(Array.from(newIssuesMap)))

        const newStoredIssuesMap = new Map(storedIssuesMap)
        newIssues.forEach((issue) => {
            newStoredIssuesMap.set(issue.issue.id!!, issue)
        })

        updateEvidenceMedia && localStorage.setItem('selectedMediaEvidence', JSON.stringify(Array.from(newStoredIssuesMap)))

        setSelectedIssues(newIssuesMap)
    }

    const deselectIssues = (issues: Array<LibraryIssue>) => {
        const newIssueMaps = new Map(selectedIssues)
        issues.forEach(issue => newIssueMaps.delete(issue.id!!))
        localStorage.setItem('selectedIssues', JSON.stringify(Array.from(newIssueMaps)))
        setSelectedIssues(newIssueMaps)
    }

    const toggleIssues = (
        issues: Array<LibraryIssue>,
        isSelected: boolean,
        requiresImage: boolean,
        requiresVideo: boolean,
        requiresComment: boolean,
        updateEvidenceMedia: boolean) => {
        if (isSelected) {
            selectIssues(issues, requiresImage, requiresVideo, requiresComment, updateEvidenceMedia)
        } else {
            deselectIssues(issues)
        }
    }

    const issuesForCategory = (catId: string): Array<LibraryIssue> => {
        return currentFocusArea?.categories!.find((cat) => cat.id === catId)
                ?.subcategories
                ?.flat()
                ?.map(subcat => subcat?.elements)
                ?.flat()
                ?.map(element => element?.issues)
                ?.flat()
                ?.filter(issue => issue) as Array<LibraryIssue>
            ?? []
    }

    const issuesForSubcategory = (subCatId: string): Array<LibraryIssue> => {
        const subcats = currentFocusArea?.categories?.map(cat => cat.subcategories)
            ?.flat()

        return subcats?.find(subcat => subcat?.id === subCatId)?.elements
            ?.flat()
            ?.map(element => element?.issues)
            ?.flat()
            ?.filter(issue => issue) as Array<LibraryIssue> ?? []
    }

    const issuesForElement = (elementId: string): Array<LibraryIssue> => {
        return currentFocusArea?.categories?.map(cat => cat.subcategories)?.flat()
            ?.map(subcat => subcat?.elements)
            ?.flat()
            ?.find(element => element?.id === elementId)
            ?.issues
            ?.filter(issue => issue) as Array<LibraryIssue> ?? []
    }

    const toggleCategory = (catId: string, isSelected: boolean) => {
        const issues = issuesForCategory(catId)
        toggleIssues(issues, isSelected, isSelected, isSelected, isSelected, false)
    }

    const toggleSubcategory = (subCatId: string, isSelected: boolean) => {
        const issues = issuesForSubcategory(subCatId)
        toggleIssues(issues, isSelected, isSelected, isSelected, isSelected, false)
    }

    const toggleElement = (elementId: string, isSelected: boolean, requiresImage: boolean, requiresVideo: boolean, requiresComment: boolean) => {
        const issues = issuesForElement(elementId)
        toggleIssues(issues, isSelected, requiresImage, requiresVideo, requiresComment, false)
    }

    const getIssuesForFocusArea = (focusArea: LibraryFocusArea) => {
        return focusArea.categories?.flat()
            .map(c => c.subcategories).flat()
            .map(s => s?.elements).flat()
            .map(e => e?.issues).flat() as Array<LibraryIssue>
    }

    const toggleFocusArea = (focusArea: LibraryFocusArea, isSelected: boolean) => {
        const issues = getIssuesForFocusArea(focusArea)

        toggleIssues(issues, isSelected, isSelected, isSelected, isSelected, false)
        setSelectedFocusAreas(prevState => {
            if (!isSelected) {
                return prevState.filter(p => p.id !== focusArea.id)
            }
            return [...prevState, focusArea]
        })
        setHasAllSelected(prevState => {
            if (!isSelected) {
                return prevState.filter(p => p !== focusArea.name)
            }
            return [...prevState, focusArea.name!]
        })
    }

    const hasAllSelectedHandler = (focusArea: LibraryFocusArea) => {
        const focusAreaName = focusArea.name!
        return hasAllSelected.includes(focusAreaName)
    }

    const handleAllSelectedForFocusArea = (selectedIssues: { [key: string]: LibraryIssue[] }) => {
        if (!currentFocusArea) return
        const issues = getIssuesForFocusArea(currentFocusArea)
        const {name} = currentFocusArea
        if (issues.length === 0) return
        setHasAllSelected(prevState => {
            const selectedIssue = selectedIssues[name!]
            if ((issues.length === selectedIssue?.length && issues.length !== 0) && !prevState.includes(name!)) {
                return [...prevState, name!]
            } else if (issues.length !== selectedIssue?.length) {
                return prevState.filter(p => p !== currentFocusArea.name)
            }
            return prevState
        })
    }

    const toggleIssue = (
        issueId: string,
        isSelected: boolean,
        requiresImage: boolean,
        requiresVideo: boolean,
        requiresComment: boolean) => {
        const issue = GetAllIssuesInFocusArea(currentFocusArea).find(issue => issue.id === issueId)
        if (issue) {
            console.log(`Toggling Issue: ${
                {
                    issueId: issueId,
                    isSelected: isSelected,
                    requiresImage: requiresImage,
                    requiresVideo: requiresVideo,
                    requiresComment: requiresComment,
                }
            }`)
            toggleIssues([issue], isSelected, requiresImage, requiresVideo, requiresComment, true)
        }
    }

    const toggleIssuesArray = (
        issueId: string[],
        isSelected: boolean,
        requiresImage: boolean,
        requiresVideo: boolean,
        requiresComment: boolean) => {
        const issues = GetAllIssuesInFocusArea(currentFocusArea).filter(issue => issueId.indexOf(issue.id ?? "") > -1)

        toggleIssues(issues, isSelected, requiresImage, requiresVideo, requiresComment, true)
    }

    const isSelectedIssue = (issue: LibraryIssue): boolean => {
        return selectedIds.find(id => id === issue.id) !== undefined
    }

    const isSelectedElement = (elementId: string): boolean => {
        const issues = issuesForElement(elementId)

        for (let i = 0; i < issues.length; i++) {
            if (selectedIds.indexOf(issues[i].id!!) > -1) {
                return true
            }
        }
        return false
    }

    const isSelectedSubcategory = (subcatId: string): boolean => {
        const issues = issuesForSubcategory(subcatId)

        for (let i = 0; i < issues.length; i++) {
            const contains = selectedIds.indexOf(issues[i].id!!) > -1
            if (contains) {
                return true
            }
        }
        return false
    }

    const elementHasMedia = (elementId: string, mediaType: MEDIA_TYPE): boolean => {
        const issues = issuesForElement(elementId)
        if (issues.length > 0) {
            const issue = issues[0]
            const selectedIssue = storedIssuesMap.size > 0 ? storedIssuesMap.get(issue.id!) : selectedIssues.get(issue.id!)
            if (selectedIssue) {
                switch (mediaType) {
                    case MEDIA_TYPE.COMMENT:
                        return selectedIssue.requiresComment
                    case MEDIA_TYPE.IMAGE:
                        return selectedIssue.requiresImage
                    case MEDIA_TYPE.VIDEO:
                        return selectedIssue.requiresVideo
                }
            }
        }

        return false
    }

    const templateIssues = (): CreateTemplateIssue[] => {
        return Array.from(selectedIssues.values())
            .map(value => {
                const storedIssuesSelection = Array.from((storedIssuesMap.values()))
                const storedIssueEvidence = storedIssuesSelection.filter(s => s.issue.id === value.issue.id)
                return {
                    issueId: value.issue.id,
                    requiresImage: storedIssueEvidence.length > 0 ? storedIssueEvidence[0].requiresImage : value.requiresImage,
                    requiresVideo: storedIssueEvidence.length > 0 ? storedIssueEvidence[0].requiresVideo : value.requiresVideo,
                    requiresComment: storedIssueEvidence.length > 0 ? storedIssueEvidence[0].requiresComment : value.requiresComment,
                }
            }) ?? []
    }

    const setAutoSelectionHandler = (focusAreaName: string) => {
        if (!focusAreaName) return
        const tempStoredAutoSelection = [...storedAutoSelectionEnabled]
        if (!tempStoredAutoSelection.includes(focusAreaName)) {
            tempStoredAutoSelection.push(focusAreaName)
        }
        localStorage.setItem('autoSelectionEnabled', JSON.stringify(tempStoredAutoSelection))
    }

    useEffect(() => {
        if (cxContext.activeFocusArea) {
            setCurrentFocusAreaId(cxContext.activeFocusArea.id)
            setCurrenFocusArea(cxContext.activeFocusArea)
            setAutoSelectionHandler(currentFocusArea?.name!)

            if (cxContext.activeFocusArea.id !== currentFocusAreaId) {
                cxContext.setActiveSubcategory(null)
            }
        }
    }, [cxContext, currentFocusAreaId, setCurrenFocusArea])

    useEffect(() => {
        const currentSelectionIds = Array.from(selectedIssues).map(s => s[1].issue.id)
        let focusAreasToSelect: FocusAreaData[] | undefined = []
        let focusAreaSelectedIssues: { [key: string]: LibraryIssue[] } = {}
        cxContext.availableFocusAreas.forEach(a => {
                focusAreaSelectedIssues[a.name] = []
                a.categories.forEach((c, cIndex) => c.subcategories?.forEach(s => s.elements?.forEach(e => {
                    const elementIssues = e.issues?.map(i => i).flat()
                    if (elementIssues && elementIssues.filter(c => currentSelectionIds.includes(c.id)).length > 0) {
                        const tempFocusAreaSelectedIssues = focusAreaSelectedIssues[a.name]
                        if (focusAreaSelectedIssues[a.name] === undefined) {
                            focusAreaSelectedIssues[a.name] = [...elementIssues]
                        } else {
                            focusAreaSelectedIssues[a.name] = [...tempFocusAreaSelectedIssues, ...elementIssues]
                        }
                        const tempSelectedFocusAreas = focusAreasToSelect?.map(f => f.id)
                        if (!tempSelectedFocusAreas?.includes(a.id)) {
                            return focusAreasToSelect?.push(a)
                        }
                    }
                })))
            }
        )
        setSelectedFocusAreas(focusAreasToSelect)
        handleAllSelectedForFocusArea(focusAreaSelectedIssues)
    }, [selectedIssues, cxContext.availableFocusAreas])

    useEffect(() => {
        if (!currentFocusArea) return
        if (storedAutoSelectionEnabled.includes(currentFocusArea.name!)) return
        const issues = currentFocusArea?.categories?.map(cat => cat.subcategories)?.flat()
            ?.map(subcat => subcat?.elements)
            ?.flat()
            ?.map(issue => issue?.issues)
            ?.flat() as Array<LibraryIssue> ?? []
        if (issues.length === 0) return
        const libraryIssues = selectedIssueSelection.map(s => s.issue).flat()
        const selectionDifference = issues.filter(i => {
            const libraryIssueIds = libraryIssues.map(l => l.id)
            return libraryIssueIds.includes(i.id)
        })
        if (selectionDifference.length === 0) {
            selectIssues(issues, true, true, true, false)
        }
        return setAutoSelectionHandler(currentFocusArea.name!)
    }, [currentFocusArea, selectedIssueSelection, storedAutoSelectionEnabled, setAutoSelectionHandler])

    useEffect(() => {
        if (!currentFocusArea) return
        const issues = currentFocusArea?.categories?.map(cat => cat.subcategories)?.flat()
            ?.map(subcat => subcat?.elements)
            ?.flat()
            ?.map(issue => issue?.issues)
            ?.flat() as Array<LibraryIssue> ?? []
        if (issues.length === 0) return
        if (!storedSelectedIssues) return
        const newIssuesMap = new Map<string, IssueSelection>(JSON.parse(storedSelectedIssues))
        const storedIssues = Array.from(newIssuesMap.values()).map(i => i.issue).flat()
        const selectionDifference = issues.filter(i => {
            const storedIssueIds = storedIssues.map(l => l.id)
            return storedIssueIds.includes(i.id)
        })
        if (selectionDifference.length > 0) {
            setSelectedIssues(newIssuesMap)
        }
    }, [currentFocusArea, storedSelectedIssues])

    const contextValue: CXSelectionContextType = {
        toggleCategory: toggleCategory,
        toggleSubcategory: toggleSubcategory,
        toggleElement: toggleElement,
        toggleIssue: toggleIssue,
        toggleIssues: toggleIssuesArray,
        toggleFocusArea,
        isSelectedIssue: isSelectedIssue,
        isSelectedSubcategory: isSelectedSubcategory,
        isSelectedElement: isSelectedElement,
        elementRequiresComment: (elementId: string) => elementHasMedia(elementId, MEDIA_TYPE.COMMENT),
        elementRequiresImage: (elementId: string) => elementHasMedia(elementId, MEDIA_TYPE.IMAGE),
        elementRequiresVideo: (elementId: string) => elementHasMedia(elementId, MEDIA_TYPE.VIDEO),
        templateIssues: templateIssues,
        selectedFocusAreas,
        setAutoSectionEnabled: setAutoSelectionHandler,
        hasAllSelected: hasAllSelectedHandler,
    }


    return <CXSelectionContext.Provider value={contextValue}>
        {props.children}
    </CXSelectionContext.Provider>

}
