import {BrowserRouter, BrowserRouter as Router} from 'react-router-dom'
import {AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react'
import Container from './components/Container'
import React from 'react'
import {UnAuthenticatedContainer} from './components/UnAuthenticatedContainer'

export const App = () => {
  return (
    <Router>
      <BrowserRouter>
        <Container />
      </BrowserRouter>
    </Router>
  )
}
