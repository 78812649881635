import React, {ReactNode, useContext} from "react"
import {Layout, Popconfirm, Tooltip} from "antd"
import {PoweroffOutlined} from '@ant-design/icons'
import {Link, useHistory} from "react-router-dom"
import "../App.less"
import {LogoutUtil} from "../util/LogoutUtil"
import styled from "styled-components"
import {
    AnnotationIcon,
    CalendarIcon,
    ChartSquareBarIcon,
    CheckCircleIcon,
    KeyIcon,
    LogoutIcon,
    MenuAlt1Icon
} from "@heroicons/react/outline"
import DefaultTheme from "../util/Helper"
import {CXContext} from "../context/CXContext"
import {UserStore} from "../data/store/UserStore"
import {IPublicClientApplication} from "@azure/msal-browser"
import {useMsal} from "@azure/msal-react"

const {
    Sider,
} = Layout

interface MenuItem {
    isActive: boolean
    href: string
    icon: (select: boolean) => ReactNode
    tooltip: string
}

export const NewMenu: React.FC = (props) => {
    const cxContext = useContext(CXContext)
  const history = useHistory();

    const items: Array<MenuItem> = [
        {
            isActive: true,
            tooltip: "HQ Comms",
            href: "/messages",
            icon: (select) => <AnnotationIcon
                // color={select ? "#ffffff" : `${DefaultTheme.color.primaryRed}`}
                color={"#ffffff"}
                style={{
                    fontSize: "25px"
                }}
            />
        },
        {
            isActive: true,
            tooltip: "To-Dos",
            href: "/todos",
            icon: (select) => <CalendarIcon
                // color={select ? "#ffffff" : `${DefaultTheme.color.primaryRed}`}
                color={"#ffffff"}
                style={{
                    fontSize: "25px"
                }}/>
        },
        {
            isActive: true,
            tooltip: "Feedback",
            href: "/tickets",
            icon: (select) => <CheckCircleIcon
                // color={select ? "#ffffff" : `${DefaultTheme.color.primaryRed}`}
                color={"#ffffff"}
                style={{
                    fontSize: "25px"
                }}/>
        },
        {
            isActive: true,
            tooltip: "CX Library",
            href: "/customerexperience",
            icon: (select) => <KeyIcon
                // color={select ? "#ffffff" : `${DefaultTheme.color.primaryRed}`}
                color={"#ffffff"}
                style={{
                    fontSize: "25px"
                }}/>
        },
        {
            isActive: true,
            tooltip: "CX Templates",
            href: "/cxtemplates",
            icon: (select) => <MenuAlt1Icon
                // color={select ? "#ffffff" : `${DefaultTheme.color.primaryRed}`}
                color={"#ffffff"}
                style={{
                    fontSize: "25px"
                }}/>
        },
        {
            isActive: true,
            tooltip: "CX Reports",
            href: "/cxreports",
            icon: (select) => <ChartSquareBarIcon
                // color={select ? "#ffffff" : `${DefaultTheme.color.primaryRed}`}
                color={"#ffffff"}
                style={{
                    fontSize: "25px"
                }}/>
        },
    ]

    const renderMenuItem = (menuItem: MenuItem, index: number) => {
        const selected = window.location.href.indexOf(menuItem.href) > -1
        return (
            <ButtonWrap key={index}>
                <ButtonContainer selected={selected}>
                    <Tooltip title={menuItem.tooltip} placement={"right"}>
                        <Link to={menuItem.href} style={{
                            flex: 1
                        }}>
                            {menuItem.icon(selected)}
                        </Link>
                    </Tooltip>
                </ButtonContainer>
            </ButtonWrap>
        )
    }

  

    const logoutHandler = () => {
        cxContext.setIsLoading(true)
        LogoutUtil.logout()
        history.push('/login');
    }

    if (!UserStore.getAzureToken()) return null

    return <Sider className="side-menu" width="64px" collapsible={false}
                  style={{
                      height: "100%",
                      backgroundColor: "#333333",
                      justifyContent: "center",
                      alignItems: "center",
                  }}>

        <div style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
        }}>
            <div className="logo" style={{
                fontSize: "26px",
                height: "64px",
                backgroundColor: `${DefaultTheme.color.primaryRed}`
            }}>
                <PoweroffOutlined className="power-icon" style={{
                    color: "#ffffff"
                }}/>
            </div>
            {items
                .filter((item) => item.isActive)
                .map((item, index) => renderMenuItem(item, index))}

            <div style={{
                position: "absolute",
                bottom: 25,
                left: 0,
                right: 0
            }}>
                <ButtonWrap>
                    <ButtonContainer>
                        <Popconfirm title={"Are you sure you want to logout"} style={{
                            display: "flex"
                        }} placement={"right"} onConfirm={() => logoutHandler()}>
                            <Tooltip title={"Logout"} placement={"right"}>
                                <div style={{
                                    flex: 1
                                }}>
                                    <LogoutIcon
                                        color={"#ffffff"}
                                        style={{
                                            fontSize: "25px"
                                        }}/>
                                </div>
                            </Tooltip>
                        </Popconfirm>
                    </ButtonContainer>
                </ButtonWrap>
            </div>

        </div>
    </Sider>
}

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px
`

interface ButtonProps {
    selected?: boolean
}

const ButtonContainer = styled.div<ButtonProps>`
  padding: 4px;
  height: 32px;
  width: 32px;
  // @ts-ignore
  background-color: ${props => props.selected ? `${DefaultTheme.color.primaryRed}` : "#333333"};
  display: flex;
  justify-content: center;
  border-radius: 6px;

  :hover {
    background-color: #DA2131;
  }
`
// eslint-disable-next-line
const DividerContainer = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
`
// eslint-disable-next-line
const Divider = styled.div`
  width: 100%;
  height: 2px;
  background: rgba(256, 256, 256, 0.5);
`
// eslint-disable-next-line
const DividerText = styled.div`
  margin-top: 12px;
  width: 100%;
  text-align: center;
`

export default NewMenu
