import styled from "styled-components"
import {Property} from "csstype"
import DefaultTheme from "../../../util/Helper"
import LoadingOverlay from "react-loading-overlay-ts"

//h
export const PageTitle = styled.h1`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-top: 10px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
export const ModalHeading = styled.h1`
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 20px;
`
export const FocusAreaHeading = styled.h2`
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
`
export const SubcategoryHeading = styled.h3`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 0;
`
export const RadioHeading = styled.h4`
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
`
export const GreyText = styled.h5`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #999999;
`
export const GreyTextModal = styled.h5`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #4D4D4D;
`
export const CXText = styled.h5`
  display: table-cell;
  vertical-align: middle;
  font-size: 14px;
  line-height: 22px;
  color: white;
`
export const ReportingSubheading = styled.h6`
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  color: #333333;
`

//p
export const ButtonText = styled.p`
  display: table-cell;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: white;
`

//label
export const ErrorText = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #F44336;
`
export const BodyTextBold = styled.label`
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
`
export const BodyText = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`
export const CheckboxText = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  align-self: center;
  color: #333333;
  margin-left: 12px;
`
export const ReportingGreyBody = styled.label`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #707070;
`

//div
export const Container = styled.div`
  height: 100%;
`
export const PageSplit = styled.div`
  display: flex;
  height: 100%;
`
export const TemplateSplit = styled.div`
  flex: 1;
  padding: 26px 24px;
`
export const ElementSplit = styled.div`
  flex: 1;
  padding: 26px 24px;
  height: 100%;
  border-left: solid;
  border-left-color: #E0E0E0;
  border-left-width: 1px;
`
export const HorizontalFlexContainer = styled.div<{ alignItems?: Property.AlignItems, padding?: Property.Margin, showBorder?: boolean }>`
  display: flex;
  align-items: ${props => props.alignItems ?? 'flex-start'};
  align-content: center;
  justify-content: space-between;
  flex-direction: row;
  padding: ${props => props.padding ?? 'initial'};
  border-bottom: 1px solid ${DefaultTheme.color.grey3};
  border-bottom-width: ${props => props.showBorder ? 1 : 0};
`
export const HeaderFlexContainer = styled(HorizontalFlexContainer)`
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid ${DefaultTheme.color.grey3};
  background-color: ${DefaultTheme.color.grey1b};
`
export const ButtonFlexContainer = styled(HorizontalFlexContainer)`
  align-items: center;
  padding: 10px 24px;
  border-bottom: 1px solid ${DefaultTheme.color.grey3};
`
export const VerticalFlexContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
`
export const ButtonBar = styled.div`

`
export const CollapseContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  background-color: transparent;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 1px solid ${DefaultTheme.color.grey2};
  position: relative;
`
export const PanelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-left: 10px;
  padding-left: 10px;
  cursor: pointer;
`
export const GreyContainer = styled.div`
  width: fit-content;
  height: 24px;
  font-size: 12px;
  padding: 3px 6px 3px 6px;
  color: #4d4d4d;
  background-color: #eee;
  border-radius: 3px;
  text-align: center;
  margin: 8px auto 10px 6px;

  :first-child {
    margin-left: 0;
  }
`
export const IconContainer = styled.div`
  width: 36px;
  height: 36px;
  border: solid;
  justify-content: center;
  align-content: center;
  border-radius: 5px;
  border-width: 1px;
  border-color: #eee;
  margin-left: 10px;
  cursor: pointer;
  background-color: ${DefaultTheme.color.white};

  &:hover {
    background-color: ${DefaultTheme.color.grey2};
  }
`
export const SmallIconContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-left: 5px;
  border-color: transparent;
`
export const HorizontalLine = styled.div`
  border-top: solid;
  border-top-color: #F2F2F2;
  border-width: 1px;
`
export const CXContainer = styled.div`
  background: white;
  display: flex;
  flex-direction: row;
  padding: 10px 24px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #E0E0E0;
`
export const ValuesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
export const Value = styled.div`
  display: flex;
  flex-direction: row;
  height: 34px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 5px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  color: black;
  border: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: #e0e0e0;
  background-color: #fafafa;
`

//button
export const DefaultButton = styled.button<{ isActive?: boolean }>`
  border-radius: 5px;
  border: 1px solid ${props => props.isActive ? DefaultTheme.color.grey9 : DefaultTheme.color.grey4};
  background-color: ${props => props.isActive ? DefaultTheme.color.grey9 : DefaultTheme.color.white};
  padding: 5px 14px;
  align-content: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.isActive ? DefaultTheme.color.white : DefaultTheme.color.grey9};
  transition: fill 0.15s ease-in-out;
  cursor: pointer;
  height: 34px;

  &:hover {
    filter: brightness(0.90);
  }
`
export const PrimaryButton = styled(DefaultButton)`
  background-color: ${DefaultTheme.color.primaryRed};
  color: ${DefaultTheme.color.white};
  border-color: ${DefaultTheme.color.primaryRed};
`

export const ModalCancelButton = styled.button`
  height: 42px;
  width: 93px;
  border-radius: 5px;
  border: solid;
  border-width: 1px;
  border-color: ${DefaultTheme.color.grey4};
  background-color: ${DefaultTheme.color.white};
  padding: 5px;
  align-content: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${DefaultTheme.color.grey9};
  transition: fill 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }
`
const modalButtonStyling = `
    height: 42px;
	width: 80px;
	border-radius: 5px;
	border: solid;
	border-width: 1px;
	border-color: ${DefaultTheme.color.grey8};
	background-color: ${DefaultTheme.color.grey8};
	padding: 5px;
	align-content: center;
	justify-content: space-between;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${DefaultTheme.color.white};
	transition: fill 0.15s ease-in-out;
	cursor: pointer;
	
	&:hover {
		background-color: ${DefaultTheme.color.grey7};
	}
    :disabled {
	    background-color: ${DefaultTheme.color.grey3};
	    border-color: ${DefaultTheme.color.grey3};
	    color: ${DefaultTheme.color.grey5};
    `
export const ModalConfirmButton = styled.button`
  ${modalButtonStyling}
}
`
export const ModalSubmitInput = styled.input`
  ${modalButtonStyling}
}
`
export const XButton = styled.button`
  margin-left: 10px;
  border: transparent;
  background: transparent;
  color: black;
  transition: fill 0.15s ease-in-out;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    filter: brightness(0.9);
  }
`

//image
export const ChevronImageContainer = styled.img`
  width: 16px;
  height: 16px;
  align-content: center;
  align-self: center;
  margin-right: 15px;
  justify-content: center;
  margin-left: 5px;
  cursor: pointer;
`
export const CheckImageContainer = styled.img<{ isDisabled?: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 3px;
  cursor: pointer;
  background-color: ${props => props.isDisabled ? '#c4c4c4' : 'initial'};
`
export const EditImageContainer = styled.span`
  //border: 1px solid rgb(224, 224, 224);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  img {
    width: 12px;
    height: 12px;
  }
`

//Input
export const StyledInput = styled.input`
  border-color: #E0E0E0;
  height: 42px;
  padding: 16px;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
`
export const RadioCircleImageContainer = styled.input`
  width: 20px;
  height: 20px;
  border-radius: 16px;
  cursor: pointer;
`
export const ButtonGroupHorizontalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 100%;
  overflow-x: auto;

  button {
    margin-left: 8px;

    :first-child {
      margin-left: 0;
    }
  }
`
export const StyledLoader = styled(LoadingOverlay)`
  width: 100%;
  height: 100%;
  overflow: scroll;
`

export const styles = {
    cancelButton:
        {
            height: 42,
            width: 93
        },
    saveButton: {
        height: 42,
        width: 80,
        backgroundColor: '#333333'
    },
    bodyBold: {
        fontWeight: 700,
        cursor: "pointer"
    },
}
