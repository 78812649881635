import {Button, Form, Input, notification} from "antd"
import Icon from '@ant-design/icons'
import React, {Component} from "react"
import logo from "../img/mrpempowerSm_1.png"
import {EmpowerLoginAPI} from "../data/api/EmpowerLoginAPI"
import {EmpowerAPIResponse, LoginRequest, LoginResponse} from "../data/api/APITypes"
import {UserStore} from "../data/store/UserStore"
import {RecipientsStore} from "../data/store/RecipientsStore"
import {Footer} from "./Footer"
import { withRouter } from "react-router-dom"

const FormItem = Form.Item

class NormalLoginForm extends Component<any, any> {
    constructor(props: any) {
        super(props)
        this.state = {
            loading: false,
            iconLoading: false,
            //set initial state
            username: "",
            password: ""
        }
    }
    handleApiCall = async () => {
        this.enterIconLoading()
        this.enterLoading()
        const credentials: LoginRequest = {
            //Start loading for button and get username and password and set state
            userCode: this.state.username,
            password: this.state.password,
            // token: 'D9339A01-82EE-4E6A-8E38-0AF6A769BB96',
        }
        RecipientsStore.clear()
        EmpowerLoginAPI.login(credentials) //Make API call, passing credentials
            .then((res: EmpowerAPIResponse<LoginResponse>) => {
                notification.open({
                    message: "Login Successful",
                    type: "success",
                    description: "You will be re-directed soon.",
                    icon: <Icon type="smile" style={{
                        color: "#108ee9"
                    }}/>
                })
                UserStore.storeLogin(res.data)
                UserStore.storeAzureToken(res.data.inTouchAccessToken);
               
                setTimeout(() => {
                    this.enterIconLoading()
                    this.enterLoading() //If successful, stop loading and proceed to HQ Announcements section
                    // document.location.href = "/customerexperience"
                    this.props.history.push('/customerexperience');
                }, 1000)
            }).catch(error => {
            notification.open({
                message: "Login Failed",
                type: "error",
                //If failed, don't proceed and display error message
                icon: <Icon type="frown" style={{
                    color: "#D92930"
                }}/>,
                description: "Please ensure your username or password is correct."
            })
            this.enterIconLoading()
            this.enterLoading()
        })
    }
    enterLoading = () => {
        let current = this.state.loading
        current ? current = false : current = true //Set loading for button method

        this.setState({
            loading: current
        })
    }
    enterIconLoading = () => {
        let current = this.state.iconLoading
        current ? current = false : current = true //set icon loading for button method

        this.setState({
            iconLoading: current
        })
    }
    handleSubmit = (e: any) => {
        this.handleApiCall()
    }

    updateUsernameValue = (name: any) => {
        this.setState({
            //set username state
            username: name.target.value
        })
    }

    updatePasswordValue = (password: any) => {
        this.setState({
            //set password state
            password: password.target.value
        })
    }

    render() {
        return <>
            <Form onFinish={this.handleSubmit} className="login-form">
                <img className="mrp-logo" src={logo} alt="mrp Logo"/>
                <FormItem name={"userName"} rules={[{
                    required: true,
                    message: "Please input your username!"
                }]}>
                    <Input prefix={<Icon type="user" style={{
                        color: "rgba(0,0,0,.25)"
                    }}/>} placeholder="Username" value={this.state.username}
                           onChange={evt => this.updateUsernameValue(evt)}/>
                </FormItem>
                <FormItem name={"password"} rules={[{
                    required: true,
                    message: "Please input your Password!"
                }]}>
                    <Input prefix={<Icon type="lock" style={{
                        color: "rgba(0,0,0,.25)"
                    }}/>} type="password" placeholder="Password" value={this.state.password}
                           onChange={evt => this.updatePasswordValue(evt)}/>
                </FormItem>
                <FormItem>
                    <Button type="primary"
                            loading={this.state.iconLoading}
                            onClick={this.state.enterLoading}
                            htmlType="submit"
                            className="login-form-button">
                        Log in
                    </Button>
                </FormItem>
            </Form>
            <Footer style={{justifyContent: 'center', left: 0, backgroundColor: 'transparent', border: 0}}/>
        </>
    }

}

const WrappedNormalLoginForm = NormalLoginForm
export default withRouter(WrappedNormalLoginForm)
