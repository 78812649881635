import { GroupDetailResponse, UserDetailResponse } from "../api/APITypes";
import { EmpowerRecipientsAPI } from "../api/EmpowerRecipientsAPI";
import Moment from "moment";
type UserAndGroupData = {
  timestamp: string;
  users: Array<UserDetailResponse>;
  groups: Array<GroupDetailResponse>;
};

class RecipientGroupProvider {
  constructor() {
    this.currentUserData = this._getData();
  }

  currentUserData: UserAndGroupData | null | undefined = undefined;

  fetchCurrentGroups(): Promise<Array<GroupDetailResponse>> {
    if (this.currentUserData != null && this.currentUserData.groups.length > 0) {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        resolve(this.currentUserData.groups);
      });
    } else {
      return this._fetchUserData().then((data: UserAndGroupData) => {
        this._saveData(data);

        return data.groups;
      });
    }
  }

  fetchCurrentUsers(): Promise<Array<UserDetailResponse>> {
    if (this.currentUserData != null && this.currentUserData.users.length > 0) {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        resolve(this.currentUserData.users);
      });
    } else {
      return this._fetchUserData().then((data: UserAndGroupData) => {
        this._saveData(data);

        return data.users;
      });
    }
  }

  fetchCurrentData(): Promise<UserAndGroupData> {
    if (this.currentUserData != null) {
      return new Promise((resolve, reject) => {
        // @ts-ignore
        resolve(this.currentUserData);
      });
    } else {
      return this._fetchUserData().then((data: UserAndGroupData) => {
        this._saveData(data);

        return data;
      });
    }
  }

  _fetchUserData(): Promise<UserAndGroupData> {
    let userPromise = EmpowerRecipientsAPI.fetchUsers();
    let groupPromise = EmpowerRecipientsAPI.fetchRecipientGroups();

    /*//$FlowFixMe*/
    return Promise.all([userPromise, groupPromise]).then(values => {
      let moment = Moment().format();
      return {
        timestamp: moment,
        // @ts-ignore
        users: values[0].data.recipients,
        // @ts-ignore
        groups: values[1].data.recipients
      };
    });
  }

  _saveData(data: UserAndGroupData) {
    this.currentUserData = data;
  }

  _getData(): UserAndGroupData | null | undefined {
    let data = localStorage.getItem("userData");

    if (data != null) {
      return null;
    } else {
      return null;
    }
  }

}

export class RecipientsStore {
  static _recipientProvider = new RecipientGroupProvider();

  static clear() {
    RecipientsStore._recipientProvider = new RecipientGroupProvider();
  }

  static getRecipientGroups = (): Promise<Array<GroupDetailResponse>> => RecipientsStore._recipientProvider.fetchCurrentGroups();
  static getRecipientUsers = (): Promise<Array<UserDetailResponse>> => RecipientsStore._recipientProvider.fetchCurrentUsers();
  static getUserAndGroupData = (): Promise<UserAndGroupData> => RecipientsStore._recipientProvider.fetchCurrentData();
}
