import { CreateTicketRequest, EmpowerAPIResponse, TicketDetailResponse, TicketPriorityResponse, TicketStatusResponse, TicketSummaryResponse } from "./APITypes";
import { apiDelete, apiGet, apiPatch, apiPost, calculateNextPageNumber, EmpowerAPIConfig } from "./APIConfig";
export class EmpowerTicketsAPI {
  static Endpoints = {
    fetchTickets: (pageNumber: number) => "/api/v1/tickets?pageSize=" + EmpowerAPIConfig.defaultPageSize + "&pageNumber=" + pageNumber + "&sortField=lastUpdatedDate&sortDirection=DESC",
    refetchTickets: (totalItemCount: number) => "/api/v1/tickets?pageSize=" + totalItemCount + "&sortField=lastUpdatedDate&sortDirection=DESC",
    createTicket: () => "/api/v1/tickets",
    fetchTicketPriorities: () => "/api/v1/tickets/priorities",
    fetchTicketStatus: () => "/api/v1/tickets/statuses",
    fetchTicketCategories: () => "/api/v1/tickets/categories",
    updateTicketStatus: (id: string) => "/api/v1/tickets/" + id + "/status",
    updateTicketPinStatus: (id: string) => "/api/v1/tickets/" + id + "/togglePin",
    fetchTicketDetails: (id: string) => "/api/v1/tickets/" + id,
    deleteTicket: (id: string) => "/api/v1/tickets/" + id,
    replyToTicket: (id: string) => "/api/v1/tickets/" + id + "/reply"
  };
  static fetchTickets = (startIndex: number = 0): Promise<EmpowerAPIResponse<TicketSummaryResponse>> => apiGet(EmpowerTicketsAPI.Endpoints.fetchTickets(calculateNextPageNumber(startIndex)));
  static refreshTickets = (currentCount: number): Promise<EmpowerAPIResponse<TicketSummaryResponse>> => apiGet(EmpowerTicketsAPI.Endpoints.refetchTickets(currentCount));
  // @ts-ignore
  static createTicket = (ticket: CreateTicketRequest): Promise<any> => apiPost(EmpowerTicketsAPI.Endpoints.createTicket(), ticket);
  static updateTicketStatus = (ticketId: string, ticketStatusId: number): Promise<any> => apiPatch(EmpowerTicketsAPI.Endpoints.updateTicketStatus(ticketId), {
    ticketStatusId: ticketStatusId
  });
  // @ts-ignore
  static fetchTicketPriorities = (): Promise<Array<TicketPriorityResponse>> => apiGet(EmpowerTicketsAPI.Endpoints.fetchTicketPriorities());
  // @ts-ignore
  static fetchTicketCategories = (): Promise<Array<TicketStatusResponse>> => apiGet(EmpowerTicketsAPI.Endpoints.fetchTicketCategories());
  // @ts-ignore
  static fetchTicketStatuses = (): Promise<Array<TicketStatusResponse>> => apiGet(EmpowerTicketsAPI.Endpoints.fetchTicketStatus());
  static deleteTicket = (ticketId: string): Promise<any> => apiDelete(EmpowerTicketsAPI.Endpoints.deleteTicket(ticketId));
  static fetchTicketDetails = (ticketId: string): Promise<EmpowerAPIResponse<TicketDetailResponse>> => apiGet(EmpowerTicketsAPI.Endpoints.fetchTicketDetails(ticketId));
  static updateTicketPinStatus = (ticketId: string): Promise<any> => apiPatch(EmpowerTicketsAPI.Endpoints.updateTicketPinStatus(ticketId));
  // @ts-ignore
  static replyToTicket = (ticketId: string, ticketBody: any): Promise<any> => apiPost(EmpowerTicketsAPI.Endpoints.replyToTicket(ticketId), ticketBody);
}
