import React, {useEffect, useRef} from "react"
import {ButtonGroupHorizontalWrapper} from "../feature/cx/library/CXStyleSheet"

interface ButtonGroupProps {
    defaultSelected?: number
}

export const ButtonGroup: React.FC<ButtonGroupProps> = (props) => {
    const {defaultSelected, children} = props
    const parentElement = useRef<HTMLElement | null>(null)
    
    const handleClick = (e: MouseEvent) => {
        if (parentElement.current) {
            // @ts-ignore
            if (e.target.id === parentElement.current?.id) {
                return
            }
            const children = parentElement.current.children
            for (let i = 0; i < children.length; i++) {
                // @ts-ignore
                children[i].classList.remove('active')
            }
        }
        // @ts-ignore
        e.target.classList.add('active')
    }
    
    useEffect(() => {
        parentElement.current = document.getElementById('btnGroupParent')
        const parent = parentElement.current
        if (parent) {
            parent.addEventListener('click', handleClick)
            defaultSelected !== undefined && parent?.children[defaultSelected].classList.add('active')
        }
        
        return () => {
            parent && parent.removeEventListener('click', handleClick)
        }
    }, [defaultSelected])
    
    return <ButtonGroupHorizontalWrapper id={'btnGroupParent'}>
        {children}
    </ButtonGroupHorizontalWrapper>
}
