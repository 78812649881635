import React, {useCallback, useContext, useEffect, useState} from 'react'
import {Modal, Popconfirm} from 'antd'
import {
    BodyText,
    ErrorText,
    IconContainer,
    ModalCancelButton,
    ModalConfirmButton,
    ModalHeading,
    StyledInput,
    VerticalFlexContainer
} from './CXStyleSheet'
import {useForm} from 'react-hook-form'
import {CXContext} from '../../../context/CXContext'
import deleteIcon from '../../../img/delete.png'
import {CXSelectionContext} from "../../../context/CXSelectionContext"
import Select, {SingleValue} from "react-select"
import {OptionValue, RepeatType} from "../../../data/api/APITypes"

interface AdditionalData {
    categoryId?: string
    isRepeat?: boolean
    subcategoryId?: string
    elementId?: string
    repeatType?: string
}

interface CxModalEditFocusAreaProps {
    id: string
    name: string
    type: 'Category' | 'Subcategory' | 'Element' | 'Issue'
    show: boolean
    additionalData?: AdditionalData
    onClose?: () => void
}

export const CxModalEditFocusAreaItems: React.FC<CxModalEditFocusAreaProps> = (props) => {
    const {id, name, type, show, additionalData, onClose} = props
    const cxContext = useContext(CXContext)
    const selectionContext = useContext(CXSelectionContext)
    const currentFocusArea = cxContext.activeFocusArea
    const [visible, setVisible] = useState(false)
    const [isChecked, setIsChecked] = useState(additionalData?.isRepeat ?? false)

    const {
        handleSubmit,
        register,
        reset,
        setValue,
        clearErrors,
        getValues,
        formState: {errors},
    } = useForm()

    const repeatElementOptions = Object.keys(RepeatType).map(r => {
        return {
            value: r.toString(),
            label: r.toString()
        }
    })

    const onSubmit = (data: any) => {
        switch (type) {
            case 'Category':
                cxContext.api.createOrUpdateCategory(
                    data.editInput,
                    id
                )
                break
            case 'Subcategory':
                cxContext.api.createOrUpdateSubcategory(
                    additionalData?.categoryId!,
                    data.editInput,
                    isChecked,
                    additionalData?.repeatType!,
                    id
                )
                break
            case 'Element':
                cxContext.api.createOrUpdateElement(
                    additionalData?.subcategoryId!,
                    data.editInput,
                    [],
                    id,
                    additionalData?.isRepeat && data.editInput
                )
                break
            case 'Issue':
                cxContext.api.updateIssue(
                    id,
                    data.editInput,
                    additionalData?.elementId
                )
                break
        }
        handleCancel()
    }

    const deleteFocusAreaHandler = () => {
        switch (type) {
            case 'Category':
                selectionContext.toggleCategory(id, false)
                cxContext.api.deleteCategory(
                    id
                )
                break
            case 'Subcategory':
                selectionContext.toggleSubcategory(id, false)
                cxContext.api.deleteSubcategory(
                    id
                )
                break
            case 'Element':
                selectionContext.toggleElement(id, false, false, false, false)
                cxContext.api.deleteElement(
                    id
                )
                break
            case 'Issue':
                selectionContext.toggleIssue(id, false, false, false, false)
                cxContext.api.deleteIssue(
                    id
                )
                break
        }
        handleCancel()
    }

    if (errors.editInput) {
        console.log(errors.editInput)
    }

    const handleCancel = useCallback(() => {
        reset({editInput: name})
        onClose && onClose()
    }, [name])

    const setValueHandler = (name: string, values: any) => {
        setValue(name, values)
        clearErrors(name)
    }

    const renderModalFooter = () => {
        return (
            <div>
                <span>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <div>
                            <ModalCancelButton onClick={handleCancel}>Cancel</ModalCancelButton>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <ModalConfirmButton type="submit">
                                Update
                            </ModalConfirmButton>
                        </form>
                    </div>
                </span>
            </div>
        )
    }

    const deleteConfirm = (
        <IconContainer style={{borderColor: '#E0E0E0', position: 'absolute', top: 25, right: 25}}>
            <Popconfirm
                title={`Are you sure you want to delete this ${type}?`}
                style={{display: 'flex'}}
                placement={'bottom'}
                onConfirm={deleteFocusAreaHandler}
            >
                <div style={{flex: 1}}>
                    <img style={{marginLeft: 10, marginTop: 10, height: 14, width: 14}} src={deleteIcon}
                         alt={'delete-icon'}/>
                </div>
            </Popconfirm>
        </IconContainer>
    )

    useEffect(() => {
        return setVisible(show)
    }, [show])

    useEffect(() => {
        if (!currentFocusArea) return
        reset({editInput: name})
    }, [currentFocusArea, name])

    return (
        <Modal
            visible={visible}
            centered
            destroyOnClose
            footer={renderModalFooter()}>

            <VerticalFlexContainer>
                <ModalHeading>Edit {type}</ModalHeading>
                {deleteConfirm}
                <BodyText>{type} Name</BodyText>

                <div style={{width: '100%'}}>
                    {(type === 'Element' && additionalData?.isRepeat) ?
                        <Select
                            {...register('editInput', {
                                required: `Select ${type} Name.`
                            })}
                            onChange={(value: SingleValue<OptionValue>) => setValueHandler('editInput', value?.value)}
                            options={repeatElementOptions}
                            defaultValue={repeatElementOptions.filter(r => r.value === getValues('editInput'))}
                        />
                        :
                        <StyledInput
                            {...register('editInput', {
                                required: `Enter ${type} Name.`
                            })}
                            type="text"
                        />
                    }
                </div>

                {errors.editInput && <ErrorText>{errors.editInput.message}</ErrorText>}

                {/*{type === 'Subcategory' && <div style={{*/}
                {/*    marginTop: 28, display: 'flex',*/}
                {/*    flexDirection: 'column',*/}
                {/*    alignItems: 'flex-start'*/}
                {/*}}>*/}
                {/*  <BodyText style={styles.bodyBold}>Element Type</BodyText>*/}
                {/*  <HorizontalFlexContainer style={{marginTop: 10}}>*/}
                {/*    <CheckImageContainer onClick={() => setIsChecked(!isChecked)}*/}
                {/*                         src={isChecked ? (greyCheck) : (emptyCheck)}/>*/}
                {/*    <CheckboxText style={{fontSize: 15, fontWeight: 700}}>Repeat Element</CheckboxText>*/}
                {/*  </HorizontalFlexContainer>*/}
                {/*  <GreyTextModal style={{marginLeft: 35, marginTop: 5}}>*/}
                {/*    This subcategory only allows for 1 element. This element and it’s*/}
                {/*    issues will be asked as many times as there are features in the store.*/}
                {/*    An example is Windows, which will be asked 3 times if there are 3 windows.</GreyTextModal>*/}
                {/*</div>}*/}
            </VerticalFlexContainer>

        </Modal>
    )
}
