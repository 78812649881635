import React, {useState} from "react";
import {Modal} from "antd";
import {
    DefaultButton,
    HorizontalFlexContainer,
    ModalCancelButton,
    ModalConfirmButton
} from "./CXStyleSheet";

// sort
const testData = [{
    name: "c",
    date: new Date("2020/01/02"),
}, {
    name: "b",
    date: new Date("2020/12/12"),
}, {
    name: "a",
    date: new Date("2020/06/06"),
}]

const sortIt = (sortBy: any) => (a: any, b: any) => {
    if (a[sortBy] > b[sortBy]) {
        return 1;
    } else if (a[sortBy] < b[sortBy]) {
        return -1;
    }
    return 0;
}

export const CXModalTest = () => {
    const [visible, setVisible] = useState(false);
       const handleOk = () => {
    }
    const handleCancel = () => {
        setVisible(false)
    }
    const renderModalFooter = () => {
        return (
            <div>
                <span>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <div>
                            <ModalCancelButton onClick={handleCancel}>Cancel</ModalCancelButton>
                        </div>
                        <div>
                            <ModalConfirmButton
                                type="submit"
                                onClick={handleOk}>
                                Save
                            </ModalConfirmButton>
                        </div>
                    </div>
                </span>
            </div>
        )
    }

    return (
        <Modal
            visible={visible}
            centered
            footer={renderModalFooter()}
            onOk={handleOk}
            onCancel={handleCancel}>

            {/*sort*/}
            <div>
                <HorizontalFlexContainer>
                    <HorizontalFlexContainer>
                        <DefaultButton style={{height: 36, width: 83}}>Active</DefaultButton>
                        <DefaultButton style={{height: 36, width: 83}}>Inactive</DefaultButton>
                    </HorizontalFlexContainer>
                    <HorizontalFlexContainer>
                        <label
                            style={{marginRight: 5}}>
                            Sort by
                        </label>
                        <div style={{width: 244, height: 36}}>

                            <select onChange={(e) => {
                                const sortedData = testData.sort(sortIt(e.target.value))
                                console.log(sortedData)
                            }}>
                                <option value="date">Newest to Oldest</option>
                                <option value="name">A → Z</option>
                            </select>
                        </div>
                    </HorizontalFlexContainer>
                </HorizontalFlexContainer>
            </div>

        </Modal>
    )
}
