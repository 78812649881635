import React from "react"
import {Button, Checkbox, Divider, Form, Spin} from "antd"
import TextArea from "antd/lib/input/TextArea"
import {AttachmentUploader} from "../../components/AttachmentUploader"
import {AttachmentDescriptor} from "../../components/AttachmentUploader"

type TicketReplyFormProps = {
    onFormSubmit: (arg0: any) => void
    form: any
}

type TicketReplyFormState = {
    submitInAction: boolean
    uploadingFiles: boolean
    attachFiles: boolean
}

export type TicketReplyFormData = {
    body: string
    attachments: Array<AttachmentDescriptor>
}

class ReplyForm extends React.Component<TicketReplyFormProps, TicketReplyFormState> {
    constructor(props: TicketReplyFormProps) {
        super(props)
        const parentState = this.state
        this.state = {
            ...parentState,
            submitInAction: false,
            uploadingFiles: false,
            attachFiles: false,
        }
    }

    showBusy = (isBusy: boolean) => {
        this.setState({
            uploadingFiles: isBusy
        })
    }
    // @ts-ignore
    handleSubmit = (values: any) => {
        // @ts-ignore
       
                this.props.onFormSubmit({
                    ...values,
                    attachments: values.currentAttachments
                })
                this.props.form.resetFields()
           
        }
    
    setShowAttachments = (e: any) => {
        this.setState({
            attachFiles: e.target.checked
        })
    }

    render() {
        const formItemLayout = {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 14
            }
        }
        return <Spin spinning={this.state.uploadingFiles}>
            <div className="item-detail-top-bar">
                <div className="item-detail-text-container">
                    <h2>Reply</h2>
                </div>
                <Divider/>
            </div>

            <Form onFinish={this.handleSubmit}>

                <Form.Item {...formItemLayout} label="Body" name={"body"} rules={[{
                    required: true,
                    message: "Please Input a Reply."
                }]}>
                    <TextArea placeholder="" style={{
                        resize: "none"
                    }}/>
                </Form.Item>

                <Form.Item {...formItemLayout} label=" " colon={false}>
                    <Checkbox onChange={this.setShowAttachments}>Attach Items</Checkbox>
                </Form.Item>

                {this.state.attachFiles && <Form.Item {...formItemLayout} label="Attach" name={"currentAttachments"}>
                    <AttachmentUploader isBusy={this.showBusy}
                                        onChange={() => {
                                        }}/>
                </Form.Item>}


                <Form.Item wrapperCol={{
                    span: 12,
                    offset: 6
                }}>
                    <Button type="primary" htmlType="submit" loading={this.state.submitInAction} style={{
                        display: "block",
                        margin: "auto"
                    }}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    }

}

export class TicketReplyForm extends React.Component<any, any> {
    render() {
        let WrappedForm = ReplyForm
        // @ts-ignore
        return <WrappedForm onFormSubmit={this.props.onFormSubmit}/>
    }

}
