import {Divider, Spin} from "antd"
import React from "react"
import {EmpowerAnnouncementAPI} from "../../data/api/EmpowerAnnouncementAPI"
import {AnnouncementDetailResponse, EmpowerAPIResponse} from "../../data/api/APITypes"
import {AttachmentListing} from "../../components/AttachmentListing"
import {DateUtil} from "../../util/DateUtil"

type AnnouncementDetailProps = {
  announcementId: string
}
type AnnouncementDetailState = {
  isLoadingDetails: boolean
  announcement: AnnouncementDetailResponse | null | undefined
}
export class AnnouncementDetail extends React.Component<AnnouncementDetailProps, AnnouncementDetailState> {
  constructor(props: AnnouncementDetailProps) {
    super(props)
    this.state = {
      isLoadingDetails: true,
      announcement: null
    }
    this.fetchAnnouncement()
  }

  componentDidUpdate(prevProps: AnnouncementDetailProps, prevState: AnnouncementDetailState, snapshot: any) {
    if (prevProps.announcementId !== this.props.announcementId) {
      this.setState({
        isLoadingDetails: true,
        announcement: null
      })
      this.fetchAnnouncement()
    }
  }

  fetchAnnouncement() {
    EmpowerAnnouncementAPI.fetchAnnouncementDetails(this.props.announcementId).then((response: EmpowerAPIResponse<AnnouncementDetailResponse>) => {
      this.setState({
        isLoadingDetails: false,
        announcement: response.data
      })
    }).catch(() => {
      this.setState({
        isLoadingDetails: false
      })
    })
  }

  render() {
    return <Spin spinning={this.state.isLoadingDetails} style={{
      alignSelf: "center",
      minHeight: 400
    }} size="large">
        <div style={{
        alignSelf: "center",
        minHeight: 400
      }}>
          {this.state.announcement != null && <div>
              <div className="item-detail-top-bar">
                <div className="item-detail-text-container">
                  <h2>{this.state.announcement.subject}</h2>
                  <h4>{this.state.announcement.from}</h4>
                </div>
                <Divider />
              </div>
              <div className="item-detail-content" style={{
            paddingBottom: 10
          }}>
                <h2>Details:</h2>
                <h4>{this.state.announcement.body}</h4>
                <Divider />
                <h2>Status:</h2>
                <h4>{this.state.announcement.announcementStatusDescription}</h4>
                {this.state.announcement.datePublished != null && <div>
                  <Divider />
                  <h2>Date Published:</h2>
                  <h4>{DateUtil.formatEmpowerDate(this.state.announcement.datePublished)}</h4>
                </div>}
                {this.state.announcement.attachments != null && this.state.announcement.attachments.length > 0 && <div>
                  <Divider />
                  <AttachmentListing attachments={this.state.announcement.attachments} />
                </div>}
              </div>
            </div>}

        </div>
      </Spin>
  }

}
