import {CXContext} from "../context/CXContext"
import React, {useContext, useEffect, useState} from "react"
import {useHistory, useParams} from "react-router-dom"
import {
    ElementSplit,
    HeaderFlexContainer,
    HorizontalFlexContainer,
    IconContainer,
    PageSplit,
    PageTitle,
    SmallIconContainer, StyledLoader,
    SubcategoryHeading,
    TemplateSplit
} from "../feature/cx/library/CXStyleSheet"
import {BackButton} from "./BackButton"
import gear from "../img/gear.png"
import {CXTemplateSettings} from "../feature/cx/library/CXTemplateSettings"
import {Switch} from "antd"
import CXLibraryTopBar from "../feature/cx/library/CXLibraryTopBar"
import {CXModalTest} from "../feature/cx/library/CXModalTest"
import {CXSplitLeft} from "../feature/cx/library/CXSplitLeft"
import comment from "../img/Comment.png"
import camera from "../img/camera.png"
import video from "../img/Frame.png"
import {CXSplitRight} from "../feature/cx/library/CXSplitRight"
import {UserRoles} from "./UserRoles"
import {TemplateSurvey} from "../data/api/generated/EmpowerSwaggerApi"
import {PulseLoader} from "react-spinners"
import DefaultTheme from "../util/Helper"
import {css} from "@emotion/react"

interface TemplateViewProps {
}

export const TemplateView: React.FC<TemplateViewProps> = (props) => {
    const history = useHistory()
    const {templateId} = useParams<{templateId: string}>()

    const cxContext = useContext(CXContext)
    const activeSubcategory = cxContext.activeSubcategory
    const templates = cxContext.availableTemplates

    const [isShowSettings, setIsShowSettings] = useState(false)
    const [labelText, setLabelText] = useState<string | null>(null)
    const [selectedTemplate, setSelectedTemplate] = useState<TemplateSurvey | undefined>()

    const isActive = labelText === "Active"

    useEffect(() => {
        if (!templateId) {
            return
        }
        if (templates.length === 0) {
            return
        }
        const selectedTemplates = templates.filter((template) => template.templateId === templateId)
        if (selectedTemplates.length === 0) {
            return
        }
        setSelectedTemplate(selectedTemplates[0])
    }, [templates, templateId])

    useEffect(() => {
        if (!selectedTemplate) {
            return
        }

        const templateIsActive = selectedTemplate.isActive
        setLabelText(templateIsActive ? "Active" : "Inactive")

        cxContext.api.getTemplate(selectedTemplate.templateId!)
        // eslint-disable-next-line
    }, [selectedTemplate])

    useEffect(() => {
        if (!labelText) {
            return
        }
        if (!selectedTemplate) {
            return
        }

        if ((selectedTemplate.isActive && isActive) || (!selectedTemplate.isActive && !isActive)) {
            return
        }

        cxContext.api.changeTemplateActiveState({
            templateId: selectedTemplate.templateId,
            activeState: isActive
        })
        // eslint-disable-next-line
    }, [labelText, selectedTemplate, isActive])

    return <>
        <StyledLoader
            active={cxContext.loading}
            text="Loading content..."
            classNamePrefix="MyLoader_"
            spinner={<PulseLoader
                color={`${DefaultTheme.color.primaryRed}`}
                css={css`display: block;
                  margin: 0 auto;`}
                speedMultiplier={0.5}
            />
            }>
            <HeaderFlexContainer>
                <HorizontalFlexContainer alignItems={"center"}>
                    <BackButton/>
                    <PageTitle>
                        {selectedTemplate?.name}
                    </PageTitle>
                </HorizontalFlexContainer>


                {selectedTemplate && <HorizontalFlexContainer alignItems={"center"}>
                  <UserRoles data={selectedTemplate.roles ?? []}/>
                  <IconContainer onClick={() => setIsShowSettings(true)}>
                    <img style={{marginLeft: 10, marginTop: 10, height: 14, width: 14}} src={gear} alt={"gear-icon"}/>
                  </IconContainer>
                  <CXTemplateSettings selectedTemplate={selectedTemplate} show={isShowSettings}
                                      onClose={() => setIsShowSettings(false)}/>
                  <label style={{marginLeft: 10, marginRight: 10, width: 52}}>{labelText}</label>
                  <Switch
                    checked={labelText === "Active"}
                    onChange={() => setLabelText(isActive ? "Inactive" : "Active")}
                  />
                </HorizontalFlexContainer>}
            </HeaderFlexContainer>

            <CXLibraryTopBar history={history}/>

            <PageSplit>
                <CXModalTest/>
                <TemplateSplit>
                    <CXSplitLeft/>
                </TemplateSplit>
                <ElementSplit>
                    {activeSubcategory?.name !== undefined && <div>
                      <HorizontalFlexContainer padding={'5px 0 12px 0'} showBorder={true}>
                        <SubcategoryHeading>{activeSubcategory?.name}</SubcategoryHeading>
                        <HorizontalFlexContainer alignItems={'center'}>
                          <SmallIconContainer>
                            <img style={{height: 14, width: 14}} src={comment} alt={'comment-icon'}/>
                          </SmallIconContainer>
                          <SmallIconContainer>
                            <img style={{height: 14, width: 14}} src={camera} alt={'camera-icon'}/>
                          </SmallIconContainer>
                          {/*<SmallIconContainer>*/}
                          {/*  <img style={{height: 14, width: 14}} src={video} alt={'video-icon'}/>*/}
                          {/*</SmallIconContainer>*/}
                          <IconContainer style={{borderColor: "transparent", height: 16, width: 28}}/>
                        </HorizontalFlexContainer>
                      </HorizontalFlexContainer>
                      <CXSplitRight/>
                    </div>}
                </ElementSplit>
            </PageSplit>
        </StyledLoader>
    </>
}
