import React from "react"
import {Button, Spin} from "antd"
import {EmpowerAttachmentsAPI} from "../data/api/EmpowerAttachmentsAPI"
import {Attachment} from "../data/api/APITypes"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import logo from "../img/ajax-loader.gif"

type AttachmentListingProps = {
  attachments: Array<Attachment>
}
type AttachmentListingState = {
  currentPDF: any | null | undefined
  showImage: boolean
  currentImage: any | null | undefined
}
export class AttachmentListing extends React.Component<AttachmentListingProps, AttachmentListingState> {
  constructor(props: AttachmentListingProps) {
    super(props)
    this.state = {
      currentPDF: null,
      showImage: false,
      currentImage: null
    }
  }

  handleAttachment = (attachment: Attachment, force: boolean = false) => {
    if (force) {
      EmpowerAttachmentsAPI.asyncDownloadFile(attachment.description, attachment.attachmentId)
    } else if (attachment.fileType === "image/jpeg" || attachment.fileType === "image/png" || attachment.fileType === "image/jpeg") {
      this.showImage(attachment)
    } else {
      EmpowerAttachmentsAPI.asyncDownloadFile(attachment.description, attachment.attachmentId)
    }
  }

  showImage(attachment: Attachment) {
    this.setState({
      showImage: true,
      currentImage: null
    })
    EmpowerAttachmentsAPI.fetchAttachment(attachment.attachmentId).then(response => {
      this.setState({
        currentImage: response
      })
    })
  }

  currentImage = () => {
    if (this.state.currentImage != null) {
      return this.state.currentImage
    }

    return logo
  }

  attachmentButtons(): any {
    return this.props.attachments != null && this.props.attachments.map(attachment => {
      return <div>
          {attachment.fileType !== "image/jpg" && attachment.fileType !== "image/png" && attachment.fileType !== "image/jpeg" && <div>
            <Button key={attachment.attachmentId} icon="download" style={{
            marginTop: 4,
            marginLeft: 4,
            marginRight: 4
          }} onClick={() => {
            this.handleAttachment(attachment, true)
          }}>
              {attachment.description}
            </Button>
          </div>}
          {(attachment.fileType === "image/jpg" || attachment.fileType === "image/png" || attachment.fileType === "image/jpeg") && <div>
            <Button key={attachment.attachmentId} icon="picture" style={{
            marginTop: 4,
            marginLeft: 4,
            marginRight: 1
          }} onClick={() => {
            this.handleAttachment(attachment)
          }}>
              {attachment.description}
            </Button>
            <Button icon="download" style={{
            marginTop: 4,
            marginLeft: 1,
            marginRight: 4
          }} onClick={() => {
            this.handleAttachment(attachment, true)
          }}></Button>
          </div>}
        </div>
    })
  }

  render() {
    return <div>
        {this.props.attachments != null && <div>
          <h3>Attachments</h3>
          {this.attachmentButtons()}
          <div>
            {this.state.showImage && <Lightbox mainSrc={this.currentImage()} onCloseRequest={() => this.setState({
            showImage: false
          })}>
              <Spin spinning={true} size="large" />
            </Lightbox>}
          </div>
        </div>}
      </div>
  }

}
