import {APIClient, APIResponse} from "./APIClient"

const REACT_APP_BASE_CX_URL = {
    test: 'http://localhost:5000',
    development: 'https://empower.mrpg.com/api/intouch',
    production: 'https://empower.mrpg.com/api/intouch',
}
const REACT_APP_BASE_URL = {
    test: 'http://localhost:5000',
    development: 'https://empower.mrpg.com/api/intouch',
    production: 'https://empower.mrpg.com/api/intouch',
}
const REACT_APP_REDIRECT_URL = {
    test: 'http://localhost:3000',
    development: 'http://localhost:3000',
    production: 'https://empower.mrpg.com/',
}
export class AzureApiConfig {
    static authorityDomain = "https://mrpgb2c.b2clogin.com"
    static tenant = "https://mrpgb2c.onmicrosoft.com"

    static signInPolicy = "B2C_1_EmpowerAPI_QA_SignIN"
    static signInSignUpPolicy = "B2C_1_EmpowerAPI_QA_SignUP_SignIN_MRPG"
    static forgotPasswordPolicy = "B2C_1_EmpowerAPI_QA_ForgotPassword_MRPG"
    static editProfilePolicy = "B2C_1_EmpowerAPI_QA_Edit"

    static applicationID = "fea505ba-80b1-4c86-88a5-ec76cf83c36e"
    static reactRedirectUri = REACT_APP_REDIRECT_URL[process.env.NODE_ENV]
    static tenantSubdomain = "https://mrpgb2c"
    static instance = "https://mrpgb2c.b2clogin.com/"

    static signInAuthority = "https://mrpgb2c.b2clogin.com/mrpgb2c.onmicrosoft.com/B2C_1_EmpowerAPI_QA_SignIN"
    static signInSignUpAuthority = "https://mrpgb2c.b2clogin.com/mrpgb2c.onmicrosoft.com/B2C_1_EmpowerAPI_QA_SignUP_SignIN_MRPG"
    static forgotPasswordAuthoriy = "https://mrpgb2c.b2clogin.com/mrpgb2c.onmicrosoft.com/B2C_1_EmpowerAPI_QA_ForgotPassword_MRPG"
    static editProfileAuthority = "https://mrpgb2c.b2clogin.com/mrpgb2c.onmicrosoft.com/B2C_1_EmpowerAPI_QA_Edit"
}

export class EmpowerAPIConfig {
    static baseURL = REACT_APP_BASE_URL[process.env.NODE_ENV]
    static baseCXUrl = REACT_APP_BASE_CX_URL[process.env.NODE_ENV]
    static defaultPageSize = 100
}

export function apiGet<T>(endpoint: string): Promise<APIResponse<T>> {
    return new APIClient().get(endpoint)
}

export function apiPost<T>(endpoint: string, request: any, conf: any): Promise<APIResponse<T>> {
    return new APIClient().post(endpoint, request, conf)
}

export function apiPatch<T>(endpoint: string, request: any = undefined): Promise<APIResponse<T>> {
    return new APIClient().patch(endpoint, request)
}

export function apiDelete<T>(endpoint: string): Promise<APIResponse<T>> {
    return new APIClient().delete(endpoint)
}

export function calculateNextPageNumber(startIndex: number): number {
    if (startIndex < EmpowerAPIConfig.defaultPageSize) {
        return 1
    }

    return startIndex / EmpowerAPIConfig.defaultPageSize + 1
}

export function fetchMoreIfNecessary(currentPage: number, currentPageSize: number, currentDataCount: number, totalCount: number, onFetchMore: () => void) {
    let currentPageCount = currentPage * currentPageSize

    if (currentDataCount === totalCount) {
        return
    }

    if (currentDataCount - currentPageCount < currentPageSize) {
        onFetchMore()
    }
}
