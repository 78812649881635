import { GroupDetailResponse, UserDetailResponse } from "./APITypes";
import { apiGet } from "./APIConfig";
export class EmpowerRecipientsAPI {
  static Endpoints = {
    fetchRecipientsUsers: () => "/api/v1/recipients/users",
    fetchRecipientsGroups: () => "/api/v1/recipients/groups"
  };
  // @ts-ignore
  static fetchUsers = (): Promise<Array<UserDetailResponse>> => apiGet(EmpowerRecipientsAPI.Endpoints.fetchRecipientsUsers());
  // @ts-ignore
  static fetchRecipientGroups = (): Promise<Array<GroupDetailResponse>> => apiGet(EmpowerRecipientsAPI.Endpoints.fetchRecipientsGroups());
}
