import React from 'react'
import {DefaultButton} from '../feature/cx/library/CXStyleSheet'
import styled from 'styled-components'

interface ImageButtonProps {
  text: string
  image: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
  imagePosition: 'left' | 'right'
  onClick: () => void
  hideText?: boolean
}

export const ImageButton: React.FC<ImageButtonProps> = props => {
  const {image, text, imagePosition, hideText, onClick} = props
  return (
    <ImageButtonWrapper
      onClick={onClick}
      imagePosition={imagePosition}
      hideText={hideText}>
      <img {...image} alt={image.alt} />
      {!hideText && text}
    </ImageButtonWrapper>
  )
}

const ImageButtonWrapper = styled(DefaultButton)<{
  imagePosition: 'left' | 'right'
  hideText?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;

  img {
    margin-right: ${props => (!props.hideText && props.imagePosition === 'left' ? 10 : 0)}px;
  }
`
