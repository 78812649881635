import React from "react"
import {Layout} from "antd"
import CXLibraryLayout from "./CXLibraryLayout"

interface CXLibraryProps {
    history: any
}

const CXLibrary: React.FC<CXLibraryProps> = (props) => {
    return <Layout style={{backgroundColor: '#fff', paddingBottom: 50}}>
        <CXLibraryLayout history={props.history}/>
    </Layout>
}

export default CXLibrary
