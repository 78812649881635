import React, {useContext, useEffect, useState} from "react"
import CXLibraryTopBar from "./CXLibraryTopBar"
import {CXSplitLeft} from "./CXSplitLeft"
import {
    ButtonBar,
    Container,
    ElementSplit,
    FocusAreaHeading,
    GreyContainer,
    HeaderFlexContainer,
    HorizontalFlexContainer,
    IconContainer,
    PageSplit,
    PageTitle,
    PrimaryButton,
    SmallIconContainer,
    StyledLoader,
    SubcategoryHeading,
    TemplateSplit
} from "./CXStyleSheet"
import {CXSplitRight} from "./CXSplitRight"
import gear from "../../../img/gear.png"
import comment from "../../../img/Comment.png"
import camera from "../../../img/camera.png"
import video from "../../../img/Frame.png"
import {CXModalFocusAreaSettings} from "./CXModalFocusAreaSettings"
import {CXContext} from "../../../context/CXContext"
import {PulseLoader} from "react-spinners"
import {css} from "@emotion/react"
import {CXModalTest} from "./CXModalTest"
import DefaultTheme from "../../../util/Helper"
import {CXModalMakeTemplate} from "./CXModalMakeTemplate"
import { UserStore } from "../../../data/store/UserStore"

/*export interface Issue {
    name: string
}
export interface Elements {
    name: string
    issues: Array<Issue>
}
export interface Subcategory {
    name: string
    elements: Array<Elements>
}
export interface Category {
    name: string
    subcategories: Array<Subcategory>
}
export interface FocusArea {
    focusAreaName: string
    categories: Array<Category>
}
export const focusArea: FocusArea = {
    focusAreaName: "Customer Experience",
    categories: [
        {
            name: "Front of House",
            subcategories: [
                {
                    name: "Windows",
                    elements: [
                        {
                            name: "Element Name A",
                            issues: [
                                {
                                    name: "VM Alignment"
                                },
                                {
                                    name: "Lighting"
                                }
                            ]
                        },
                        {
                            name: "Element Name B",
                            issues: [
                                {
                                    name: "Price"
                                },
                                {
                                    name: "Other"
                                }
                            ]
                        }
                    ]
                },
                {
                    name: "Drive Aisle",
                    elements: [
                        {
                            name: "Element Name A",
                            issues: [
                                {
                                    name: "VM Alignment"
                                },
                                {
                                    name: "Lighting"
                                }
                            ]
                        },
                        {
                            name: "Element Name B",
                            issues: [
                                {
                                    name: "Price"
                                },
                                {
                                    name: "Other"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            name: "Front of House",
            subcategories: [
                {
                    name: "Security",
                    elements: [
                        {
                            name: "Element Name A",
                            issues: [
                                {
                                    name: "VM Alignment"
                                },
                                {
                                    name: "Lighting"
                                }
                            ]
                        },
                        {
                            name: "Element Name B",
                            issues: [
                                {
                                    name: "Price"
                                },
                                {
                                    name: "Other"
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
}*/

interface CXLibraryLayoutProps {
    history: any
}


const CXLibraryLayout: React.FC<CXLibraryLayoutProps> = (props) => {
    const cxContext = useContext(CXContext)
    const activeFocusArea = cxContext.activeFocusArea
    const activeSubcategory = cxContext.activeSubcategory
    const active = (cxContext.availableFocusAreas.length > 0 && !activeFocusArea?.id) || cxContext.loading

    const [isShowTemplate, setIsShowTemplate] = useState(false)
    const [isShowSettings, setIsShowSettings] = useState(false)

    useEffect(() => {
        cxContext.api.refreshAvailableFocusAreas()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <StyledLoader
                active={active}
                text='Loading content...'
                classNamePrefix='MyLoader_'
                spinner={<PulseLoader
                    color={`${DefaultTheme.color.primaryRed}`}
                    css={css`display: block; margin: 0 auto;`}
                    speedMultiplier={0.5}
                />
                }>
                <Container>
                    <HeaderFlexContainer>
                        <HorizontalFlexContainer alignItems={"center"}>
                            <PageTitle>Template Library</PageTitle>
                        </HorizontalFlexContainer>

                        <PrimaryButton onClick={() => setIsShowTemplate(!isShowTemplate)}>
                            Make Template
                        </PrimaryButton>
                        <CXModalMakeTemplate show={isShowTemplate} onClose={() => setIsShowTemplate(!isShowTemplate)}/>
                    </HeaderFlexContainer>
                    <ButtonBar>
                        <CXLibraryTopBar history={props.history}/>
                    </ButtonBar>
                    <PageSplit>
                        <CXModalTest/>
                       <TemplateSplit>
                            {!activeFocusArea && <div style={{fontSize: 14, lineHeight: '22px', color: '#707070'}}>
                              <p>Create a Focus Area and build up a template using <br/> Categories, Subcategories and Elements.</p>
                            </div>}
                           {activeFocusArea && <div>
                                <HorizontalFlexContainer alignItems={'center'}>
                                    <FocusAreaHeading>{activeFocusArea?.name}</FocusAreaHeading>
                                    <HorizontalFlexContainer alignItems={'center'}>
                                        <label style={{fontSize: 12, color: '#4D4D4D'}}>Type:</label>
                                        <GreyContainer>{
                                            (activeFocusArea?.decisionType === 0) ? "Basic" : "Confirmation"
                                        }</GreyContainer>
                                        <IconContainer
                                            onClick={() => setIsShowSettings(!isShowSettings)}
                                        >
                                            <img style={{marginLeft: 10, marginTop: 10, height: 14, width: 14}}
                                                 src={gear} alt={'gear-icon'}/>
                                        </IconContainer>
                                        <CXModalFocusAreaSettings focusArea={cxContext.activeFocusArea}
                                                                  show={isShowSettings && cxContext.activeFocusArea !== undefined}
                                                                  onClose={() => setIsShowSettings(false)}/>
                                    </HorizontalFlexContainer>
                                </HorizontalFlexContainer>
                                <CXSplitLeft/>
                            </div>}
                        </TemplateSplit>
                        <ElementSplit>
                            {activeSubcategory?.name !== undefined && <div>
                                <HorizontalFlexContainer padding={'5px 0 12px 0'} showBorder={true}>
                                    <SubcategoryHeading>{activeSubcategory?.name}</SubcategoryHeading>
                                    <HorizontalFlexContainer alignItems={'center'} style={{marginRight: 68}}>
                                        <SmallIconContainer>
                                            <img style={{height: 14, width: 14}} src={comment} alt={'comment-icon'}/>
                                        </SmallIconContainer>
                                        <SmallIconContainer>
                                            <img style={{height: 14, width: 14}} src={camera} alt={'camera-icon'}/>
                                        </SmallIconContainer>
                                        {/*<SmallIconContainer>*/}
                                        {/*    <img style={{height: 14, width: 14}} src={video} alt={'video-icon'}/>*/}
                                        {/*</SmallIconContainer>*/}
                                    </HorizontalFlexContainer>
                                </HorizontalFlexContainer>
                                <CXSplitRight/>
                            </div>}
                        </ElementSplit>
                    </PageSplit>
                </Container>
            </StyledLoader>
        </>
    )
}

export default CXLibraryLayout
