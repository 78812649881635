import React from "react"
import Dropzone from "react-dropzone"
import {EmpowerAttachmentsAPI} from "../data/api/EmpowerAttachmentsAPI"
import {Button, Divider, List} from "antd"

type AttachmentUploaderState = {
  acceptedFiles: Array<any> | null | undefined
  uploadedFiles: Array<AttachmentDescriptor> | null | undefined
}
type AttachmentUploaderProps = {
  isBusy?: ((isBusy: boolean) => void) | null | undefined
  value?: Array<AttachmentDescriptor> | null | undefined
  onChange: ((arg0: Array<AttachmentDescriptor>) => void)
}
export type AttachmentDescriptor = {
  name: string
  id: string
  type: string
  filename: string
}
export class AttachmentUploader extends React.Component<AttachmentUploaderProps, AttachmentUploaderState> {
  static getDerivedStateFromProps(props: AttachmentUploaderProps, state: AttachmentUploaderState): AttachmentUploaderState | null | undefined {
    if (props.value === undefined || props.value === null) {
      return {
        uploadedFiles: null,
        acceptedFiles: null
      }
    }

    return null
  }

  constructor(props: AttachmentUploaderProps) {
    super(props)
    this.state = {
      acceptedFiles: undefined,
      uploadedFiles: undefined
    }
  }

  onDrop = (acceptedFiles: Array<any>, rejectedFiles: Array<any>) => {
    let self = this
    this.showBusy(true)
    this.uploadFiles(acceptedFiles).then((files: Array<AttachmentDescriptor>) => {
      var newFiles = files

      if (this.state.uploadedFiles != null) {
        newFiles = this.state.uploadedFiles.concat(files)
      }

      if (this.props.onChange != null) {
        this.props.onChange(newFiles)
      }

      self.setState({
        uploadedFiles: newFiles
      })
      this.showBusy(false)
    }).catch(error => {
      this.showBusy(false)
    })
    this.setState({
      acceptedFiles: acceptedFiles
    })
  }

  showBusy(isBusy: boolean) {
    if (this.props.isBusy != null) {
      this.props.isBusy(isBusy)
    }
  }

  removeAttachment(item: AttachmentDescriptor) {
    if (this.state.uploadedFiles != null) {
      let itemIndex = this.state.uploadedFiles.indexOf(item)

      this.state.uploadedFiles.splice(itemIndex, 1)

      this.props.onChange(this.state.uploadedFiles)
    }
  }

  uploadFiles = (acceptedFiles: Array<any>): Promise<Array<AttachmentDescriptor>> => {
    let promises = acceptedFiles.map((value: any) => {
      return EmpowerAttachmentsAPI.uploadFile(value.name, value, value.type).then(id => {
        return {
          name: value.name,
          id: id,
          type: value.type,
          filename: value.name
        }
      })
    })
    return Promise.all(promises)
  }

  dropMessage(): any {
    // if (this.state.acceptedFiles != null && this.state.acceptedFiles.length > 0) {
    //   return this.state.acceptedFiles.map(f => <li key={f.name}>{f.name} - {f.size} bytes</li>)
    // }
    if (this.state.uploadedFiles != null && this.state.uploadedFiles.length > 0) {
      return <div>
          <Divider />
          <h4>Uploaded Files:</h4>
          <List itemLayout="horizontal" dataSource={this.state.uploadedFiles} renderItem={(item: AttachmentDescriptor) => {
          return <List.Item actions={[<Button onClick={() => {
            this.removeAttachment(item)
          }}>Delete</Button>]}>
                  <List.Item.Meta title={<span>{item.name}</span>} />
                </List.Item>
        }} />
          <Divider />
        </div>
    }

    return "Drag and Drop files here, or click to open File Dialog. PNG, JPG and PDF files are supported."
  }

  accept: any = ["application/pdf", "image/png", "image/jpeg", "image/jpg"]

  render() {

    return <div>
        <Dropzone onDrop={this.onDrop} accept={this.accept}>
        </Dropzone>
        <div>
          {this.dropMessage()}
        </div>
      </div>
  }

}
