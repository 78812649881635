import {LibraryCategory, LibraryFocusArea, LibraryIssue} from "../data/api/generated/EmpowerSwaggerApi";


export class CXDataUtil {
    static GetAllIssuesInFocusArea = (focusArea: LibraryFocusArea | null | undefined): Array<LibraryIssue> => {
        return focusArea?.categories
                ?.map(cat => cat.subcategories)
                ?.flat()
                ?.map(subcat => subcat?.elements)
                ?.flat()
                ?.map(element => element?.issues)
                ?.flat()
                ?.filter(issue => issue) as Array<LibraryIssue>
            ?? []
    }

    static IssuesToStrings = (issues : Array<LibraryIssue>) : string[] => {
        return issues.map(issue => issue.name!!)
            .filter(function(elem, index, self) {
                return index === self.indexOf(elem);
            })
    }

    static getAllCatsInFocusArea = (focusArea: LibraryFocusArea | null | undefined): Array<LibraryCategory> => {
        return focusArea?.categories ?? []
    }

    static getAllSubCatsInCategory = (focusArea: LibraryFocusArea | null | undefined): Array<LibraryCategory> => {
        return focusArea?.categories ?? []
    }
}

export const GetAllIssuesInFocusArea = (focusArea: LibraryFocusArea | null | undefined): Array<LibraryIssue> => {
    return focusArea?.categories
            ?.map(cat => cat.subcategories)
            ?.flat()
            ?.map(subcat => subcat?.elements)
            ?.flat()
            ?.map(element => element?.issues)
            ?.flat()
            ?.filter(issue => issue) as Array<LibraryIssue>
        ?? []
}

export const IssuesToStrings = (issues : Array<LibraryIssue>) : string[] => {
    return issues.map(issue => issue.name!)
        .filter(function(elem, index, self) {
            return index === self.indexOf(elem);
        })
}
