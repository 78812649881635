type APIRedirectorProps = {
  history: any;
};
type APIRedirectorRoute = {
  errorCode: number;
  route: string;
};
type APIErrorHandlerConfig = {
  code: number;
  onError: () => void;
};
export class APIErrorHandler {
  configs: Array<APIErrorHandlerConfig>;
  codes: Array<number>;

  constructor(configs: Array<APIErrorHandlerConfig>) {
    this.configs = configs;
    this.codes = configs.map(config => {
      return config.code;
    });
  }

  onError(error: any) {
    if (error.response != null && error.response.status != null) {
      let code = error.response.status;

      if (this.codes.find(errorCode => {
        return errorCode === code;
      })) {
        let config = this.configs.find(config => {
          return config.code === code;
        });

        if (config != null) {
          config.onError();
        }
      }
    }

    return Promise.reject(error);
  }

}
export class APIErrorRedirector {
  propsFetch: () => APIRedirectorProps;
  apiErrorHandler: APIErrorHandler;

  constructor(propsForPush: () => any, routes: Array<APIRedirectorRoute>) {
    this.propsFetch = propsForPush;
    let configs: Array<APIErrorHandlerConfig> = routes.map(route => {
      return {
        code: route.errorCode,
        onError: () => {
          this._push(route.route);
        }
      };
    });
    this.apiErrorHandler = new APIErrorHandler(configs);
  }

  _push = (route: string) => {
    this.propsFetch().history.push(route);
  };

  onError(error: any) {
    return this.apiErrorHandler.onError(error);
  }

}
export function loginAPIErrorRedirector(providePropsFunction: () => any): APIErrorRedirector {
  //let routes = [{errorCode: 401, route: "/login"}];
  let routes : Array<APIRedirectorRoute> = [];
  return new APIErrorRedirector(providePropsFunction, routes);
}
