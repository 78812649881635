import React, { useEffect } from 'react'
import Announcements from '../feature/comms/Announcements'
import Tasks from '../feature/todo/Tasks'
import Ticket from '../feature/tickets/Ticket'
import {BrowserRouter, Redirect, Route, Switch, useHistory, withRouter} from 'react-router-dom'
import '../App.less'
import {TicketDetail} from '../feature/tickets/TicketDetail'
import CXLibrary from '../feature/cx/library/CXLibrary'
import {CXReportScreen} from '../feature/cx/library/CXReportScreen'
import {CXTemplatesScreen} from '../feature/cx/library/CXTemplatesScreen'
import {CXTemplateDetailScreen} from '../feature/cx/library/CXTemplateDetailScreen'
import {CXContextProvider} from '../context/CXContext'
import {CXSelectionContextProvider} from '../context/CXSelectionContext'
import {Layout} from 'antd'
import NewMenu from './NewMenu'
import {UserAPILoginCheck} from './UserAPILoginCheck'
import {Footer} from './Footer'
import WrappedNormalLoginForm from './Login'
import { UserStore } from '../data/store/UserStore'

function Container({
  // @ts-ignore
  location,
}) {
  const history = useHistory()

  useEffect(() => {
      const token = UserStore.getAzureToken()
      if (!token || token === '') history.push('login')
  }, [history])

  return (
    <section
      className="route-section"
      style={{
        height: '100%',
      }}>
      <CXContextProvider>
        <CXSelectionContextProvider>
          <Layout>
            <NewMenu />
            {/* <UserAPILoginCheck /> */}
            <Switch>
              <Route exact path="/" component={() => <Redirect to="/customerexperience" />} />
              <Route exact path="/login" component={WrappedNormalLoginForm} />
              <Route exact path="/messages" component={Announcements} />
              <Route exact path="/tickets" component={Ticket} />
              <Route exact path="/todos" component={Tasks} />
              <Route exact path="/ticketDetails" component={TicketDetail} />
              <Route exact path="/customerexperience" component={CXLibrary} />
              <Route exact path="/cxtemplates" component={CXTemplatesScreen} />
              <Route exact path="/cxtemplate/:templateId" component={CXTemplateDetailScreen} />
              <Route exact path="/cxreports" component={CXReportScreen} />
            </Switch>
            <Footer />
          </Layout>
        </CXSelectionContextProvider>
      </CXContextProvider>
    </section>
  )
}

export default withRouter(Container)
