import React, {useContext, useEffect, useState} from "react"
import {CXSelectionContext} from "../context/CXSelectionContext"
import {Issue, LibraryElement} from "../data/api/generated/EmpowerSwaggerApi"
import {
    CheckboxText,
    CheckImageContainer,
    ChevronImageContainer,
    CollapseContainer,
    DefaultButton, EditImageContainer,
    GreyText,
    HorizontalFlexContainer,
    HorizontalLine
} from "../feature/cx/library/CXStyleSheet"
import redCheck from "../img/check-red.png"
import emptyCheck from "../img/checkEmpty.png"
import {RenderMediaIcon} from "./MediaIcon"
import chevronUp from "../img/chevron-up-grey.png"
import chevronDown from "../img/chevron-down-grey.png"
import add from "../img/add.png"
import {CXModalAddIssue} from "../feature/cx/library/CXModalAddIssue"
import {RenderIssue} from "./RenderIssue"
import {CxModalEditFocusAreaItems} from "../feature/cx/library/CXModalEditFocusAreaData"
import editIcon from "../img/edit.png"
import {ImageButton} from "./ImageButton"

interface RenderElementProps {
    element: LibraryElement
    hasCurrentTemplate: boolean
    subcategoryId: string
    isRepeat: boolean
}

interface RenderElementState {
    showIssue: boolean
    isElementChecked: boolean
    showModalIssue: boolean
    requiresComment: boolean
    requiresVideo: boolean
    requiresImage: boolean
}

const initialState = {
    isElementChecked: false,
    requiresComment: false,
    requiresImage: false,
    requiresVideo: false,
    showIssue: false,
    showModalIssue: false
}

export const RenderElements: React.FC<RenderElementProps> = (props) => {
    const {element, hasCurrentTemplate, subcategoryId, isRepeat} = props
    const selectionContext = useContext(CXSelectionContext)
    const [state, setState] = useState<RenderElementState>(initialState)
    const [showModal, setShowModal] = useState<'editElement' | null>(null)

    const setCurrentState = (key: string, value: any) => {
        setState(prevState => ({
            ...prevState,
            [key]: value
        }))
    }

    const evidenceToggleHandler = (key: string, isRequired: boolean) => {
        const issuesToUpdate = element.issues!
            // .filter(issue => selectionContext.isSelectedIssue(issue))
            .filter(issue => issue)
            .map(issue => issue.id as string) ?? []

        selectionContext.toggleIssues(
            issuesToUpdate,
            true,
            key === 'requiresImage' ? isRequired : state.requiresImage,
            key === 'requiresVideo' ? isRequired : state.requiresVideo,
            key === 'requiresComment' ? isRequired : state.requiresComment
        )
    }

    useEffect(() => {
        if (!hasCurrentTemplate) return
        const evidenceCommentRequired = element.issues!.filter((issue: Issue) => issue!.evidenceCommentRequired!).length > 0
        const evidenceImageRequired = element.issues!.filter((issue: Issue) => issue!.evidenceImageRequired!).length > 0
        const evidenceVideoRequired = element.issues!.filter((issue: Issue) => issue!.evidenceVideoRequired!).length > 0
        setState(prevState => ({
            ...prevState,
            requiresComment: evidenceCommentRequired,
            requiresImage: evidenceImageRequired,
            requiresVideo: evidenceVideoRequired,
        }))
    }, [hasCurrentTemplate, element.issues])

    useEffect(() => {
        if (hasCurrentTemplate) return
        if (!element.id) return
        setState(prevState => ({
            ...prevState,
            isElementChecked: selectionContext.isSelectedElement(element.id!!),
            requiresComment: selectionContext.elementRequiresComment(element.id!!),
            requiresImage: selectionContext.elementRequiresImage(element.id!!),
            requiresVideo: selectionContext.elementRequiresVideo(element.id!!),
        }))
    }, [element.id, hasCurrentTemplate, selectionContext, state.isElementChecked])

    return (
        <>
            <CollapseContainer
                style={{backgroundColor: state.showIssue ? ("#fafafa") : ("transparent"), paddingLeft: 10}}>
                <HorizontalFlexContainer style={{flex: 1}}>
                    {!hasCurrentTemplate && <CheckImageContainer
                      onClick={() => {
                          const value = !state.isElementChecked
                          selectionContext.toggleElement(
                              element.id!!,
                              value,
                              state.requiresImage,
                              state.requiresVideo,
                              state.requiresComment
                          )
                      }}
                      src={state.isElementChecked ? (redCheck) : (emptyCheck)}
                    />}
                    <CheckboxText
                        style={{cursor: 'pointer', flex: 1}}
                        onClick={() => setCurrentState('showIssue', !state.showIssue)}>
                        {element.name}</CheckboxText>
                </HorizontalFlexContainer>
                <HorizontalFlexContainer>
                    <RenderMediaIcon
                        initialState={state.requiresComment}
                        onToggled={(value) => evidenceToggleHandler('requiresComment', value)}
                        canToggle={!hasCurrentTemplate}
                    />
                    <RenderMediaIcon
                        initialState={state.requiresImage}
                        onToggled={(value) => evidenceToggleHandler('requiresImage', value)}
                        canToggle={!hasCurrentTemplate}
                    />
                    {/*<RenderMediaIcon*/}
                    {/*    initialState={state.requiresVideo}*/}
                    {/*    onToggled={(value) => evidenceToggleHandler('requiresVideo', value)}*/}
                    {/*    canToggle={!hasCurrentTemplate}*/}
                    {/*/>*/}
                    <HorizontalFlexContainer style={{marginLeft: 15}}>
                        {!hasCurrentTemplate &&<EditImageContainer style={{right: 15}} onClick={() => setShowModal('editElement')}>
                            <img src={editIcon} alt={"edit-icon"} width={10} height={10}/>
                        </EditImageContainer>}
                        <ChevronImageContainer
                            onClick={() => setCurrentState('showIssue', !state.showIssue)}
                            src={state.showIssue ? (chevronUp) : (chevronDown)}/>
                    </HorizontalFlexContainer>
                </HorizontalFlexContainer>
            </CollapseContainer>
            <HorizontalLine/>
            {state.showIssue && <GreyText style={{marginLeft: 38, marginTop: 10}}>Issues</GreyText>}
            {
                state.showIssue
                && element.issues !== undefined
                && element.issues?.map((issue, index) => <RenderIssue
                    key={index}
                    issues={issue}
                    requiresComment={state.requiresComment}
                    requiresVideo={state.requiresVideo}
                    requiresImage={state.requiresImage}
                    hasCurrentTemplate={hasCurrentTemplate}
                    elementId={element.id!}
                />)
            }
            {!hasCurrentTemplate && state.showIssue && <div style={{padding: '20px 30px'}}>
                <ImageButton
                text={'Add Issue'}
                imagePosition={'left'}
                image={{src: add, height: 14, width: 14, alt: 'add-icon'}}
                onClick={() => setCurrentState('showModalIssue', !state.showModalIssue)}
                />
            </div>}
            <CXModalAddIssue elementId={element!!.id!!} show={state.showModalIssue}
                             onClose={() => setState(prevState => ({...prevState, showModalIssue: false}))}/>
            <CxModalEditFocusAreaItems id={element.id!} name={element.name!} type={'Element'}
                                       show={showModal === 'editElement'}
                                       additionalData={{subcategoryId, isRepeat}}
                                       onClose={() => setShowModal(null)}/>
        </>
    )
}
