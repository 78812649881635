import React, {Component} from "react"
import {Button, Layout, Modal, Popconfirm, Spin, Table} from "antd"
import Icon from '@ant-design/icons'
import "../../App.less"
import {EmpowerAnnouncementAPI} from "../../data/api/EmpowerAnnouncementAPI"
import {CreateAnnouncementForm} from "./CreateAnnouncementForm"
import {AnnouncementDetail} from "./AnnouncementDetail"
import {AttachmentDescriptor} from "../../components/AttachmentUploader"
import moment from "moment"
import {DateUtil} from "../../util/DateUtil"
import {fetchMoreIfNecessary} from "../../data/api/APIConfig"
import {loginAPIErrorRedirector} from "../../data/api/APIErrorRedirector"

const {
    Header,
    Content
} = Layout
type CommsFormData = {
    body: string
    subject: string
    groupRecipients: Array<string>
    attachments: Array<AttachmentDescriptor>
    shouldPublish: boolean
}
type CommsMailData = {
    name: string
    subject: string
    date: string
}
type CommsTableData = {
    key: number
    name: string
    subject: string
    date?: string
    announcementId: string
}
type CommsProps = {
    history: any
}
type CommsState = {
    dataSource: Array<CommsTableData>
    detailVisible: boolean
    announcementVisible: boolean
    currentMail: Array<CommsMailData>
    busy: boolean
    announcementDetail: string
    totalAnnouncements: number
    authFailed: boolean
}

class Announcements extends Component<CommsProps, CommsState> {
    constructor(props: CommsProps) {
        super(props)
        this.state = {
            dataSource: [],
            detailVisible: false,
            announcementVisible: false,
            busy: true,
            announcementDetail: "",
            totalAnnouncements: 0,
            authFailed: false,
            currentMail: [{
                name: "",
                subject: "",
                date: ""
            }]
        }
        this.fetchAnnouncements()
    }

    handleModalOk = () => {
        this.setState({
            detailVisible: false
        })
    }

    fetchAnnouncements(index: number = 0, isRefresh: boolean = false) {
        this.setState({
            busy: true
        })
        var promise

        if (isRefresh) {
            promise = EmpowerAnnouncementAPI.refreshAnnouncements(this.state.dataSource.length)
        } else {
            promise = EmpowerAnnouncementAPI.fetchAnnouncements(index)
        }

        promise.catch(error => {
            this.setState({
                authFailed: true
            })
            return loginAPIErrorRedirector(() => {
                return this.props
            }).onError(error)
        }).then(value => {
            let data: Array<CommsTableData> = value.data.sentAnnouncements.map((announcement, index) => {
                var date = undefined

                if (announcement.datePublished != null) {
                    date = DateUtil.formatEmpowerDate(announcement.datePublished)
                }

                return {
                    key: index,
                    name: announcement.from,
                    subject: announcement.subject,
                    date: date,
                    announcementId: announcement.announcementId
                }
            })

            if (data != null) {
                if (index > 0 && !isRefresh) {
                    this.setState({
                        totalAnnouncements: value.data.totalItemCount,
                        dataSource: this.state.dataSource.concat(data),
                        busy: false
                    })
                } else {
                    this.setState({
                        totalAnnouncements: value.data.totalItemCount,
                        dataSource: data,
                        busy: false
                    })
                }
            } else {
                this.setState({
                    busy: false
                })
            }
        })
    }

    fetchMore() {
        this.fetchAnnouncements(this.state.dataSource.length)
    }

    deleteAnnouncement(id: string) {
        this.setState({
            busy: true,
            detailVisible: false,
            announcementVisible: false
        })
        EmpowerAnnouncementAPI.deleteAnnouncment(id).catch(error => {
            this.setState({
                authFailed: true
            })
            return loginAPIErrorRedirector(() => {
                return this.props
            }).onError(error)
        }).then(() => {
            this.fetchAnnouncements(0, true)
        })
    }

    togglePublish(record: CommsTableData) {
        this.setState({
            busy: true
        })

        if (record.date != null) {
            EmpowerAnnouncementAPI.unpublishAnnouncement(record.announcementId).catch(error => {
                this.setState({
                    authFailed: true
                })
                return loginAPIErrorRedirector(() => {
                    return this.props
                }).onError(error)
            }).then(() => {
                this.fetchAnnouncements(0, true)
            })
        } else {
            EmpowerAnnouncementAPI.publishAnnouncement(record.announcementId).catch(error => {
                this.setState({
                    authFailed: true
                })
                return loginAPIErrorRedirector(() => {
                    return this.props
                }).onError(error)
            }).then(() => {
                this.fetchAnnouncements(0, true)
            })
        }
    }

    showModal = (announcementId: string) => {
        this.setState({
            detailVisible: true,
            announcementDetail: announcementId
        })
    }
    createAnnouncementModal = () => {
        this.setState({
            announcementVisible: true
        })
    }
    handleAnnouncementModalOk = (e: any) => {
        this.setState({
            announcementVisible: false
        })
    }
    createComms = (formData: CommsFormData) => {
        this.setState({
            announcementVisible: false,
            busy: true
        })
        var attachments = undefined

        if (formData.attachments != null) {
            attachments = formData.attachments.map(attachment => {
                return attachment.id
            })
        }

        var status = 3

        if (formData.shouldPublish) {
            status = 4
        }

        EmpowerAnnouncementAPI.createAnnouncement({
            announcementStatusId: status,
            subject: formData.subject,
            body: formData.body,
            // @ts-ignore
            attachmentIds: attachments,
            recipients: formData.groupRecipients.map(value => {
                return {
                    recipientId: value,
                    recipientType: "2"
                }
            })
        }).catch(error => {
            this.setState({
                authFailed: true
            })
            return loginAPIErrorRedirector(() => {
                return this.props
            }).onError(error)
        }).then(() => {
            this.setState({
                announcementVisible: false,
                dataSource: []
            })
            this.fetchAnnouncements()
        })
    }
    paginationConfig = () => {
        return {
            pageSize: 25,
            total: this.state.totalAnnouncements,
            onChange: (page: number, pageSize: number) => {
                fetchMoreIfNecessary(page, pageSize, this.state.dataSource.length, this.state.totalAnnouncements, () => {
                    this.fetchMore()
                })
            }
        }
    }

    render() {
        const {
            dataSource
        } = this.state
        // @ts-ignore
        const columns = this.columnConfig().map(col => {
            return {
                ...col
            }
        })

        return (
            <Layout>
                <Header className="header1">
                    <div>
                        <h1>HQ Comms</h1>
                    </div>
                    <div className="button-container">
                        <Button className="btn-Announcement"
                                onClick={this.createAnnouncementModal}>
                            <Icon type="plus"/>
                            New HQ Comms
                        </Button>
                        <Modal title={<span style={{
                            textAlign: "center"
                        }}>New HQ Comms</span>} bodyStyle={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            paddingRight: 0
                        }} visible={this.state.announcementVisible} onCancel={this.handleAnnouncementModalOk}
                               className="announcement-modal">
                            <CreateAnnouncementForm onFormSubmit={this.createComms}/>
                        </Modal>
                        <Modal visible={this.state.detailVisible} bodyStyle={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            paddingRight: 0
                        }} onOk={this.handleModalOk} onCancel={this.handleModalOk} footer={[<div style={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                            <p className="date" style={{
                                position: "relative",
                                top: "7px",
                                marginBottom: "0px"
                            }}/>
                            <div className="rows">
                                <div className="row">
                                    <Popconfirm className="btn-delete-record"
                                        // @ts-ignore
                                                size="default"
                                                title="Are you sure delete this comms?" onConfirm={() => {
                                        this.deleteAnnouncement(this.state.announcementDetail)
                                    }} onCancel={() => {
                                    }} okText="Yes" cancelText="No">
                                        <Button style={{
                                            marginTop: "8px"
                                        }} className="btn-delete-record" href="#">
                                            Delete
                                        </Button>
                                    </Popconfirm>
                                </div>
                                <div className="row" style={{
                                    marginLeft: 10
                                }}>
                                    <Button key="back" onClick={this.handleModalOk}>
                                        Back
                                    </Button>
                                </div>
                            </div>
                        </div>]}>
                            {this.state.detailVisible &&
                              <AnnouncementDetail announcementId={this.state.announcementDetail}/>}
                        </Modal>
                    </div>
                </Header>
                <Spin style={{
                    alignSelf: "center",
                    minHeight: 400
                }} spinning={this.state.busy} size="large">
                    <div className="content-container" style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: "100%"
                    }}>
                        {this.state.dataSource != null && this.state.dataSource.length > 0 &&
                          <Content className="table-container">
                            <div className="table">
                              <Table bordered columns={columns}
                                     dataSource={dataSource}
                                  // @ts-ignore
                                     pagination={this.paginationConfig()}/>
                            </div>
                          </Content>}

                        {this.state.dataSource != null && this.state.dataSource.length > 0 && <Content>
                          <div className="filter-nav" style={{
                              background: "#fff"
                          }}/>
                        </Content>}
                    </div>
                </Spin>
            </Layout>
        )
    }

    currentColumnConfig: any
    columnConfig = () => {
        if (this.currentColumnConfig != null) {
            return this.currentColumnConfig
        }

        this.currentColumnConfig = [{
            title: "Name",
            dataIndex: "name",
            sorter: (a: any, b: any) => a.name.length - b.name.length,
            onCell: (record: CommsTableData) => {
                return {
                    onClick: () => {
                        this.showModal(record.announcementId)
                    }
                }
            },
            render: (text: string, record: any) => {
                return this.state.dataSource.length >= 1 ? <div>{record.name}</div> : null
            }
        }, {
            title: "Subject",
            dataIndex: "subject",
            defaultSortOrder: "descend",
            onCell: (record: CommsTableData) => {
                return {
                    onClick: () => {
                        this.showModal(record.announcementId)
                    }
                }
            }
        }, {
            title: "Date",
            className: "Date-Header",
            dataIndex: "date",
            sorter: (a: any, b: any) => moment(a).isAfter(b),
            onCell: (record: CommsTableData) => {
                return {
                    onClick: () => {
                        this.showModal(record.announcementId)
                    }
                }
            }
        }, {
            title: "",
            render: (text: string, record: CommsTableData) => {
                return <div>
                    {(record.date === undefined || record.date === null || record.date === "") &&
                      <Button className="btn-publish-record" onClick={() => {
                          this.togglePublish(record)
                      }}>
                        Publish
                      </Button>}
                    {record.date != null && <Button className="btn-publish-record" onClick={() => {
                        this.togglePublish(record)
                    }}>
                      Unpublish
                    </Button>}
                    <Popconfirm className="btn-delete-record"
                        // @ts-ignore
                                size="default"
                                title="Are you sure delete this comms?"
                                onConfirm={() => {
                                    this.deleteAnnouncement(record.announcementId)
                                }} onCancel={() => {
                    }} okText="Yes" cancelText="No">
                        <Button style={{
                            marginTop: "8px"
                        }} className="btn-delete-record" href="#">
                            Delete
                        </Button>
                    </Popconfirm>
                </div>
            }
        }]
        return this.currentColumnConfig
    }
}

export default Announcements
