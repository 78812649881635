import { EmpowerAPIConfig } from "./APIConfig";
import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import { UserStore } from "../store/UserStore";

const getClient = (baseUrl = EmpowerAPIConfig.baseURL, authorized = true, customOptions: any): AxiosInstance => {
  const options: AxiosRequestConfig = {
    baseURL: baseUrl
  };

  if (authorized && UserStore.getAzureToken() != null) {
    let token = UserStore.getAzureToken();
    options.headers = {
      Authorization: `${token}`,
      ...customOptions
    };
  } else if (customOptions != null) {
    options.headers = customOptions;
  }

  const client = axios.create(options);
  // Add a request interceptor
  client.interceptors.request.use(requestConfig => {
    console.log("Making Request:\n" + requestConfig.baseURL + " : " + requestConfig.url);
    return requestConfig;
  }, requestError => {
    return Promise.reject(requestError);
  });
  // Add a response interceptor
  client.interceptors.response.use(response => {
    console.log("Got Response:\n" + response.config.url + " with code: " + response.status);
    return response;
  }, error => {
    return Promise.reject(error);
  });
  return client;
};

export const noAuthClient = () => new APIClient(EmpowerAPIConfig.baseURL, false);
export const customHeaderClient = (customHeaders: any) => new APIClient(EmpowerAPIConfig.baseURL, true, customHeaders);

export interface APIResponse<T> {
  data : T
}

class APIClient {
  client: AxiosInstance;

  constructor(baseUrl: string = EmpowerAPIConfig.baseURL, authenticated: boolean = true, customOptions: any = null) {
    this.client = getClient(baseUrl, authenticated, customOptions);
  }

  get<T>(url: string, conf: any = {}): Promise<APIResponse<T>> {
    // @ts-ignore
    return this.client
        .get(url, conf)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error));
  }

  delete<T>(url: string, conf: any = {}): Promise<APIResponse<T>> {
    // @ts-ignore
    return this.client.delete(url, conf).then(response => Promise.resolve(response)).catch(error => Promise.reject(error));
  }

  head<T>(url: string, conf: any = {}): Promise<APIResponse<T>> {
    // @ts-ignore
    return this.client.head(url, conf).then(response => Promise.resolve(response)).catch(error => Promise.reject(error));
  }

  options<T>(url: string, conf: any = {}): Promise<APIResponse<T>> {
    // @ts-ignore
    return this.client.options(url, conf).then(response => Promise.resolve(response)).catch(error => Promise.reject(error));
  }

  post<T>(url: string, data: any = {}, conf: any = {}): Promise<APIResponse<T>> {
    // @ts-ignore
    return this.client.post(url, data, conf).then(response => Promise.resolve(response)).catch(error => Promise.reject(error));
  }

  put<T>(url: string, data: any = {}, conf: any = {}): Promise<APIResponse<T>> {
    // @ts-ignore
    return this.client.put(url, data, conf).then(response => Promise.resolve(response)).catch(error => Promise.reject(error));
  }

  patch<T>(url: string, data: any = {}, conf: any = {}): Promise<APIResponse<T>> {
    // @ts-ignore
    return this.client.patch(url, data, conf).then(response => Promise.resolve(response)).catch(error => Promise.reject(error));
  }

}

export { APIClient };
