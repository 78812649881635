import { Divider, Spin, Collapse, List } from "antd";
import React from "react";
import { EmpowerTasksAPI } from "../../data/api/EmpowerTasksAPI";
import { EmpowerAPIResponse, SubTaskResponse, TaskDetailsResponse } from "../../data/api/APITypes";
import { AttachmentListing } from "../../components/AttachmentListing";
import { DateUtil } from "../../util/DateUtil";
type TaskDetailProps = {
  taskId: string;
};
type TaskDetailState = {
  isLoadingDetails: boolean;
  task: TaskDetailsResponse | null | undefined;
  completeRecipients?: Array<SubTaskResponse> | null | undefined;
  incompleteRecipients?: Array<SubTaskResponse> | null | undefined;
};
export class TaskDetail extends React.Component<TaskDetailProps, TaskDetailState> {
  constructor(props: TaskDetailProps) {
    super(props);
    this.state = {
      isLoadingDetails: true,
      task: null,
      completeRecipients: null,
      incompleteRecipients: null
    };
    this.fetchTask();
  }

  componentDidUpdate(prevProps: TaskDetailProps, prevState: TaskDetailState, snapshot: any) {
    if (prevProps.taskId !== this.props.taskId) {
      this.setState({
        isLoadingDetails: true,
        task: null
      });
      this.fetchTask();
    }
  }

  fetchTask() {
    EmpowerTasksAPI.fetchTaskDetails(this.props.taskId).then((response: EmpowerAPIResponse<TaskDetailsResponse>) => {
      let completeTasks = response.data.recipients.filter((subTask: SubTaskResponse) => {
        return subTask.isCompleted;
      });
      let incompleteTasks = response.data.recipients.filter((subTask: SubTaskResponse) => {
        return !subTask.isCompleted;
      });
      this.setState({
        isLoadingDetails: false,
        task: response.data,
        completeRecipients: completeTasks,
        incompleteRecipients: incompleteTasks
      });
    }).catch(() => {
      this.setState({
        isLoadingDetails: false
      });
    });
  }

  render() {
    return <Spin spinning={this.state.isLoadingDetails} style={{
      alignSelf: "center",
      minHeight: 400
    }} size="large">
        <div style={{
        alignSelf: "center",
        minHeight: 400
      }}>
          {this.state.task != null && <div>
              <div className="item-detail-top-bar">
                <div className="item-detail-text-container">
                  <h2>{this.state.task.subject}</h2>
                  <h4>{this.state.task.from}</h4>
                </div>
                <Divider />
              </div>
              <div className="item-detail-content">
                <h2>Details: </h2>
                <h3>{this.state.task.body}</h3>
                <Divider />
                <h2>Due Date: </h2>
                <h4>{DateUtil.formatEmpowerDate(this.state.task.dueDate)}</h4>
                {this.state.task != null && this.state.task.attachments != null && this.state.task.attachments.length > 0 && <div>
                  <Divider />
                  <AttachmentListing attachments={this.state.task.attachments} />
                </div>}
              </div>
              <div style={{
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: 12
          }}>
                <Divider />
                <h2>Recipients</h2>
                <Collapse>
                  <Collapse.Panel key={"completed"} header={"Complete Recipients"}>
                    {this.state.completeRecipients != null && this.state.completeRecipients.length > 0 && <List size="small" dataSource={this.state.completeRecipients} renderItem={(item: SubTaskResponse) => <List.Item>{item.recipient.recipientName}</List.Item>} />}
                    {(this.state.completeRecipients === null || (this.state.completeRecipients != null && this.state.completeRecipients.length === 0)) && <h2>No Recipients have Completed this Task</h2>}
                    </Collapse.Panel>
                  <Collapse.Panel key={"incompleted"} header={"Incomplete Recipients"}>
                    {this.state.incompleteRecipients != null && this.state.incompleteRecipients.length > 0 && <List size="small" dataSource={this.state.incompleteRecipients} renderItem={(item: SubTaskResponse) => <List.Item>{item.recipient.recipientName}</List.Item>} />}
                    {(this.state.incompleteRecipients === null || (this.state.incompleteRecipients != null && this.state.incompleteRecipients.length === 0)) && <h2>This task is Complete</h2>}
                  </Collapse.Panel>
                </Collapse>
              </div>
            </div>}
        </div>
      </Spin>;
  }

}
