import React, {useContext, useEffect, useState} from "react"
import {Modal} from "antd"
import {
    BodyText, ErrorText,
    ModalCancelButton, ModalConfirmButton,
    ModalHeading,
    StyledInput,
    VerticalFlexContainer
} from "./CXStyleSheet"
import {useForm} from "react-hook-form"
import {CXContext, CXContextType} from "../../../context/CXContext"

interface CXModalAddCategoryProps {
    show: boolean
    onClose?: () => void
}

export const CXModalAddCategory: React.FC<CXModalAddCategoryProps> = (props) => {
    const {show, onClose} = props
    const cxContext = useContext<CXContextType>(CXContext)
    const [visible, setVisible] = useState(false)

    const {
        handleSubmit,
        register,
        reset,
        formState: {errors}
    } = useForm()

    const onSubmit = (data: any) => {
        cxContext.api.createOrUpdateCategory(data.categoryInput)
        handleCancel()
    }

    if (errors.categoryInput) {
        console.log(errors.categoryInput)
    }

    const handleCancel = () => {
        onClose && onClose()
        reset({categoryInput: ''})
    }

    const renderModalFooter = () => {
        return (
            <div>
                <span>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <div>
                            <ModalCancelButton onClick={handleCancel}>Cancel</ModalCancelButton>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <ModalConfirmButton type="submit">
                                Add
                            </ModalConfirmButton>
                        </form>
                    </div>
                </span>
            </div>
        )
    }

    useEffect(() => {
        return setVisible(show)
    }, [show])

    return (
        <Modal
            visible={visible}
            centered
            footer={renderModalFooter()}>

            <VerticalFlexContainer>

                <ModalHeading>Add Category</ModalHeading>
                <BodyText>Category Name</BodyText>
                <StyledInput
                    {...register("categoryInput", {
                        required: "Enter Category name."
                    })}
                    style={{marginTop: 5, height: 42}}
                    name="categoryInput"
                    id="categoryInput"
                    type="text"
                    placeholder={'E.g. Front of House'}
                />
                {errors.categoryInput && <ErrorText>{errors.categoryInput.message}</ErrorText>}

            </VerticalFlexContainer>

        </Modal>
    )
}
