import React from "react"
import {ReportingGreyBody} from "./CXStyleSheet"

interface ProgressBarProps {
    numberOfIssues: number
}

export const ProgressBar: React.FC<ProgressBarProps> = (props) => {

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            height: 20,
            width: '100%',
            borderRadius: 2,
        }}>

            <div style={{
                height: '100%',
                width: `${props.numberOfIssues}%`,
                marginRight: 5,
                backgroundImage: `linear-gradient(to right, #b51e24, #DA2131)`,
                borderRadius: 'inherit',
                textAlign: 'right',
                transition: 'width 1s ease-in-out'
            }}>
            </div>
            <ReportingGreyBody>{props.numberOfIssues}</ReportingGreyBody>
        </div>
    )

}
