import React, {useContext, useEffect, useState} from "react"
import {Modal, Popconfirm} from "antd"
import {
    BodyText,
    ErrorText,
    GreyText,
    IconContainer,
    ModalCancelButton,
    ModalConfirmButton,
    ModalHeading,
    StyledInput,
    VerticalFlexContainer
} from "./CXStyleSheet"
import {useForm} from "react-hook-form"
import {LibraryFocusArea} from "../../../data/api/generated/EmpowerSwaggerApi"
import {CXContext} from "../../../context/CXContext"
import deleteIcon from "../../../img/delete.png"

interface RenderFocusAreaProps {
    focusArea: LibraryFocusArea | null | undefined
    show: boolean
    onClose?: () => void
}

export const CXModalFocusAreaSettings: React.FC<RenderFocusAreaProps> = (props) => {
    const {show, onClose} = props
    const cxContext = useContext(CXContext)
    const currentFocusArea = cxContext.activeFocusArea

    const {
        handleSubmit,
        register,
        reset,
        formState: {errors},
    } = useForm()

    const onSubmit = (data: any) => {
        cxContext.api.updateFocusArea(
            currentFocusArea?.id!!,
            data.focusAreaInput,
            data.abbreviationInput
        )
        handleCancel()
    }

    if (errors.focusAreaInput) {
        console.log(errors.focusAreaInput)
    }
    if (errors.abbreviationInput) {
        console.log(errors.abbreviationInput)
    }

    const [visible, setVisible] = useState(false)

    const handleCancel = () => {
        onClose && onClose()
    }
    const renderModalFooter = () => {
        return (
            <div>
                <span>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <div>
                            <ModalCancelButton onClick={handleCancel}>Cancel</ModalCancelButton>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <ModalConfirmButton type="submit">
                                Update
                            </ModalConfirmButton>
                        </form>
                    </div>
                </span>
            </div>
        )
    }

    const deleteFocusAreaHandler = () => {
        cxContext.api.deleteFocusArea(currentFocusArea?.id!)
        handleCancel()
    }

    const deleteConfirm = (
        <IconContainer style={{borderColor: '#E0E0E0', position: 'absolute', top: 25, right: 25}}>
            <Popconfirm
                title={"Are you sure you want to delete this focus area?"}
                style={{display: "flex"}}
                placement={"bottom"}
                onConfirm={deleteFocusAreaHandler}
            >
                <div style={{flex: 1}}>
                    <img style={{marginLeft: 10, marginTop: 10, height: 14, width: 14}} src={deleteIcon}
                         alt={"delete-icon"}/>
                </div>
            </Popconfirm>
        </IconContainer>
    )

    useEffect(() => {
        return setVisible(show)
    }, [show])

    useEffect(() => {
        if (!currentFocusArea) return
        reset({focusAreaInput: currentFocusArea.name, abbreviationInput: currentFocusArea.abbreviation})
    }, [currentFocusArea])

    return (
        <Modal
            visible={visible}
            centered
            footer={renderModalFooter()}>

            <VerticalFlexContainer>
                <ModalHeading>Focus Area Settings</ModalHeading>
                {deleteConfirm}
                <BodyText>Focus Area Name</BodyText>
                <StyledInput
                    {...register("focusAreaInput", {
                        required: "Enter Focus Area Name."
                    })}
                    style={{marginBottom: 24}}
                    type="text"
                />
                {errors.focusAreaInput && <ErrorText>{errors.focusAreaInput.message}</ErrorText>}

                <BodyText>Abbreviation</BodyText>
                <StyledInput
                    {...register("abbreviationInput", {
                        required: "Enter Abbreviation.",
                        minLength: {
                            value: 2,
                            message: "2 to 3 letters"
                        },
                    })}
                    style={{width: 100}}
                    type="text"
                    maxLength={3}/>
                {errors.abbreviationInput ?
                    (<ErrorText>{errors.abbreviationInput.message}</ErrorText>) :
                    (<GreyText>2 to 3 letters</GreyText>)
                }

            </VerticalFlexContainer>

        </Modal>
    )
}
