import { CreateTaskRequest, EmpowerAPIResponse, TaskDetailsResponse, GetTaskPrioritiesResponse, GetTasksResponse, GetTaskStatusesResponse } from "./APITypes";
import { apiDelete, apiGet, apiPatch, apiPost, calculateNextPageNumber, EmpowerAPIConfig } from "./APIConfig";
export class EmpowerTasksAPI {
  static Endpoints = {
    fetchTasks: (pageNumber: number) => "/api/v1/tasks/sent?pageSize=" + EmpowerAPIConfig.defaultPageSize + "&pageNumber=" + pageNumber + "&sortField=dueDate",
    refreshTasks: (totalItemCount: number) => "/api/v1/tasks/sent?pageSize=" + EmpowerAPIConfig.defaultPageSize + "&sortField=dueDate",
    fetchTaskPriorities: () => "/api/v1/tasks/priorities",
    fetchTaskStatuses: () => "/api/v1/task/statuses",
    fetchTaskDetails: (id: string) => "/api/v1/tasks/" + id,
    deleteTask: (id: string) => "/api/v1/tasks/" + id,
    createTask: () => "/api/v1/tasks",
    updateTaskStatus: (id: string) => "/api/v1/tasks/" + id + "/status",
    toggleTaskCompletion: (id: string) => "/api/v1/tasks/" + id + "/toggleCompletion"
  };
  static fetchTasks = (startIndex: number = 0): Promise<EmpowerAPIResponse<GetTasksResponse>> => apiGet(EmpowerTasksAPI.Endpoints.fetchTasks(calculateNextPageNumber(startIndex)));
  static refreshTasks = (totalIndex: number = 0): Promise<EmpowerAPIResponse<GetTasksResponse>> => apiGet(EmpowerTasksAPI.Endpoints.refreshTasks(totalIndex));
  // @ts-ignore
  static createNewTask = (request: CreateTaskRequest): Promise<any> => apiPost(EmpowerTasksAPI.Endpoints.createTask(), request);
  static fetchTaskStatuses = (): Promise<EmpowerAPIResponse<GetTaskStatusesResponse>> => apiGet(EmpowerTasksAPI.Endpoints.fetchTaskStatuses());
  // @ts-ignore
  static fetchTaskPriorities = (): Promise<GetTaskPrioritiesResponse> => apiGet(EmpowerTasksAPI.Endpoints.fetchTaskPriorities());
  static fetchTaskDetails = (taskId: string): Promise<EmpowerAPIResponse<TaskDetailsResponse>> => apiGet(EmpowerTasksAPI.Endpoints.fetchTaskDetails(taskId));
  static deleteTasks = (taskId: string) => apiDelete(EmpowerTasksAPI.Endpoints.deleteTask(taskId));
  static updateTaskStatus = (taskId: string, taskStatusId: number): Promise<any> => apiPatch(EmpowerTasksAPI.Endpoints.updateTaskStatus(taskId), {
    taskStatusId: taskStatusId
  });
  static toggleTaskCompletion = (taskId: string): Promise<any> => apiPatch(EmpowerTasksAPI.Endpoints.toggleTaskCompletion(taskId));
}
