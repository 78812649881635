import React, {useContext, useEffect, useState} from "react"
import {Modal} from "antd"
import {
    BodyText,
    BodyTextBold,
    CheckboxText,
    CheckImageContainer,
    Container,
    ErrorText,
    GreyText,
    HorizontalFlexContainer,
    ModalCancelButton,
    ModalConfirmButton,
    ModalHeading,
    RadioCircleImageContainer,
    StyledInput,
    VerticalFlexContainer
} from "./CXStyleSheet"
import {useForm} from "react-hook-form"
import emptyCheck from "../../../img/checkEmpty.png"
import greyCheck from "../../../img/check-grey.png"
import emptyRadio from "../../../img/radio-empty.png"
import greyRadio from "../../../img/radio-grey.png"
import {CXContext} from "../../../context/CXContext"

interface CXModalAddFocusAreaProps {
    show: boolean
    onClose?: () => void
}

export const CXModalAddFocusArea: React.FC<CXModalAddFocusAreaProps> = (props) => {
    const {show, onClose} = props

    const cxContext = useContext(CXContext)

    const [visible, setVisible] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [decisionType, setDecisionType] = useState("basic")
    const [actionWord, setActionWord] = useState<string>("")

    const isBasicDecisionType = decisionType === "basic"
    const isConfirmationDecisionType = decisionType === "confirmation"
    const onDecisionTypeToggle = (updatedDecisionType: string) => {
        if (updatedDecisionType !== decisionType) {
            setDecisionType(updatedDecisionType)
        }
    }

    const {
        handleSubmit,
        register,
        reset,
        formState: {errors}
    } = useForm()

    const onSubmit = (data: any) => {
        cxContext.api.createFocusArea({
            focusName: data.customerExperience,
            focusAbbrev: data.abbreviationInput,
            decisionType: decisionType === "basic" ? "Basic" : "Confirmation",
            allComplete: isChecked,
            discussionWord: data.actionWord,
            positiveWord: data.positiveWord,
            negativeWord: data.negativeWord
        })
        handleCancel()
    }
    if (errors.focusAreaInput) {
        console.log(errors.focusAreaInput)
    }
    if (errors.abbreviationInput) {
        console.log(errors.abbreviationInput)
    }
    if (errors.positiveWord) {
        console.log(errors.positiveWord)
    }
    if (errors.negativeWord) {
        console.log(errors.negativeWord)
    }
    if (errors.actionWord) {
        console.log(errors.actionWord)
    }

    const handleCancel = () => {
        onClose && onClose()
        reset({customerExperience: '', abbreviationInput: '', positiveWord: '', negativeWord: '', actionWord: ''})
    }

    const renderModalFooter = () => {
        return (
            <div>
                <span>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <div>
                            <ModalCancelButton onClick={handleCancel}>Cancel</ModalCancelButton>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <ModalConfirmButton type="submit">
                                Save
                            </ModalConfirmButton>
                        </form>
                    </div>
                </span>
            </div>
        )
    }

    const RenderCheckbox = () => {
        return (
            <>
                <BodyText style={{fontWeight: 700, marginTop: 24, marginBottom: 16}}>Completion Criteria</BodyText>
                <HorizontalFlexContainer>
                    <CheckImageContainer
                        onClick={() => setIsChecked(!isChecked)}
                        src={isChecked ? (greyCheck) : (emptyCheck)}
                    />
                    <CheckboxText>All Categories must be completed.</CheckboxText>
                </HorizontalFlexContainer>
            </>
        )
    }

    useEffect(() => {
        return setVisible(show)
    }, [show])

    return (
        <Modal
            visible={visible}
            centered
            footer={renderModalFooter()}>

            <VerticalFlexContainer>

                <ModalHeading>New Focus Area</ModalHeading>

                <BodyText>Focus Area Name</BodyText>
                <StyledInput
                    {...register("customerExperience", {
                        required: "Enter Focus Area Name."
                    })}
                    name="customerExperience"
                    id="customerExperience"
                    type="text"
                    placeholder="Customer Experience"
                />
                {errors.customerExperience &&
                  <ErrorText>{errors.customerExperience.message}</ErrorText>}

                <BodyText style={{marginTop: 24}}>Abbreviation</BodyText>
                <StyledInput
                    {...register("abbreviationInput", {
                        required: "Enter Abbreviation.",
                        minLength: {
                            value: 2,
                            message: "2 to 3 letters"
                        },
                    })}
                    style={{width: 100}}
                    name="abbreviationInput"
                    id="abbreviationInput"
                    type="text"
                    placeholder="CX"
                    maxLength={3}
                />
                {errors.abbreviationInput ?
                    (<ErrorText>{errors.abbreviationInput.message}</ErrorText>) :
                    (<GreyText>2 to 3 letters</GreyText>)
                }

                <BodyText style={{fontWeight: 700, marginTop: 20, marginBottom: 16}}>Decision Type</BodyText>
                <VerticalFlexContainer>

                    {/*basic*/}
                    <HorizontalFlexContainer style={{justifyContent: "start"}}>
                        <RadioCircleImageContainer
                            onClick={() => onDecisionTypeToggle("basic")}
                            src={isBasicDecisionType ? (greyRadio) : (emptyRadio)}
                            onChange={() => {
                                onDecisionTypeToggle("basic")
                            }}
                            checked={isBasicDecisionType}
                            type="image"
                        />
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <CheckboxText style={{fontWeight: 700, alignSelf: 'flex-start'}}>Basic Decision
                                Type</CheckboxText>
                            <BodyText
                                style={{marginLeft: 13, marginTop: 5, marginBottom: 10}}>
                                A Basic Focus Area flow only has the option of subcategory flows being positive,
                                e.g.
                                ‘Correct’ or negative, e.g. ‘Wrong’.
                            </BodyText>
                        </div>
                    </HorizontalFlexContainer>

                    {isBasicDecisionType &&
                        <Container
                          style={{marginLeft: 33, marginTop: 10, marginBottom: 10}}>
                          <HorizontalFlexContainer>
                            <VerticalFlexContainer
                              style={{marginRight: 16}}>
                              <BodyText>Positive Word</BodyText>
                              <StyledInput
                                  {...register("positiveWord", {
                                      required: "Enter Positive Word"
                                  })}
                                  type="text"
                                  name="positiveWord"
                                  placeholder="Correct"/>
                                {errors.positiveWord && <ErrorText>{errors.positiveWord.message}</ErrorText>}
                            </VerticalFlexContainer>
                            <VerticalFlexContainer>
                              <BodyText>Negative Word</BodyText>
                              <StyledInput
                                  {...register("negativeWord", {
                                      required: "Enter Negative Word"
                                  })}
                                  type="text"
                                  name="negativeWord"
                                  placeholder="Wrong"/>
                                {errors.negativeWord && <ErrorText>{errors.negativeWord.message}</ErrorText>}
                            </VerticalFlexContainer>
                          </HorizontalFlexContainer>
                        </Container>
                    }

                    {/*Confirmation Decision Type*/}
                    <HorizontalFlexContainer style={{marginTop: 10, justifyContent: "start"}}>
                        <RadioCircleImageContainer
                            onClick={() => onDecisionTypeToggle("confirmation")}
                            src={isConfirmationDecisionType ? (greyRadio) : (emptyRadio)}
                            onChange={() => {
                                onDecisionTypeToggle("confirmation")
                            }}
                            checked={isConfirmationDecisionType}
                            type="image"
                        />
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <CheckboxText style={{fontWeight: 700, alignSelf: 'flex-start'}}>Confirmation Decision Type</CheckboxText>
                            <BodyText
                                style={{marginLeft: 13, marginTop: 5, marginBottom: 10}}>
                                This decision type requires the user to acknowledge that each
                                subcategory has either been acted on or not.
                            </BodyText>
                        </div>
                    </HorizontalFlexContainer>

                    {isConfirmationDecisionType &&
                        <Container
                          style={{width: "93%", marginLeft: 33, marginTop: 10, marginBottom: 10}}>
                          <BodyText>
                            Action Word</BodyText>
                          <StyledInput
                              {...register("actionWord", {
                                  required: "Enter Action Word"
                              })}
                              style={{width: "100%"}}
                              type="text"
                              name="actionWord"
                              onChange={(newText) => {
                                  setActionWord(newText.currentTarget.value)
                              }}
                              placeholder="Discussed"
                              maxLength={35}
                          />
                          <HorizontalFlexContainer style={{justifyContent: "start"}}>
                            <BodyText style={{marginTop: 7}}>Has the following been </BodyText>
                              {errors.actionWord ? (<ErrorText
                                      style={{marginTop: 7, marginLeft: 5}}>{errors.actionWord.message}</ErrorText>) :
                                  (<BodyTextBold style={{marginTop: 7, marginLeft: 5}}>
                                      {actionWord}
                                  </BodyTextBold>)
                              }

                          </HorizontalFlexContainer>
                        </Container>
                    }

                </VerticalFlexContainer>
                {/*check button*/}
                {RenderCheckbox()}
            </VerticalFlexContainer>

        </Modal>
    )
}
