import React, {useCallback, useContext, useEffect, useState} from "react"
import {Modal} from "antd"
import {BodyText, ErrorText, ModalCancelButton, ModalConfirmButton, ModalHeading} from "./CXStyleSheet"
import {OptionOutsideSelect} from "./OptionOutsideSelect"
import {useForm} from "react-hook-form"
import {CXContext} from "../../../context/CXContext"
import {GetAllIssuesInFocusArea, IssuesToStrings} from "../../../util/CXDataUtil"
import {pretty} from "../../../util/Log"
import {InputActionMeta} from "react-select/dist/declarations/src/types"
import {MultiValue} from "react-select"
import {OptionValue} from "../../../data/api/APITypes"

interface CXModalIssueProps {
    elementId: string
    show: boolean
    onClose?: () => void
}

export const CXModalAddIssue: React.FC<CXModalIssueProps> = (props) => {
    const {show, onClose} = props
    const [isVisible, setIsVisible] = useState(false)
    const [isSelected, setIsSelected] = useState<MultiValue<OptionValue>>([])
    const [inputValue, setInputValue] = useState('')
    const cxContext = useContext(CXContext)

    const issues = IssuesToStrings(GetAllIssuesInFocusArea(cxContext.activeFocusArea)).map(issue => {
        return {
            value: issue,
            label: issue
        }
    })

    const {
        handleSubmit,
        register,
        formState: {errors},
        setValue,
        reset,
        clearErrors
    } = useForm()

    const onSubmit = (data: any) => {
        console.log(`Creating issue: ${pretty(data)}`)
        cxContext.api.createIssue(props.elementId!!, data.issue.map((issue:any) => issue.value))
        handleCancel()
    }

    const handleSelectChange = useCallback((values: any) => {
        const newValues = [...isSelected, ...values]
        setIsSelected(newValues)
        setValue("issue", newValues)
        setInputValue('')
        clearErrors('issue')
    }, [clearErrors, isSelected, setValue])

    if (errors.issue) {
        console.log(errors.issue)
    }

    const handleCancel = () => {
        onClose && onClose()
        setIsSelected([])
        setInputValue('')
        reset({issue: []})
    }

    const renderModalFooter = () => {
        return (
            <div>
                <span>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <div>
                            <ModalCancelButton onClick={handleCancel}>Cancel</ModalCancelButton>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <ModalConfirmButton type="submit">
                                Add
                            </ModalConfirmButton>
                        </form>
                    </div>
                </span>
            </div>
        )
    }

    useEffect(() => {
        return setIsVisible(show)
    }, [show])

    return (
        <Modal
            visible={isVisible}
            centered
            footer={renderModalFooter()}>

            <ModalHeading>Add Issue</ModalHeading>
            <BodyText>Issue Name</BodyText>
            <OptionOutsideSelect
                {...register("issue",{
                    required:"Select at least 1 issue."
                })}
                isMulti
                onChange={handleSelectChange}
                options={issues}
                value={isSelected}
                isClearable={false}
                components={{DropdownIndicator: null}}
                placeholder={"E.g. VM Alignment"}
                onInputChange={(newValue: string, actionMeta: InputActionMeta) => {
                    if (actionMeta.action === 'input-change') {
                        setInputValue(newValue)
                    }
                }}
                inputValue={inputValue}
            />
            {errors.issue && <ErrorText>{errors.issue.message}</ErrorText>}

        </Modal>
    )
}
