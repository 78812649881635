import React, {Component} from "react"
import {Button, Input, Layout, Spin, Table} from "antd"
import Icon from '@ant-design/icons'
import "../../App.less"
import {EmpowerTicketsAPI} from "../../data/api/EmpowerTicketsAPI"
import {loginAPIErrorRedirector} from "../../data/api/APIErrorRedirector"
import {fetchMoreIfNecessary} from "../../data/api/APIConfig"
import {TicketCategoryResponse, TicketsSummaryResponse} from "../../data/api/APITypes"
import {navigateToTicketDetail} from "./TicketDetail"
import {DateUtil} from "../../util/DateUtil"

const {
    Header,
    Content
} = Layout

type TicketProps = {
    history: any
}

type TicketData = {
    ticketStatus: number
    ticketId: string
}

type TicketTableData = {
    ticket: string
    from: string
    assignedTo: string
    date: string
    priority: string
    id: string
    ticketStatus: string
    key: number
}

type TicketState = {
    announcementVisible: boolean
    busy: boolean
    isEmpty: boolean
    visible: boolean
    dataSource: Array<TicketTableData>
    searchText: string
    totalTickets: number
    currentTicket?: TicketTableData | null | undefined
    authFailed: boolean
}

class Ticket extends Component<TicketProps, TicketState> {
    searchInput: string | null | undefined
    handleSearch = (selectedKeys: any, confirm: any) => () => {
        confirm()
        this.setState({
            searchText: selectedKeys[0]
        })
    }
    handleReset = (clearFilters: any) => () => {
        clearFilters()
        this.setState({
            searchText: ""
        })
    }

    constructor(props: TicketProps) {
        super(props)
        this.state = {
            dataSource: [],
            announcementVisible: false,
            busy: true,
            isEmpty: true,
            visible: false,
            searchText: "",
            totalTickets: 0,
            currentTicket: null,
            authFailed: false
        }
        this.fetchTickets()
    }

    fetchTickets(index: number = 0, isRefresh: boolean = false) {
        this.setState({
            busy: true
        })
        var promise

        if (isRefresh) {
            promise = EmpowerTicketsAPI.refreshTickets(this.state.dataSource.length)
        } else {
            promise = EmpowerTicketsAPI.fetchTickets(index)
        }

        promise.catch(error => {
            this.setState({
                authFailed: true
            })
            return loginAPIErrorRedirector(() => {
                return this.props
            }).onError(error)
        }).then(tickets => {
            if (tickets.data.itemCount < 1) {
                this.setState({
                    busy: false,
                    dataSource: []
                })
            } else {
                let dataSource: Array<TicketTableData> | null | undefined = tickets.data.tickets.map((ticket, index) => {
                    return {
                        key: index,
                        ticket: ticket.subject,
                        from: ticket.from,
                        ticketStatus: ticket.ticketStatus.ticketStatusDescription,
                        priority: ticket.ticketPriority.ticketPriorityDescription,
                        id: ticket.ticketId,
                        state: "",
                        date: this.buildDate(ticket),
                        assignedTo: this.assignedTo(ticket)
                    }
                })

                if (dataSource != null) {
                    if (index > 0 && !isRefresh) {
                        this.setState({
                            totalTickets: tickets.data.itemCount,
                            dataSource: this.state.dataSource.concat(dataSource),
                            busy: false,
                            currentTicket: null
                        })
                    } else {
                        this.setState({
                            totalTickets: tickets.data.itemCount,
                            dataSource: dataSource,
                            busy: false,
                            currentTicket: null
                        })
                    }
                } else {
                    this.setState({
                        busy: false
                    })
                }
            }
        })
    }

    buildDate(ticket: TicketsSummaryResponse): string {
        if (ticket.lastUpdatedDate != null) {
            return DateUtil.formatEmpowerDate(ticket.lastUpdatedDate)
        }

        return DateUtil.formatEmpowerDate(ticket.dateCreated)
    }

    assignedTo(ticket: TicketsSummaryResponse): string {
        let arr = this.fromCategoryResponse(ticket.ticketCategory)
        return arr.join(" - ")
    }

    // @ts-ignore
    fromCategoryResponse(ticketCategoryResponse: TicketCategoryResponse, current?: Array<string> | null | undefined = null): Array<string> {
        var arr: Array<string>

        if (current != null) {
            arr = current
        } else {
            arr = []
        }

        arr.push(ticketCategoryResponse.ticketCategoryDescription)

        if (ticketCategoryResponse.parentTicketCategory != null) {
            return this.fromCategoryResponse(ticketCategoryResponse.parentTicketCategory, arr)
        }

        return arr.reverse()
    }

    fetchMore() {
        this.fetchTickets(this.state.dataSource.length)
    }

    showModal = (ticket: TicketTableData) => {
        navigateToTicketDetail(this.props.history, ticket.id, ticket.ticket)
    }
    handleModalOk = (e: any) => {
        this.setState({
            visible: false
        })
    }

    fetchAnnouncements() {
        this.setState({
            busy: true
        })
    }

    handleDelete = (key: number) => {
        const dataSource = this.state.dataSource

        if (dataSource != null) {
            console.log(dataSource)
            this.setState({
                dataSource: dataSource.filter(item => item.key !== key)
            })
        }
    }
    createToDoModal = () => {
        this.setState({
            announcementVisible: true
        })
    }
    handleTodoModalOk = (e: any) => {
        this.setState({
            announcementVisible: false
        })
    }
    updateTicketStatus = (formData: TicketData) => {
        EmpowerTicketsAPI.updateTicketStatus(formData.ticketId, formData.ticketStatus).catch(error => {
            this.setState({
                authFailed: true
            })
            return loginAPIErrorRedirector(() => {
                return this.props
            }).onError(error)
        }).then(() => {
            //Not Tested
            this.setState({
                announcementVisible: false
            })
        })
    }
    paginationConfig = () => {
        return {
            pageSize: 25,
            total: this.state.totalTickets,
            onChange: (page: number, pageSize: number) => {
                fetchMoreIfNecessary(page, pageSize, this.state.dataSource.length, this.state.totalTickets, () => {
                    this.fetchMore()
                })
            }
        }
    }

    render() {
        const {
            dataSource
        } = this.state
        // @ts-ignore
        const columns = this.columnConfig().map(col => {
            return {
                ...col
            }
        })

        return <Layout>
            <Layout>
                <Header className="header1">
                    <div>
                        <h1>Feedback</h1>
                    </div>
                </Header>
                <Spin style={{
                    alignSelf: "center",
                    minHeight: 400
                }} spinning={this.state.busy}>
                    <div className="content-container">
                        {this.state.dataSource != null && this.state.dataSource.length > 0 &&
                        <Content className="table-Todo-container">
                            <div className="table-ToDo">
                                <Table bordered
                                       columns={columns}
                                       dataSource={dataSource}
                                       locale={{
                                           emptyText: "No Data"
                                       }}
                                    // @ts-ignore
                                       pagination={this.paginationConfig()}/>
                            </div>
                        </Content>}
                    </div>
                </Spin>
            </Layout>
        </Layout>
    }

    currentColumnConfig: any
    columnConfig = () => {
        if (this.currentColumnConfig != null) {
            return this.currentColumnConfig
        }

        this.currentColumnConfig = [{
            title: "From",
            dataIndex: "from",
            filterMultiple: false,
            onCell: (record: TicketTableData) => {
                return {
                    onClick: () => {
                        this.showModal(record)
                    }
                }
            }
        }, {
            title: "Subject",
            dataIndex: "ticket",

            // @ts-ignore
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) =>
                <div className="custom-filter-dropdown">
                    <Input
                        // @ts-ignore
                        ref={ele => this.searchInput = ele} placeholder="Search name" value={selectedKeys[0]}
                           onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                           onPressEnter={this.handleSearch(selectedKeys, confirm)}/>
                    <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
                        Search
                    </Button>
                    <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
                </div>,
            filterIcon: (filtered: any) => <Icon type="search" style={{
                color: filtered ? "#108ee9" : "#aaa"
            }}/>,
            // specify the condition of filtering result
            // here is that finding the To_Do started with `value`
            onFilter: (value: any, record: any) => record.Ticket.toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible: any) => {
                if (visible) {
                    setTimeout(() => {
                        // @ts-ignore
                        this.searchInput.focus()
                    })
                }
            },
            onCell: (record: TicketTableData) => {
                return {
                    onClick: () => {
                        this.showModal(record)
                    }
                }
            }
        }, {
            title: "Date",
            dataIndex: "date",
            filterMultiple: false,
            onCell: (record: TicketTableData) => {
                return {
                    onClick: () => {
                        this.showModal(record)
                    }
                }
            }
        }, {
            title: "Assigned To",
            dataIndex: "assignedTo",
            filterMultiple: false,
            onCell: (record: TicketTableData) => {
                return {
                    onClick: () => {
                        this.showModal(record)
                    }
                }
            }
        }, {
            title: "Ticket Status",
            dataIndex: "ticketStatus",
            filterMultiple: false,
            onCell: (record: TicketTableData) => {
                return {
                    onClick: () => {
                        this.showModal(record)
                    }
                }
            }
        }]
        return this.currentColumnConfig
    }
}

export default Ticket
